import {
  COLLECTION__MATCH_DECISION_TABLES
} from '../services/BE/loopback.api';

export const QUERY__MATCH_DECISION = {
  collection: COLLECTION__MATCH_DECISION_TABLES,
  where: {
    engagementId: '{{ENGAGEMENT_ID}}',
    annotator: '{{ANNOTATOR}}'
  },
  fields: [
    'id',
    'annotator',
    'engagementId',
    'candidateId',
    'jobId',
    'createdAt',
    'updatedAt',
    'whyNoDetails',
    'whyNoCategories',
    'whyYesDetails',
    'whyYesCategories',
    'shouldTag',
    'shouldNotTag',
    'whyNoPrivateNote',
    'whyYesPrivateNote',
    'whyNeedToReadCV',
    'matchDecision',
    'reviewRequested',
    'sameDecision',
    'diffDecision',
  ],
  order: 'updatedAt DESC',
  limit: 1
};
