import AppUI from '../../../dictionaries/AppUI.dic';
import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import {
  QUERY__ACCOUNT_EMPLOYERS__BY__EMPLOYER
} from '../../../lib/queries/AccountEmployers.query';
import {
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  compileText
} from '../../../lib/String.lib';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import copySummaryEmployerPendings from '../../Employers/EmployerPendings/Libraries/copySummaryEmployerPendings.fun';
import {
  copyUrlEmployerPendings
} from '../../Employers/EmployerPendings/Libraries/copyUrlEmployerPendings.fun';
import FieldsetAccountEmployer from '../FieldsetAccountEmployer';

export const KEY__ACTION__GENERATE_URL = 'generate_url';
export const KEY__ACTION__GENERATE_TODO_SUMMARY = 'generate_todo_summary';

export default async function openDialogAccountEmployerSelector({
  employerId,
  onAcceptAction = KEY__ACTION__GENERATE_URL,
  title = {
    [KEY__ACTION__GENERATE_URL]: EmployerPendingsUI.urlGeneratorDialog.title,
    [KEY__ACTION__GENERATE_TODO_SUMMARY]: EmployerPendingsUI.TriggerWaitingEmployerSummary.Dialog.title
  }[onAcceptAction],
  onAcceptLabel = {
    [KEY__ACTION__GENERATE_URL]: EmployerPendingsUI.urlGeneratorDialog.acceptLabel,
    [KEY__ACTION__GENERATE_TODO_SUMMARY]: EmployerPendingsUI.TriggerWaitingEmployerSummary.Dialog.acceptLabel
  }[onAcceptAction],
  onAccept = async (accountId) => {
    if (onAcceptAction === KEY__ACTION__GENERATE_TODO_SUMMARY) {
      await copySummaryEmployerPendings({ accountId, employerId });
    }
    else if (onAcceptAction === KEY__ACTION__GENERATE_URL) {
      await copyUrlEmployerPendings({ accountId });
    }
    else {
      Core.showError(AppUI.unexpectedError.message);
    }
  }
}) {
  try {
    const context = {};
    if (NOT(employerId)) {
      throw new Error('Missed employerId');
    }
    const options = Arr(
      await readLoopbackRecord(
        compileText(
          QUERY__ACCOUNT_EMPLOYERS__BY__EMPLOYER,
          { EMPLOYER__ID: employerId }
        )
      )
    );
    if (NOT(options.length)) {
      return Core.showWarning(
        EmployerPendingsUI.urlGeneratorDialog.warningNoAccountsFound
      );
    }
    if (options.length === 1) {
      return onAccept(options[0].accountId);
    }
    Object.assign(context, options[0]);
    showConfirm({
      title,
      content: (
        <FieldsetAccountEmployer
          options={options}
          context={context}
        />
      ),
      paperStyle: { minWidth: 640 },
      onAcceptLabel,
      async onAccept() {
        return onAccept(context.accountId);
      },
    })
  }
  catch (error) {
    if (Core.isProduction()) {
      Core.showError(AppUI.unexpectedError.message, error);
    }
    else {
      Core.showError(error);
    }
  }
}
