import Box from '../../Layout/Wrappers/Box';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Typography from '../../Layout/Wrappers/Typography';

export default function WarningDifferentPlatformRating(props) {
  let {
    candidate,
    recommendedPlatformRatingTag,
    candidatePlatformRatingTag,
  } = props;
  return (recommendedPlatformRatingTag.label !== candidatePlatformRatingTag.label) && (
    <Box column flex1>
      <Box row className='f-lg c-red px-1'>
        <b>Warning:</b>&nbsp;
        The system recommends [&nbsp;
        <b>{recommendedPlatformRatingTag.label}</b>&nbsp;
        ]&nbsp;
        rating instead of [ {candidatePlatformRatingTag.label} ]
      </Box>
      {!!candidate._positiveSignals.length && (
        <Fieldset
          title='Positive signals'
          wrapperProps={{ className: 'mt-05' }}
        >
          <Typography className='c-black-medium f-sm'>
            {candidate._positiveSignals}
          </Typography>
        </Fieldset>
      )}
      {!!candidate._negativeSignals.length && (
        <Fieldset
          title='Negative signals'
          wrapperProps={{ className: 'mt-05' }}
        >
          <Typography className='c-black-medium f-sm'>
            {candidate._negativeSignals}
          </Typography>
        </Fieldset>
      )}
    </Box>
  );
}
