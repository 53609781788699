import _ from 'lodash';
import {
  Arr
} from '../../../lib/Array.lib';
import Candidate from '../../../lib/Candidate';
import CandidateSkillsAction from '../../../lib/CandidateSkillsAction';
import {
  jobTypes
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Engagement from '../../../lib/Engagement';
import FilterControl from '../../../lib/FilterControl';
import Job from '../../../lib/Job';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  YearsOfExperienceColor
} from '../../../lib/YearOfExperienceColor';
import FilterLib from '../../../lib/services/Filtering/Filter.lib';
import formatMoney from '../../../lib/tools/formatMoney';
import mapCandidateSignalsOnChange from '../../Candidates/mapCandidateSignalsOnChange.tool';
import {
  THEME__VARIANT__OUTLINED
} from '../../Layout/Libraries/Theme.lib';
import Button from '../../Layout/Wrappers/Button';
import Chip from '../../Layout/Wrappers/Chip';
import Dialog from '../../Layout/Wrappers/Dialog';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import InputNumber from '../../Layout/Wrappers/InputNumber';
import Slider from '../../Layout/Wrappers/Slider';
import JobPipe from '../Job/JobPipe';
import {
  unitTestingPresetLocationMenusAll
} from '../Libraries/Match.lib';
import {
  getMatchEntities
} from '../Libraries/MatchList.lib';
import SingleJobCard from '../List/SingleJobCard';
import {
  VALUE__MATCH__RANGE_EXPERIENCE__MAXIMUM,
  VALUE__MATCH__RANGE_EXPERIENCE__MINIMUM
} from '../MatchPage';
import CandidatePipe from './CandidatePipe';

let candidate = {};
let allMatches = [];

const fetchProfile = (candidateId) => (cb) => {
  Candidate.get(candidateId, (result) => {
    candidate = Obj(result);
    if (!candidate.id) {
      const error = `Error: candidate.id is missing in Candidates__MatchStrategy__Strategy__subjectFunc`;
      console.debug(error, candidate);
      Core.showError(error, () => Core.refresh());
      candidate.id = candidateId;
      candidate['tempMinimumSalary'] = candidate.minimumSalary;
      candidate['rangeExperience'] = [
        VALUE__MATCH__RANGE_EXPERIENCE__MINIMUM,
        candidate.yearsOfExperience
      ];
      candidate['tempMinimumCompanySize'] = 0; // means not to check that.
      candidate.jobOwnerships = [];
      candidate.engagements = [];
      return !!cb && cb(candidate, []);
    }
    Candidate.getPotentialDuplicatedWithOwnerships({ id: candidateId }).then((result) => {
      candidate.jobOwnerships = Arr(result);
      Engagement.getWhereV2({ candidateId: candidate.id }).then((engagement) => {
        candidate['tempMinimumSalary'] = candidate.minimumSalary;
        candidate['rangeExperience'] = [
          VALUE__MATCH__RANGE_EXPERIENCE__MINIMUM,
          candidate.yearsOfExperience
        ];
        candidate['tempMinimumCompanySize'] = 0; // means not to check that.
        candidate.engagements.push(engagement);
        !!cb && cb(candidate, engagement);
      });
    });
  });
};

const fetchMatches = (params) => (cb, paramsExtra) => {
  const ACTIVE_STATE = 1;
  Job.getAll(
    (jobs) => {
      allMatches = jobs;
      !!cb && cb(jobs);
    },
    {
      where: {
        state: {
          inq:
            Array.isArray(paramsExtra.state) && paramsExtra.state.length > 0
              ? paramsExtra.state
              : [ACTIVE_STATE],
        },
      },
    }
  );
};

const getDefaultFields = (candidate) => {
  return [
    { key: 'visa', label: candidate._visa, checked: true },
    { key: 'location', label: candidate._workLocationIds, checked: true }
  ];
};

/** [2024-07-03][story_9166] @todo rename "object" param */
const filterControl = ({
  profile,
  object,
  selected
}) => {
  let preset = '^Active$';
  if (profile._roles && profile._roles.length) {
    preset += '|^' + profile._roles.replace(/, /g, '$|^') + '$';
  }

  /**
   * If it is Candidate._desiredEmploymentTypes defined
   * then it will be preset in the filter menu to be matching
   * with Job._jobType
   * story-3083-M2-1
   */
  if (
    profile._desiredEmploymentTypes &&
    profile._desiredEmploymentTypes.length
  ) {
    preset +=
      '|' +
      profile._desiredEmploymentTypes
        .split(',')
        .map((n) => n.trim().replace(/\(|\)/g, '.+'))
        .join('|') +
      '$';
  }

  const label = getDefaultFields(profile);
  const fields = label;

  const filters = FilterControl.setInitialSearchMenu(
    object,
    _.cloneDeep(Job.menus),
    _.cloneDeep(Job.more),
    preset,
    profile,
    [],
    label,
    fields,
    getChips,
    { candidate: profile }
  );

  const { menus, more, sources, chips } = filters;

  const jobsHavingConstraints = object.filter((job) =>
    filterJobsContainConstraints(profile, job)
  );
  const jobsWithoutConstraints = object.filter(
    (job) => !filterJobsContainConstraints(profile, job)
  );

  const filteredMatches = filterJobs(
    profile,
    jobsWithoutConstraints,
    [],
    menus,
    more,
    selected,
    jobsHavingConstraints
  );

  return {
    menus,
    more,
    sources,
    chips,
    filteredMatches,
    constraintsObjects: jobsHavingConstraints,
    withoutConstraintsObjects: jobsWithoutConstraints,
  };
};

const skillsHandler = async (skills, type) => {
  let oldSkills;

  switch (type) {
    case 'technicalSkills':
      oldSkills = candidate.technicalSkills;
      candidate = {
        ...candidate,
        technicalSkills: skills,
      };

      Candidate.update(
        candidate.id,
        {
          technicalSkills: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: 'Technical Skills',
              oldState: oldSkills,
              newState: skills,
            },
          ];

          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showSuccess('Saved Successfully!');
        }
      );
      break;
    case 'technologyDomain':
      oldSkills = candidate.technologyDomain;
      candidate = {
        ...candidate,
        technologyDomain: skills,
      };

      Candidate.update(
        candidate.id,
        {
          technologyDomain: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: 'Technology Domain',
              oldState: oldSkills,
              newState: skills,
            },
          ];

          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showSuccess('Saved Successfully!');
        }
      );
      break;
    case 'positiveSignals':
      oldSkills = [...candidate.positiveSignals];
      await mapCandidateSignalsOnChange({
        candidate,
        signals: skills,
        key: 'positiveSignals',
        updater: update => {
          Candidate.update(
            candidate.id,
            update,
            (response) => {
              const dirtyAttrs = [
                {
                  key: type,
                  label: "Positive Signals",
                  oldState: oldSkills,
                  newState: update.positiveSignals,
                },
              ];
              CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
              Core.showSuccess("Saved Successfully!");
            }
          );
        }
      });
      break;
    case 'negativeSignals':
      oldSkills = [...candidate.negativeSignals];
      await mapCandidateSignalsOnChange({
        candidate,
        signals: skills,
        key: 'negativeSignals',
        updater: update => {
          Candidate.update(
            candidate.id,
            update,
            (response) => {
              const dirtyAttrs = [
                {
                  key: type,
                  label: "Negative Signals",
                  oldState: oldSkills,
                  newState: update.negativeSignals,
                },
              ];
              CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
              Core.showSuccess("Saved Successfully!");
            }
          );
        }
      });
      break;
    default:
      break;
  }
};

const saveContentCandidate = (key, content) => {
  candidate = {
    ...candidate,
    [key]: content,
  };

  Candidate.update(
    candidate.id,
    {
      [key]: content,
    },
    () => {
      Core.showSuccess('Saved Successfully!');
    }
  );
};

const saveJob = (job, callback) => (key, content) => {
  const oldJobState = allMatches.find((j) => j.id === job.id) || job;
  const oldSJobStateIndex = allMatches.findIndex((j) => j.id === job.id);
  const withoutThisJob = allMatches.filter((j) => j.id !== job.id);

  const updateOldJobState = {
    ...oldJobState,
    [key]: content,
  };

  let updatedJobs = [...withoutThisJob];

  updatedJobs.splice(oldSJobStateIndex, 0, updateOldJobState);

  allMatches = [...updatedJobs];
  !!callback && callback(allMatches);
  Job.update(
    job.id,
    {
      [key]: content,
    },
    () => {
      Core.showSuccess('Saved Successfully!');
    }
  );
};

const staticRowDisplayChip = (jobAtr, candAtr, color) => {
  const singleAttrJob = jobAtr.split(',');

  if (!jobAtr || !candAtr) {
    return singleAttrJob.map((attr, index) => {
      return (
        <Chip
          key={index}
          className="slim-chip ui-candidate-match-strategy-chip-a"
          label={attr}
          size="small"
          variant="outlined"
          style={{
            border: `1px solid gray`,
          }}
        />
      );
    });
  }

  return singleAttrJob.map((attr, index) => {
    return (
      <Chip
        key={index}
        className="slim-chip ui-candidate-match-strategy-chip-b"
        label={attr}
        size="small"
        variant="outlined"
        style={{
          border: `${color === 'red' ? 2 : 1}px solid ${color}`,
        }}
      />
    );
  });
};

const staticRowDisplayColor = (job, candidate, color) => {
  let obj = {};

  obj.getSalaryColor = ((candidate, job) => () => {
    if (!candidate.minimumSalary || !job.salaryMax) {
      return '';
    }

    let color;

    if (candidate.minimumSalary <= job.salaryMax) {
      color = 'green';
    } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
      color = 'grey';
    } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
      color = 'grey';
    } else {
      color = 'red';
    }
    // console.log("salary " + color + " cand sal "+candidate.minimumSalary+ " job sal " + job.salaryMax)
    return color;
  })(candidate, job);

  obj.getVisaColor = ((candidate, job) => () => {
    let menu = Candidate.menus.find((obj) => obj.key === 'visa');
    let myMappings = menu.mappings[job._visaTransfer] || [];
    return myMappings.includes(candidate._visa) ? 'green' : 'red';
  })(candidate, job);

  obj.getRolesColor = ((candidate, jobRole) => () => {
    const rolesRegExp = new RegExp(jobRole.trim(), 'gi').test(candidate._roles);
    return !!rolesRegExp ? 'green' : color;
  })(candidate, job);

  obj.getLocationColor = ((candidate, jobLoc) => () => {
    const locRegExp = new RegExp(jobLoc.trim(), 'gi').test(
      candidate._workLocationIds
    );
    return !!locRegExp ? 'green' : color;
  })(candidate, job);

  obj.getYearsXp = ((candidate, job) => () => {
    return YearsOfExperienceColor(job, candidate);
  })(candidate, job);
  return obj;
};

const segregateRolesChip = (job, candidate) => {
  let colorAlarm = 'red';

  if (!!job.id && !!job._roles) {
    job._roles.split(',').forEach((roles) => {
      const rolesRegExp = new RegExp(roles.trim(), 'gi').test(candidate._roles);
      if (!!rolesRegExp) colorAlarm = '';
    });

    return job._roles.split(',').map((roles) => {
      return staticRowDisplayChip(
        roles,
        candidate._roles,
        staticRowDisplayColor(roles, candidate, colorAlarm).getRolesColor()
      );
    });
  }
};

const segregateLocationChip = (job, candidate) => {
  let colorAlarm = 'red';

  if (!!job.id && !!job._locations) {
    job._locations.split(',').forEach((loc) => {
      const locRegExp = new RegExp(loc.trim(), 'gi').test(
        candidate._workLocationIds
      );
      if (!!locRegExp) colorAlarm = '';
    });

    return job._locations.split(',').map((loc) => {
      return staticRowDisplayChip(
        loc,
        candidate._workLocationIds,
        staticRowDisplayColor(loc, candidate, colorAlarm).getLocationColor()
      );
    });
  }
};

const renderCandidatePipe = ({ profile, selectedMatch }) => {
  const { candidate, job } = getMatchEntities({ profile, selectedMatch });
  return (
    <CandidatePipe
      job={job}
      candidate={candidate}
      saveContentCandidate={saveContentCandidate}
      skillsHandler={skillsHandler}
      source="candidateMatch"
    />
  );
};

const renderJobPipe = ({ profile, selectedMatch }, callback) => {
  const { candidate, job } = getMatchEntities({ profile, selectedMatch });
  return (
    <JobPipe
      job={job}
      candidate={candidate}
      saveJob={saveJob(job, callback)}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      source="candidateMatch"
    />
  );
};

const renderListCard = ({
  match = {},
  ...props
}) => {
  return (
    <SingleJobCard {...props}
      key={`match_candidate__render_card__${match.id}`}
      match={match}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
    />
  );
};

const renderEngagementCard = ({
  engagement = {},
  ...props
}) => {
  return (
    <SingleJobCard {...props}
      key={`match_candidate__render_card__${engagement.id}`}
      engagement={engagement}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
    />
  );
};

const filterJobsContainConstraints = (candidate, job) => {
  if (
    (Array.isArray(candidate[jobTypes.jobsPermitted]) &&
      candidate[jobTypes.jobsPermitted].includes(job.id)) ||
    (Array.isArray(candidate[jobTypes.jobsPitched]) &&
      candidate[jobTypes.jobsPitched].includes(job.id)) ||
    (Array.isArray(candidate[jobTypes.jobsDeclined]) &&
      candidate[jobTypes.jobsDeclined].includes(job.id))
  ) {
    return true;
  }
  return false;
};

const getChips = (candidate, menus, more, keywords) => {
  const chips = [];

  menus.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach(
        (name) =>
          menu.items[name] === true &&
          chips.push({ name, menu: true, key: menu.key })
      );
  });

  more.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach(
        (name) =>
          menu.items[name] === true &&
          chips.push({ name, more: true, key: menu.key })
      );
  });

  !!keywords.length && keywords.forEach((item) => chips.push(item));

  if (!!candidate.tempMinimumSalary && candidate.tempMinimumSalary !== 0) {
    let prefix = '';
    prefix = 'Pays Salary >=';

    chips.push({
      name: `${prefix} $${formatMoney(candidate.tempMinimumSalary, 0)}`,
      minimumSalary: true,
    });
  }

  if (!!candidate.maximumSalary && candidate.maximumSalary !== 500000) {
    chips.push({
      name: `Max Salary: $${formatMoney(candidate.maximumSalary, 0)}`,
      maximumSalary: true,
    });
  }

  if (
    Arr(candidate.rangeExperience)[0] > 0
  ) {
    let prefix = '';
    prefix = `require >= ${candidate.rangeExperience[0]}y exp`;
    chips.push({
      name: `${prefix}`,
      minimumXp: true,
    });
  }
  if (
    Arr(candidate.rangeExperience)[1] > 0
  ) {
    let prefix = '';
    prefix = `require <= ${candidate.rangeExperience[1]}y exp`;
    chips.push({
      name: `${prefix}`,
      minimumXp: true,
    });
  }

  return chips;
};

const filterJobs = (
  candidate,
  jobs,
  keywords,
  menus,
  more,
  selectedId,
  jobsHavingConstraints
) => {
  // const jobsHavingConstraints = jobs.filter(job => filterJobsContainConstraints(candidate, job));
  if (!jobs) {
    jobs = [];
  }

  unitTestingPresetLocationMenusAll({ candidate });

  if (!jobsHavingConstraints) {
    jobsHavingConstraints = [];
  }

  const selectedMatch = jobs.find((job) => job.id === selectedId);
  let filtered = jobs;
  /** FILTER BY MINIMUM AND MAXIMUM SALARY */

  if (!!candidate.tempMinimumSalary && candidate.tempMinimumSalary !== 0) {
    filtered = filtered.filter((item) => {
      const job = item;
      let salaryGreaterThan = Number(
        String(job.salaryMax || Number.MAX_SAFE_INTEGER).replace(
          /(\..*)|\D/g,
          ''
        )
      );

      if (!!candidate.applyLooseMatch) {
        //increase salary requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          salaryGreaterThan = 1.5 * salaryGreaterThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          salaryGreaterThan = 1.3 * salaryGreaterThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          salaryGreaterThan = 1.15 * salaryGreaterThan;
        }
      }

      return salaryGreaterThan >= Number(candidate.tempMinimumSalary);
    });
  }
  //
  // /** FILTER BY MINIMUM AND MAXIMUM XP */
  if (
    (
      +Arr(candidate.rangeExperience)[0] >
      VALUE__MATCH__RANGE_EXPERIENCE__MINIMUM
    ) ||
    (
      +Arr(candidate.rangeExperience)[1] <
      VALUE__MATCH__RANGE_EXPERIENCE__MAXIMUM
    )
  ) {
    filtered = filtered.filter((item) => {
      const job = item;
      let experienceGreaterThan = Arr(candidate.rangeExperience)[0];
      let experienceLessThan = Arr(candidate.rangeExperience)[1];

      let numberOfYearsOfExperience = Number(
        Number(job.minYearsOfExperience) > -1
          ? String(job.minYearsOfExperience).replace(/(\..*)|\D/g, '')
          : String(Number.MAX_SAFE_INTEGER).replace(/(\..*)|\D/g, '')
      );

      if (!!candidate.applyLooseMatch) {
        //reduce years of experience requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          experienceLessThan = 0.5 * experienceLessThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          experienceLessThan = 0.7 * experienceLessThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          experienceLessThan = 0.85 * experienceLessThan;
        }
      }

      return (
        numberOfYearsOfExperience >= experienceGreaterThan &&
        numberOfYearsOfExperience <= experienceLessThan
      );
    });
  }

  /* FILTER BY COMPANY SIZE */
  if (
    !!candidate.tempMinimumCompanySize &&
    candidate.tempMinimumCompanySize > 0
  ) {
    filtered = filtered.filter(
      (item) =>
        !!item.employer &&
        Number(
          Number(item.employer.employeeCount) > -1
            ? String(item.employer.employeeCount).replace(/(\..*)|\D/g, '')
            : String(Number.MAX_SAFE_INTEGER).replace(/(\..*)|\D/g, '')
        ) <= Number(candidate.tempMinimumCompanySize)
    );
  }

  /** FILTER BY KEYWORDS */
  if (!!keywords.length) {
    filtered = filtered.filter((item) => {
      return keywords.every((objKeyword) => {
        return item.___keys___.some((label) =>
          new RegExp(
            String(objKeyword.name)
              .replace('+', '\\+')
              .replace('.', '\\.')
              .replace('#', '\\#')
              .replace('(', '\\(')
              .replace(')', '\\)'),
            'i'
          ).test(label)
        );
      });
    });
  }

  /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
  filtered = FilterLib.filterItemsByCheckedOptionsInFilterBar({
    items: filtered,
    menus,
    more,
  });

  return [selectedMatch, ...jobsHavingConstraints, ...filtered].filter(
    (el) => !!el
  );
};

const jobOfferSalary = (closeEvent, applyEvent, onChangeEvent, salary) => {
  return (
    <Dialog open
      title='Filter by'
      content={
        <Fieldset
          title={
            <>Job can offer up to:&nbsp; ${formatMoney(salary, 0)}</>
          }
        >
          <Slider
            name="minimumSalary"
            min={0}
            max={500000}
            step={10000}
            value={salary}
            onChange={(event, salary) => {
              !!onChangeEvent && onChangeEvent(salary);
            }}
          />
        </Fieldset>
      }
      actions={[
        <Button outlined minW120
          label="Cancel"
          onClick={(ev) => closeEvent()}
        />,
        <Button primary minW120
          label="Apply"
          onClick={(ev) => {
            applyEvent(salary);
          }}
        />
      ]}
    />
  );
};

const jobOfferExperience = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  rangeExperience = [
    VALUE__MATCH__RANGE_EXPERIENCE__MINIMUM,
    VALUE__MATCH__RANGE_EXPERIENCE__MAXIMUM
  ]
) => {
  return (
    <Dialog open
      title='Filter by'
      content={
        <Fieldset row
          title={
            <>
              Jobs require xp between&nbsp;
              {formatMoney(rangeExperience[0], 0)}
              &nbsp;and&nbsp;
              {formatMoney(rangeExperience[1], 0)}
              &nbsp;years
            </>
          }
        >
          <InputNumber
            name="rangeExperienceMinimum"
            min={VALUE__MATCH__RANGE_EXPERIENCE__MINIMUM}
            max={rangeExperience[1]}
            step={1}
            value={rangeExperience[0]}
            onChange={(rangeExperienceMin) => {
              rangeExperience[0] = rangeExperienceMin;
              !!onChangeEvent && onChangeEvent(rangeExperience);
            }}
            className="mr-2 flex-1"
          />
          <InputNumber
            name="rangeExperienceMaximum"
            min={rangeExperience[0]}
            max={VALUE__MATCH__RANGE_EXPERIENCE__MAXIMUM}
            step={1}
            value={rangeExperience[1]}
            onChange={(rangeExperienceMax) => {
              rangeExperience[1] = rangeExperienceMax;
              !!onChangeEvent && onChangeEvent(rangeExperience);
            }}
            className="flex-1"
          />
        </Fieldset>
      }
      actions={[
        <Button
          label="Cancel"
          onClick={(ev) => closeEvent()}
          variant={THEME__VARIANT__OUTLINED}
          className='min-w-120'
        />,
        <Button
          label="Apply"
          onClick={(event) => applyEvent(rangeExperience)}
          className='min-w-120'
          primary
        />
      ]}
    />
  );
};

const jobOfferCompanySize = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  companySize
) => {
  return (
    <Dialog open
      title='Filter by'
      content={
        <Fieldset
          title={
            <>
              Jobs require equal or less than:&nbsp;{companySize}&nbsp;company size
            </>
          }
        >
          <Slider
            min={0}
            max={10000}
            step={100}
            value={companySize}
            onChange={(event, companySize) => {
              !!onChangeEvent && onChangeEvent(companySize);
            }}
          />
        </Fieldset>
      }
      actions={[
        <Button
          label="Cancel"
          onClick={(ev) => closeEvent()}
          variant={THEME__VARIANT__OUTLINED}
          className='min-w-120'
        />,
        <Button
          label="Apply"
          onClick={(ev) => {
            applyEvent(companySize);
          }}
          className='min-w-120'
          primary
        />
      ]}
    />
  );
};

const candidatePutDownJobs = (id, putDownJobs) => {
  Candidate.update(id, {
    putDownJobs,
  });
};

const disEngagementModel = ({
  engagement = {},
  candidate = {},
  employer = {},
  job = {},
  matchStrength,
  shouldTag = '',
  shouldNotTag = '',
  whyNoPrivateNote = '',
  whyNeedToReadCV = '',
  whyNoCategories = [],
  whyNoDetails = '',
  reviewed,
}) => {
  return {
    source: 'candidateMatch',
    annotator: Core.getSessionEmail(),
    matchDecision: matchStrength,
    candidateName: candidate._name,
    employerName: employer.name,
    jobName: job.jobTitle,
    engagementStage: engagement.stage,
    engagementStatus: engagement.status,
    matchingUrl: !!candidate.id && !!job.id
      ? Core.getPath(`candidate/match/${candidate.id}?selected=${job.id}`)
      : '',
    engagementUrl: !!engagement.id
      ? Core.getPath(`engagement/view/${engagement.id}`)
      : '',
    candidateUrl: !!candidate.id
      ? Core.getPath(`candidate/edit/${candidate.id}`)
      : '',
    jobUrl: !!job.id
      ? Core.getPath(`job/edit/${job.id}`)
      : '',
    engagementId: engagement.id,
    jobId: job.id,
    candidateId: candidate.id,
    jobRoles: job._roles,
    reviewed,
    shouldTag,
    whyNoDetails,
    whyNoCategories,
    shouldNotTag,
    whyNoPrivateNote,
    whyNeedToReadCV,
  };
};

export default function StrategyMain(id, param) {
  return {
    fetchProfile: fetchProfile(id),
    fetchMatches: fetchMatches(param),
    renderProfilePipe: renderCandidatePipe,
    renderMatchPipe: renderJobPipe,
    renderListCard,
    renderEngagementCard,
    filterControl,
    filterMatches: filterJobs,
    salaryPopup: jobOfferSalary,
    experiencePopup: jobOfferExperience,
    companySizePopup: jobOfferCompanySize,
    getChips: getChips,
    putDownCallback: candidatePutDownJobs,
    disEngagementModel,
    matchKey: 'job',
  };
};
