import {
  compile
} from 'handlebars';
import moment from 'moment';
import {
  join
} from '../../../lib/Array.lib';
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  DATE_FORMAT__DATETIME__US_24H,
  TIMEZONE__LA
} from '../../../lib/Date.lib';
import globalErrorHandler from '../../../lib/Error/globalErrorHandler.fun';
import {
  COLLECTION__CANDIDATES,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  CandidateEditController
} from '../Edit/CandidateEdit';



export default async function prependNoteDifferentPlatformRating({
  candidateId = '',
  context = {}
}) {
  try {
    const candidate = await readLoopbackRecord({
      collection: COLLECTION__CANDIDATES,
      where: { id: candidateId },
      fields: [
        'id',
        'platformRatingNotes',
      ],
      limit: 1
    });
    const ENTRY = compile(`
      <div>
        <b>{{USER__EMAIL}}</b> changed rating from <b>{{SYSTEM__RATING}}</b> to <b>{{CANDIDATE__RATING}}</b> on {{DATE_TIME}}<br/>
        Reason: {{NOTE}}
      </div>
    `)(
      {
        ...context,
        USER__EMAIL: Core.getUser().email,
        DATE_TIME: `${(
          moment.tz(TIMEZONE__LA).format(DATE_FORMAT__DATETIME__US_24H)
        )} PT`,
        NOTE: Str(context.NOTE).replace(/\n/g, ' ').trim() || MDASH,
      }
    );
    const update = {
      platformRatingNotes: join(
        [ENTRY, trim(candidate.platformRatingNotes)],
        '<br/>'
      )
    };
    /** 
     * [2025-01-21]
     * @todo
     * Figure a way to save this data to Loopback without messing up the change detection
     * @see https://app.shortcut.com/10by10/story/11519/#activity-11829
     */
    // await Candidate.update(candidateId, update);
    CandidateEditController().setState(update);
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
