import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Definition from '../../../lib/Definition';
import { trim } from '../../../lib/String.lib';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import InputNotePlatformRating, {
  InputNotePlatformRatingController
} from './InputNotePlatformRating';
import prependNoteDifferentPlatformRating from './prependNoteDifferentPlatformRating.fun';

export default function confirmDifferentPlatformRating({
  candidate,
  onAccept,
  ...dialogOptions
}) {
  const calculatedPR = Candidate.calculatePlatformRating({ candidate });
  const recommendedPlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: calculatedPR
  });
  const candidatePlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: candidate.platformRating
  });
  showConfirm({
    ...dialogOptions,
    title: `Why different rating? (${candidatePlatformRatingTag.label})`,
    content: (
      <InputNotePlatformRating />
    ),
    paperStyle: { minWidth: 640 },
    actionOptions: [
      {
        label: 'Cancel',
      },
      {
        label: 'Confirm',
        variant: 'contained',
        color: 'primary',
        onClick: async (event) => {
          const NOTE = trim(InputNotePlatformRatingController().state?.note);
          await prependNoteDifferentPlatformRating({
            candidateId: candidate.id,
            context: {
              NOTE,
              USER: Core.getUserName(),
              SYSTEM__RATING: recommendedPlatformRatingTag.label,
              CANDIDATE__RATING: candidatePlatformRatingTag.label
            }
          });
        }
      }
    ]
  });
}
