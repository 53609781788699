/**
 * [2025-01-30][11824]
 * @note
 * This file describes the model and the options used in Job.engagementFeedbackReasons
 */

import {
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN
} from '../../dictionaries/Engagement.dic';
import {
  ENTITY__EMPLOYER
} from '../../dictionaries/Entities.dic';
import {
  join
} from '../Array.lib';
import {
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__COMPANY_PEDIGREE,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__COMPANY_SIZE,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__EDUCATION_CREDENTIALS,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__JOB_HOPPER_CONSULTING,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__MAYBE_LATER,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__NOT_INTERESTED,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__PROJECT_COMPLEXITY,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__SENIORITY_LEVEL,
  ID__TAG__ENGAGEMENT_FEEDBACK_REASON__TECH_STACK
} from '../Definition';

export const OPTIONS__ENGAGEMENT_FEEDBACK_REASON__BY_WHO = [
  ENTITY__EMPLOYER
];

export const OPTIONS__ENGAGEMENT_FEEDBACK_REASON__STAGES = [
  STAGE_REVIEW,
  join([STAGE_SCREEN, 1], ' - '),
  join([STAGE_SCREEN, 2], ' - '),
  join([STAGE_SCREEN, 3], ' - '),
  join([STAGE_ONSITE, 1], ' - '),
  join([STAGE_ONSITE, 2], ' - ')
];

const MODEL__ENGAGEMENT_FEEDBACK_REASON = {
  reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__NOT_INTERESTED,
  byWho: OPTIONS__ENGAGEMENT_FEEDBACK_REASON__BY_WHO[0],
  stage: OPTIONS__ENGAGEMENT_FEEDBACK_REASON__STAGES[0]
};

export default MODEL__ENGAGEMENT_FEEDBACK_REASON;

export const OPTIONS__DEFAULT__COMMON__ENGAGEMENT_FEEDBACK_REASONS = [
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__TECH_STACK },
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__COMPANY_PEDIGREE },
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__COMPANY_SIZE },
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__EDUCATION_CREDENTIALS },
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__SENIORITY_LEVEL },
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__JOB_HOPPER_CONSULTING },
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__PROJECT_COMPLEXITY },
];

export const OPTIONS__DEFAULT__ADMIN__ENGAGEMENT_FEEDBACK_REASONS = [
  ...OPTIONS__DEFAULT__COMMON__ENGAGEMENT_FEEDBACK_REASONS,
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__NOT_INTERESTED },
  { ...MODEL__ENGAGEMENT_FEEDBACK_REASON, reason: ID__TAG__ENGAGEMENT_FEEDBACK_REASON__MAYBE_LATER },
];

