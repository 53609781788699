import {
  trim
} from 'lodash';
import {
  YES
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import { joinClassName } from '../Libraries/Theme.lib';
import IconButton from './IconButton';

export default function IconLinkLauncher({
  url = '',
  className = '',
  ...props
}) {
  return (
    <IconButton
      {...props}
      acl={YES(trim(url))}
      icon='launch'
      onClick={event => Core.openPopUp(url, 1200)}
      className={joinClassName(['icon18 f-md fw-bold c-black-dark', className])}
    />
  );
}
