import {
  isObject
} from 'lodash';
import {
  Component
} from "react";
import Core from "../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__EDIT_JOB,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__LIST_JOBS,
  ACCOUNT_ACTION__MATCH_JOB
} from "../../../lib/Definition";
import EmailTemplate from "../../../lib/EmailTemplate";
import Employer from "../../../lib/Employer";
import HistoryLog from "../../../lib/HistoryLog";
import Job from "../../../lib/Job";
import {
  Obj
} from '../../../lib/Object.lib';
import Store from "../../../lib/Store";
import {
  getLocation,
  getParams
} from '../../../lib/URL.lib';
import {
  EMPLOYER__INTAKE_NOTE
} from "../../../lib/models/employer";
import {
  getJobModel,
  mapJob
} from "../../../lib/models/job";
import {
  openMessageEmailPreview
} from '../../../lib/services/Email/Email.lib';
import SuccessDialog from "../../Dialogs/Success";
import {
  EmployerJobsDropdown
} from '../../Employers/EmployerJobsDropdown';
import {
  openEmployerPendings
} from '../../Employers/EmployerPendings/Libraries/EmployerPendings.lib';
import EmployerUI from '../../Employers/EmployerUI.dic';
import HistoryMenu from '../../HistoryMenu/HistoryMenu';
import MoreOptionsMenu from '../../Home/3DotsMenu/MoreOptionsMenu';
import {
  moreMenuOptionsJobs
} from '../../Home/3DotsMenu/moreMenuOptionsJobs';
import Page from '../../Layout/Page';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import NavLink from '../../Layout/Wrappers/NavLink';
import Navigate from '../../Layout/Wrappers/Navigate';
import Paper, { getDefaultPaperStyle } from '../../Layout/Wrappers/Paper';
import RichTextBox from "../../Layout/Wrappers/RichTextBox";
import Snackbar from '../../Layout/Wrappers/Snackbar';
import Stepper, { stepperScrollToTop } from "../../Layout/Wrappers/Stepper";
import AccessDenied from '../../Shared/AccessDenied';
import Basics from "./Steps/Basics";
import Process from "./Steps/Process";
import Requirements from "./Steps/Requirements";

const required = "This field is required";

export default class JobEdit extends Component {
  state = {
    ...getJobModel({ extended: true }),
    /** controller states */
    categories: [],
    allCategories: [],
    employers: [],
    snackbar: null,
    error: {},
    errorJobTitle: "",
    step: 1
  };
  constructor() {
    super(...arguments);
    Store.set("path", getLocation());
    const _jobId = getParams({ pattern: '/job/edit/:id' }).id;
    if (Core.getUserRole() !== "SysAdmin") {
      Core.go({ ...this.props, to: '/' });
    }
    Employer.getList((employers) => {
      this.setState(
        {
          employers,
          /** setting fulltime as default value of job type */
          jobType: Definition.getId("jobType", "Fulltime"),
          /** setting unknown as default value of remote */
          remote: Definition.getId("remote", "Unknown"),
        },
        (then) => {
          if (_jobId) {
            Job.get(_jobId, (model) => {
              this.setState(
                {
                  ...model,
                  employers: [...this.state.employers],
                  /** @todo [ 2022-10-18 ][ MS: for future implementation ] */
                  // __snapshot: snapshotState({ state: model, model: getJobModel() })
                },
                (then) => {
                  EmailTemplate.getAll((emailTemplates) => {
                    this.setState({ emailTemplates }, (then) =>
                      HistoryLog.set({ group: "jobs", label: this.state._name })
                    );
                  });
                }
              );
              setTimeout((st) => Core.log(this.state));
            });
          }
        }
      );
    });
  }
  /** POST/UPDATE JOB */
  update = (success, final) => {
    this.setState((state) => {
      const job = state;
      const { employer } = job;
      if (this.isValid(state)) {
        if (job.id) {
          /* Jira Ticket Ticket VER-195 ===>>> */
          /* >Match candidate & job - prefill roles & visa. */
          /* -It is needed to remove searchConfig from the job
              in order to preset the chips on the filter for match
              when the job change.
          */
          job.searchConfig = {};
          /* <<<=== Jira Ticket Ticket VER-195 */
          Job.update(job.id, job, success);
        } else {
          Job.post(job, success);
        }
        if (Obj(employer).id) {
          Employer.update(employer.id, employer)
            .then(() => Object.assign(state, { saveEmployer: false }))
            .catch(Core.showError);
        }
      }
      return state;
    });
  };
  /** SAVE & NEXT BUTTON */
  submitThenGoNext = (ev) => {
    if (this.isValid()) {
      this.update((response) =>
        this.setState({ id: response.id }, (then) => Stepper.next())
      );
    }
  };
  goBack = (ev) => this.cancel();
  cancel = (ev) => Core.goBack(this.props);
  previous = (ev) => {
    Stepper.previous();
  };
  next = (ev) => {
    if ((this.state.step === 1) && !this.isValid()) {
      return;
    }
    Stepper.next();
  };
  save = (event) => {
    this.setState({ state: Definition.getId("state", "Draft") }, (then) =>
      this.submit()
    );
  };
  /**
   * SUBMIT; DONE BUTTON
   */
  submit = (ev) => {
    this.update((response) => {
      if (this.state.id) {
        this.successDialog.open("Job has been successfully updated");
      }
      else {
        this.setState({ id: response.id }, (then) => {
          this.successDialog.open("Job has been successfully created");
        });
      }
    }, true);
  };
  isValid = (state = this.state) => {
    if (!state.roles.length) {
      stepperScrollToTop();
      this.setState({
        snackbar: "Role is required",
        error: { role: required },
      });
      return false;
    }
    if (!state.employerId) {
      stepperScrollToTop();
      this.setState({
        snackbar: "Employer is required",
        error: { employer: required },
      });
      return false;
    }
    if (!String(state.jobTitle).trim().length) {
      stepperScrollToTop();
      this.setState({
        snackbar: "Job Title is required",
        errorJobTitle: required,
      });
      return false;
    }
    return true;
  };

  render() {

    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    if (Core.isAdmin() && !Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB })) {
      return <AccessDenied />;
    }

    const job = Obj(this.state);

    const _jobId = getParams({ pattern: '/job/edit/:id' }).id;

    const _headers = [
      "BASICS",
      "REQUIREMENTS",
      "PROCESS"
    ];

    JobEditController(this);

    return (
      <Page
        title={
          (_jobId ? "Edit " : "New ") +
          "Job" +
          (!!job.jobTitle
            ? " - " +
            (!!job.role
              ? Definition.getLabel("roles", job.role) + "/"
              : "") +
            job.jobTitle
            : "")
        }
        contentRight={
          <Box row wAuto noWrap alignRight>

            <IconButton acl={!!job.employerId && Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER })}
              title='Go to employer'
              onClick={(ev) => Core.go({
                ...Obj(this.props),
                to: `/employer/edit/${job.employerId}`
              })}
              icon='business'
            />

            <EmployerJobsDropdown
              employerId={job.employerId}
              className='mr-1'
            />

            <Box row wAuto noWrap acl={!!job.id}>

              {Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }) && (
                <IconButton
                  onClick={ev => Core.openPopUp(`#/job/view/${job.id}`, 1600)}
                  title={`See Job Card`}
                >
                  <i className="material-icons">visibility</i>
                </IconButton>
              )}

              {Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }) && (
                <IconButton
                  title='Open engagements details'
                  onClick={event => {
                    job.openEngagementsDetails();
                  }}
                >
                  <i className="material-icons">sync</i>
                </IconButton>
              )}

              {Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }) && (
                <IconButton
                  title='Open employer Portal'
                  onClick={(event) => openEmployerPendings({ employerId: job.employerId, jobId: job.id })}
                >
                  <i className="material-icons-outlined">thumbs_up_down</i>
                </IconButton>
              )}

              {Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_JOB }) && (
                <NavLink
                  title='Go to match'
                  to={`/job/match/${job.id}`}
                  icon='fact_check'
                />
              )}

            </Box>

            <HistoryMenu />

            <IconButton
              onClick={(event) => openMessageEmailPreview({ jobId: job.id, employerId: job.employerId })}
              title={`Open a message box`}
              className='hint--left'
            >
              <i className="material-icons">email</i>
            </IconButton>

            {job.id && (
              <MoreOptionsMenu
                row={{ original: mapJob({ ...job }) }}
                context={{
                  config: { moreMenuOptions: moreMenuOptionsJobs }
                }}
              />
            )}

          </Box>
        }
      >
        <Box row h100 w100
          className='flex-align-center-top'
        >

          <Box column h100 w100
            role='JobEdit__LeftSide'
            className='px-05'
            style={{ maxWidth: 800 }}
          >
            <Paper
              className='mx-auto'
              style={
                getDefaultPaperStyle({
                  height: '100%',
                  width: '100%',
                })
              }
            >
              <Stepper
                headers={_headers}
                toolBar={
                  <Button outlined minW120
                    label="SAVE"
                    onClick={this.submit}
                  />
                }
                views={[
                  <Basics parent={this} title={_headers[0]} />,
                  <Requirements parent={this} title={_headers[1]} />,
                  <Process parent={this} title={_headers[2]} />,
                ]}
                footerBar={
                  <Box row w100>
                    <Box row wAuto className='mr-auto'>
                      <Button outlined minW120 error
                        label="CANCEL"
                        onClick={this.goBack}
                        className='mr-auto'
                      />
                    </Box>
                    <Box row flex1 alignRight>
                      {this.state.step === 1 ? (
                        ""
                      ) : (
                        <Button outlined minW120 mr1
                          label="PREVIOUS"
                          onClick={this.previous}
                        />
                      )}
                      {
                        (
                          this.state.step === 3
                        ) ? (
                          <Button primary minW120
                            label="DONE"
                            onClick={this.submit}
                          />
                        ) : (
                          <>
                            <Button outlined minW120 mr1
                              label="NEXT"
                              onClick={this.next}
                            />
                            <Button minW120
                              label="SAVE & NEXT"
                              onClick={this.submitThenGoNext}
                            />
                          </>
                        )
                      }
                    </Box>
                  </Box>
                }
                step={this.state.step}
                onChange={(step) => this.setState({ step })}
              />

            </Paper>

          </Box>

          <Box column h100 w100
            role='JobEdit__RightSide'
            className='px-05 hidden-on-small-screen'
            style={{ maxWidth: 800 }}
          >

            <Paper
              className='mx-auto py-05'
              style={
                getDefaultPaperStyle({
                  height: '100%',
                  width: '100%',
                })
              }
            >

              <Fieldset
                title='Intake Note'
                actionBar={
                  <Button flat primary small
                    onClick={ev => {
                      this.setState({
                        employer: {
                          ...this.state.employer,
                          intakeNote: `${EMPLOYER__INTAKE_NOTE}<br/>${this.state.employer.intakeNote}`
                        }
                      });
                    }}
                    label='Insert template'
                    startIcon='arrow_downward'
                    className='c-purple-hover'
                  />
                }
                subtitle={EmployerUI.intakeNotes.warning}
              >

                <RichTextBox
                  value={this.state.employer.intakeNote}
                  onChange={value => {
                    clearTimeout(Core.getKeyValue('employerStickyNote.timeout'));
                    this.setState({ employer: { ...this.state.employer, intakeNote: value } });
                    Core.setKeyValue('employerStickyNote.timeout', setTimeout(() => {
                      Employer.update(this.state.employerId, { intakeNote: String(value).trim() });
                    }, 1000));
                  }}
                  taller
                />

              </Fieldset>

            </Paper>

          </Box>

        </Box>

        <SuccessDialog
          Controller={this}
          actions={[
            <Button outlined minW120>
              STAY
            </Button>,
            Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }) && (
              <Button outlined
                onClick={(ev) => Core.openSameWindow(`#/job/view/${this.state.id}`)}
                className='min-w-120'
              >
                GO TO DETAILS
              </Button>
            ),
            <Button primary autoFocus
              onClick={(ev) => Core.go({ ...this.props, to: Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }) ? '/jobs/' : '/' })}
              className='min-w-120'
            >
              DONE
            </Button>,
          ]}
        />

        <Snackbar
          open={!!this.state.snackbar}
          message={this.state.snackbar || ""}
          className="snack-bar"
          autoHideDuration={6000}
          onClose={(ev) => this.setState({ snackbar: null })}
        />

      </Page>
    );
  }
}

export function JobEditController(controller) {
  if (isObject(controller)) {
    return Core.setKeyValue('JobEditController', controller);
  }
  return Obj(Core.getKeyValue('JobEditController'));
}
