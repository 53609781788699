import {
  Component
} from "react";
import {
  withTranslation
} from 'react-i18next';
import AccountLib from '../../../lib/Account';
import Core from "../../../lib/Core";
import Definition, {
  POSITIVE_SIGNALS__GITHUB,
  POSITIVE_SIGNALS__GREAT_GITHUB,
  POSITIVE_SIGNALS__IMPRESSIVE_GITHUB,
  POSITIVE_SIGNALS__STRONG_GITHUB
} from "../../../lib/Definition";
import {
  getPersonName
} from '../../../lib/Object.lib';
import githubGraphqlGetUser, {
  getGitHubTag
} from '../../../lib/services/GitHub/githubGraphqlGetUser';
import cleanHtml from "../../../lib/tools/cleanHtml";
import Chips from "../../Forms/Chips";
import StepHeader from '../../Forms/StepHeader';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Col from "../../Layout/Wrappers/Col";
import DatePicker from '../../Layout/Wrappers/DatePicker';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import List from '../../Layout/Wrappers/List';
import Menu from '../../Layout/Wrappers/Menu';
import RadioGroup from '../../Layout/Wrappers/RadioGroup';
import RichTextBox from "../../Layout/Wrappers/RichTextBox";
import Row from "../../Layout/Wrappers/Row";
import TextField from '../../Layout/Wrappers/TextField';
import {
  Label
} from '../../Layout/Wrappers/Typography';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import MatchExclusionFieldset from '../Forms/MatchExclusionFieldset';
import mapCandidateSignalsOnChange from '../mapCandidateSignalsOnChange.tool';
import FieldsetPlatformRating from '../PlatformRating/FieldsetPlatformRating';

class Admin extends Component {
  state = {
    githubLabel: ''
  };
  componentDidMount() {
    const CandidateEditController = this.props.parent;
    let { state: candidate } = CandidateEditController;
    githubGraphqlGetUser({ gitHubURL: candidate.gitHubURL, candidate }).then(gitHubStats => {
      let gitHubTagId = getGitHubTag({ gitHubStats, candidate }).id;
      if (!!gitHubTagId) {
        const gitHubTagIds = [
          POSITIVE_SIGNALS__GITHUB,
          POSITIVE_SIGNALS__GREAT_GITHUB,
          POSITIVE_SIGNALS__STRONG_GITHUB,
          POSITIVE_SIGNALS__IMPRESSIVE_GITHUB
        ];
        const currentLabelIds = candidate.positiveSignals.filter(id => !gitHubTagIds.includes(id));
        CandidateEditController.setStateStore({ positiveSignalsManual: [...new Set([...currentLabelIds, gitHubTagId])] });
        setTimeout(() => {
          delete CandidateEditController.__mappedSignals;
          CandidateEditController.setState({});
        });
        const label = Definition.getLabel('positiveSignals', gitHubTagId);
        if (label) { this.setState({ githubLabel: label }); }
      }
    });
  }

  render() {
    const CandidateEditController = this.props.parent;
    let candidate = CandidateEditController.state;
    const { githubLabel } = candidate;
    const areOpenEngagements = CandidateEditController.state.engagements.find(
      eng => eng.state === "Open"
    );

    return (
      <List>

        <StepHeader {...this.props} />

        {CandidateEditController.state.initiallyIsDuplicate && (
          <Fieldset
            title={
              <>
                <Label mr>
                  Duplicate Candidate
                </Label>
                <Checkbox
                  checked={CandidateEditController.state.isDuplicate}
                  onChange={(event) => {
                    const isDuplicate = !!event.target.checked;
                    CandidateEditController.setStateStore({ isDuplicate }, then => {
                      if (!CandidateEditController.state.warnedDuplicateDetectionOff) {
                        Core.showWarning('WARNING, duplicate detection is off.  Re-edit to enable duplicate detection once save as not a duplicate');
                        CandidateEditController.setStateStore({
                          warnedDuplicateDetectionOff: true,
                          key: 'warnedDuplicateDetectionOff'
                        });
                      }
                    });
                  }}
                />
              </>
            }
          />
        )}
        <Row>
          <Col>
            <label>Recruiter</label>
            <Menu dropdown
              name="recruiter"
              value={CandidateEditController.state.accountId || Core.getUserId()}
              onChange={(accountId) => {
                AccountLib.get(accountId).then((recruiter) => {
                  CandidateEditController.setStateStore({
                    accountId,
                    key: 'accountId',
                    account: recruiter,
                    recruiter
                  });
                }).catch(Core.showError);
              }}
              options={
                CandidateEditController.state.recruiters.sort((a, b) => {
                  a = String(a.firstName || a.email).toLowerCase();
                  b = String(b.firstName || b.email).toLowerCase();
                  return a > b ? 1 : a < b ? -1 : 0;
                })
              }
              optionMapper={(option) => ({ ...option, label: getPersonName(option) })}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col fullWidth>
            <Chips
              name="positiveSignals"
              label={(
                <>
                  Positive signals<br />
                  {!!CandidateEditController.state.linkedInSuggestedPosSignals?.filter(v => !!v).length && (
                    <p className='f-md'>
                      Suggested From Linkedin : ( {
                        Definition.getLabels('positiveSignals', CandidateEditController.state.linkedInSuggestedPosSignals).filter(v => !!v).join(', ')
                      } )
                    </p>
                  )}
                  {!!githubLabel && (<p className='f-md'>Suggested From Github : ( {githubLabel} )</p>)}
                </>
              )}
              heading={(
                <div className='c-purple m-0'>
                  Positive Signals
                </div>
              )}
              values={CandidateEditController.state.positiveSignals}
              onChange={signals => mapCandidateSignalsOnChange({
                candidate,
                signals,
                key: 'positiveSignals',
                updater: CandidateEditController.setStateStore
              })}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col fullWidth>
            <Chips
              name="negativeSignals"
              label='Negative signals'
              heading={(
                <div className='c-purple m-0'>
                  Negative signals
                </div>
              )}
              values={CandidateEditController.state.negativeSignals}
              onChange={signals => mapCandidateSignalsOnChange({
                candidate,
                signals,
                key: 'negativeSignals',
                updater: CandidateEditController.setStateStore
              })}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col fullWidth>
            <label>
              {CANDIDATE_UI.tagLine.title}
            </label>
            <TextField
              name="tagLine"
              rows={1}
              rowsMax={5}
              multiLine={true}
              value={CandidateEditController.state.tagLine}
              onChange={(ev, tagLine) => CandidateEditController.setStateStore({ tagLine, key: 'tagLine' })}
              fullWidth
            />
          </Col>
        </Row>

        <Fieldset
          title={CANDIDATE_UI.submissionNotesToEmployer.title}
          actionBar={
            <>
              <Button
                title={`Insert recruiter's full write up to the beginning of the text box below`}
                startIcon={<i className="material-icons c-inherit">content_paste_go</i>}
                variant="outlined"
                color="secondary"
                size="small"
                className="ml-auto text-truncate flex-align-left-center"
                onClick={event => {
                  let original = String(CandidateEditController.state.submissionNotesToEmployer).replace('<p><br></p>', '').trim();
                  let insert = String(CandidateEditController.state.submissionNotes).replace('<p><br></p>', '').trim();
                  if (insert.length) {
                    let newNotes = cleanHtml(`${insert}${original.length ? `<p>-----------</p>${original}` : ''}`);
                    CandidateEditController.setStateStore({
                      submissionNotesToEmployer: newNotes
                    });
                  }
                  else {
                    Core.showWarning('Nothing to insert');
                  }
                }}
              >
                Insert recruiter write up
              </Button>
            </>
          }
        >
          <RichTextBox
            name="submissionNotesToEmployer"
            value={CandidateEditController.state.submissionNotesToEmployer}
            onChange={submissionNotesToEmployer => {
              CandidateEditController.setStateStore({
                submissionNotesToEmployer,
                key: 'submissionNotesToEmployer'
              });
            }}
          />
        </Fieldset>

        <FieldsetPlatformRating />

        <Row className='mt-3'>
          <Col fullWidth>
            <label>Current state</label>
            <RadioGroup
              name="state"
              options={
                Definition.get("state").filter(
                  tag => Core.isAdminOrCoordinator() ? true : Definition.test("state", tag.id, /draft/)
                ).map(
                  (tag) => ({
                    ...tag,
                    disabled: !!(
                      tag.label === "Hold" || // Temporaly disabled 2018-09-05
                      (tag.label === "Hold" && !areOpenEngagements) ||
                      (tag.label === "Inactive" && areOpenEngagements)
                    )
                  })
                )
              }
              value={CandidateEditController.state.state}
              onChange={(event, state) => {
                let holdDate = {};
                if (
                  Definition.test("state", CandidateEditController.state.state, /Active/)
                ) {
                  holdDate = { holdDate: null };
                }
                CandidateEditController.setStateStore({ state, ...holdDate, key: 'state' }, then => {
                  !!this.pickerHoldDate &&
                    this.pickerHoldDate.openDialog &&
                    this.pickerHoldDate.openDialog();
                  !!this.pickerWakeUpDate &&
                    this.pickerWakeUpDate.openDialog &&
                    this.pickerWakeUpDate.openDialog();
                });
              }}
              fullWidth
            />
          </Col>
        </Row>

        <Row className='mt-3 px-1'>
          <MatchExclusionFieldset CandidateEditController={CandidateEditController} />
        </Row>

        {Definition.test("state", CandidateEditController.state.state, /Hold/) && (
          <Row className='mt-3'>
            <Col>
              <label>Hold date</label>
              <DatePicker
                name="holdDate"
                ref={picker => (this.pickerHoldDate = picker)}
                title="Set Hold Date"
                value={
                  CandidateEditController.state.holdDate
                    ? new Date(CandidateEditController.state.holdDate)
                    : null
                }
                onChange={(holdDate) =>
                  CandidateEditController.setStateStore({ holdDate: holdDate.toISOString(), key: 'holdDate' })
                }
              />
            </Col>
          </Row>
        )}
        {Definition.test("state", CandidateEditController.state.state, /Inactive/) && (
          <Row className='mt-3'>
            <Col>
              <label>Wake up date</label>
              <DatePicker
                name="wakeUpDate"
                ref={picker => (this.pickerWakeUpDate = picker)}
                title="Set Wake Up Date"
                value={
                  CandidateEditController.state.wakeUpDate
                    ? new Date(CandidateEditController.state.wakeUpDate)
                    : null
                }
                onChange={(wakeUpDate) =>
                  CandidateEditController.setStateStore({ wakeUpDate: wakeUpDate.toISOString(), key: 'wakeUpDate' })
                }
              />
            </Col>
          </Row>
        )}

        <Row className='mt-3'>
          <Col fullWidth>
            <label>Private notes</label>
            <TextField
              name="privateNotes"
              rows={6}
              rowsMax={12}
              value={CandidateEditController.state.privateNotes}
              onChange={(ev, privateNotes) =>
                CandidateEditController.setStateStore({ privateNotes, key: 'privateNotes' })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col fullWidth>
            <label>Conversation history</label>
            <TextField
              name="publicNotes"
              rows={6}
              rowsMax={12}
              value={CandidateEditController.state.publicNotes}
              onChange={(ev, publicNotes) => CandidateEditController.setStateStore({ publicNotes, key: 'publicNotes' })}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <label>Introduced date</label>
            <DatePicker
              name="introduced"
              value={
                CandidateEditController.state.introduced
                  ? new Date(CandidateEditController.state.introduced)
                  : null
              }
              onChange={(introduced) => {
                CandidateEditController.setStateStore({ introduced: introduced.toISOString(), key: 'introduced' });
              }}
              fullWidth
            />
          </Col>
          <Col>
            <label>Expiration date</label>
            <DatePicker
              name="expirationDate"
              value={
                CandidateEditController.state.expirationDate
                  ? new Date(CandidateEditController.state.expirationDate)
                  : null
              }
              onChange={(expirationDate) => {
                CandidateEditController.setStateStore({
                  expirationDate: expirationDate.toISOString(),
                  key: 'expirationDate'
                });
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Closed date</label>
            <DatePicker
              name="closeDate"
              value={
                CandidateEditController.state.closeDate ? new Date(CandidateEditController.state.closeDate) : null
              }
              onChange={(ev, closeDate) => {
                CandidateEditController.setStateStore({ closeDate: closeDate.toISOString(), key: 'closeDate' });
              }}
              fullWidth
            />
          </Col>
          <Col>
            <label>Last update</label>
            <DatePicker
              name="updatedAt"
              value={
                CandidateEditController.state.updatedAt ? new Date(CandidateEditController.state.updatedAt) : null
              }
              onChange={(updatedAt) => {
                CandidateEditController.setStateStore({ updatedAt: updatedAt.toISOString(), key: 'updatedAt' });
              }}
              fullWidth
            />
          </Col>
        </Row>
      </List >
    );
  }
}

export default withTranslation()(Admin);
