/**
 * [2025-01-30][11824]
 * @note
 * This file describes the model and the options used in Engagement.feedback
 */

import {
  Arr,
  join
} from '../Array.lib';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON
} from '../Definition';

const MODEL__ENGAGEMENT__FEEDBACK = {
  reasons: [],
};

/**
 * Creates a new engagement feedback object by merging default feedback properties
 * with any additional properties provided.
 *
 * @param {Object} [props={}] - Optional properties to override or extend the default feedback model.
 * @returns {Object} A new engagement feedback object with the combined properties.
 */
export default function getNewEntryEngagementFeedback(props = {}) {
  return { ...MODEL__ENGAGEMENT__FEEDBACK, ...props };
}

/**
 * Prepends new engagement feedback reasons to the existing feedback array.
 *
 * @param {Object} options - The options for the function.
 * @param {Object} options.engagement - The engagement object to update.
 * @param {Array} [options.engagement.feedback=[]] - An array of existing feedback entries.
 * @param {Array} [options.labels=[]] - An array of reason labels to convert to tag IDs if `tagIds` is not provided.
 * @param {Array} [options.tagIds=undefined] - An optional array of reason TagIds to use directly.
 * @returns {Array} An array with the new feedback entry prepended to the existing feedback array.
 */
export function getNewPrependedEngagementFeedback({
  engagement,
  labels = [],
  ids = undefined
}) {
  const reasons = ids || Definition.getTagIds({
    categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON,
    labels
  });
  return [
    getNewEntryEngagementFeedback({ reasons }),
    ...Arr(engagement.feedback)
  ];
}

/**
 * Retrieves the reasons for the latest engagement feedback.
 *
 * @param {Object} engagement - The engagement object.
 * @param {Array} engagement.feedback - An array of feedback entries.
 * @returns {Array} An array of reason TagIds from the latest feedback entry, or an empty array if not available.
 */
export function getIdsLatestEngagementFeedbackReasons(engagement = {}) {
  return Arr(Arr(engagement?.feedback)[0]?.reasons);
}

/**
 * Retrieves the reason labels for the latest engagement feedback.
 *
 * @param {Object} engagement - The engagement object.
 * @param {Array} engagement.feedback - An array of feedback entries.
 * @returns {Array} An array of reason labels from the latest feedback entry, or an empty array if not available.
 */
export function getLabelsLatestEngagementFeedbackReasons(engagement = {}) {
  return Definition.getLabels(
    DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON,
    getIdsLatestEngagementFeedbackReasons(engagement)
  );
}

/**
 * Joins the reason labels for the latest engagement feedback into a single string.
 *
 * @param {Object} engagement - The engagement object.
 * @param {Array} engagement.feedback - An array of feedback entries.
 * @returns {string} A joined string of reason labels from the latest feedback entry, or an empty string if not available.
 */
export function getJoinedLatestEngagementFeedbackReasons(engagement = {}) {
  return join(getLabelsLatestEngagementFeedbackReasons(engagement));
}

/**
 * Retrieves a string describing the reason for the engagement feedback.
 * If the engagement has feedback, it returns the joined reason labels from the latest feedback entry.
 * If the engagement does not have feedback, it returns the engagement's rejectionReason.
 *
 * @param {Object} engagement - The engagement object.
 * @param {Array} engagement.feedback - An array of feedback entries.
 * @param {string} engagement.rejectionReason - The rejection reason for the engagement.
 * @returns {string} A string describing the reason for the engagement feedback.
 */
export function getJoinedFeedbackOrRejectionReason(engagement = {}) {
  return getJoinedLatestEngagementFeedbackReasons(engagement) || engagement.rejectionReason;
}
