import {
  Component
} from 'react';
import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH
} from '../../../lib/Definition';
import getEngagementFeedbackReasons from '../../Engagements/Libraries/getEngagementFeedbackReasons.fun';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Dash from '../../Layout/Wrappers/Dash';
import Dialog from '../../Layout/Wrappers/Dialog';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import Once from '../../Layout/Wrappers/Once';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';
import {
  getTDModel
} from '../Libraries/Match.lib';
import {
  isMatchDecisionNo
} from '../Libraries/MatchDecisions.lib';
import {
  getMatchEntities,
  getMatches,
  pushingToProfileOnThumbsDown
} from '../Libraries/MatchList.lib';
import {
  MatchPageController
} from '../MatchPage';
import {
  MATCH_DECISION_DETAILS__CONFIG
} from './MatchDecisionDetails';
import {
  showErrorUnexpectedMatchStrength
} from './ThumbsBar';

/**
 * @param {React.Component} props.Controller  ListHeader | SingleJobCard | SingleCandidateCard
 */
class CreateDisagreement extends Component {
  constructor(props) {
    super(...arguments);
    const {
      Controller = {}
    } = this.props;
    Object.assign(Controller, { createDisagreementDialog: this });
    this.state = {
      ...this.props,
      open: false,
      whyNoDetails: '',
      shouldTag: '',
      shouldNotTag: '',
      whyNoPrivateNote: '',
      whyNeedToReadCV: '',
      whyNoCategories: [],
      fromAnnotatorList: Array.from(
        new Set(
          [
            'bob@10by10.io',
            'april@10by10.io',
            'andy@10by10.io',
            'thomas@10by10.io',
            this.props.fromAnnotator,
          ].filter((el) => !!el)
        )
      ),
    };
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({
    open: false,
    whyNoDetails: '',
    shouldTag: '',
    shouldNotTag: '',
    whyNoPrivateNote: '',
    whyNeedToReadCV: '',
    whyNoCategories: []
  });

  specialEngagementCreation = (
    {
      match = {}, // Current match, not necessarily the same as List or Card match.
      whyNoCategories = [],
      whyNoDetails,
      shouldTag,
      shouldNotTag,
      whyNoPrivateNote,
      whyNeedToReadCV,
    },
    {
      disagreementModel = {}
    }
  ) => {

    // CONTROLLER: ListHeader OR SingleCandidateCard OR SingleJobCard
    const { Controller = {} } = this.state;

    // GET ENTITIES
    const {
      candidate = {},
      job = {}
    } = getMatchEntities({ match });

    // GET CONTROLLER STATE
    const {
      engagement = {},
      candoPrivateNoteText: statusNote = '',
      reviewed = false,
    } = Controller.state;

    // GET DECISION TAG
    const matchStrength = Controller.thumbsBar.state.matchStrength;
    const matchStrengthTag = Definition.getTag({
      categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
      tagId: matchStrength
    });
    if (NOT(matchStrengthTag.id)) {
      return showErrorUnexpectedMatchStrength(
        matchStrength,
        matchStrengthTag.id
      );
    }

    // TD MODEL, VALUES FROM
    const {
      stage,
      status,
      state,
      rejectionReason,
      rejectionReasonAdditionalInfo,
      feedback
    } = getTDModel({
      engagement,
      candidate,
      job,
      whyNoCategories,
      whyNoDetails
    });

    // ENGAGEMENT MODEL
    const model = {
      ...disagreementModel,
      stage,
      status,
      statusNote,
      state,
      rejectionReason,
      rejectionReasonAdditionalInfo,
      feedback,
      matchStrength: matchStrengthTag.id,
      matchStrengthLabel: matchStrengthTag.label,
      reviewed,
    };

    // CREATE OR FIND ENGAGEMENT
    // + CREATE OR UPDATE DISAGREEMENT
    MatchPageController().createSingleEngagementWithDis(match, model);

    // NO, DECISION (TD)
    if (isMatchDecisionNo(matchStrengthTag)) {
      pushingToProfileOnThumbsDown({ candidate, job });
    }

  }

  createDisagreement = () => {

    // CONTROLLER: ListHeader OR SingleCandidateCard OR SingleJobCard
    const { Controller = {} } = this.props;

    // FROM CURRENT STATE
    const {
      whyNoCategories,
      whyNoDetails,
      shouldTag,
      shouldNotTag,
      whyNoPrivateNote,
      whyNeedToReadCV,
    } = this.state;

    // CREATE OR FIND ENGAGEMENT
    // + CREATE OR UPDATE DISAGREEMENT
    // + PUSH INTO PROFILE TDs
    const _createWith = (match) => {
      this.specialEngagementCreation(
        {
          match,
          whyNoCategories,
          whyNoDetails,
          shouldTag,
          shouldNotTag,
          whyNoPrivateNote,
          whyNeedToReadCV,
        },
        {
          disagreementModel: match
        }
      );
    };
    // FROM LIST HEADER
    if (Controller.role === 'ListHeader') {
      getMatches().forEach(_createWith);
    }
    // FROM SINGLE CARD
    else {
      _createWith(Controller.props.match);
    }

  };

  handleSelectChanged = (key) => (value) => {
    this.setState({ [key]: value });
  };

  render() {
    const {
      Controller = {},  // ListHeader || SingleCandidateCard || SingleJobCard
      open = false,
      whyNoDetails,
      whyNoCategories,
      shouldTag,
      shouldNotTag,
      whyNoPrivateNote,
      whyNeedToReadCV,
    } = this.state;
    const {
      profile = {},
      selectedMatch = {},
    } = MatchPageController().state;
    const {
      match = {},
    } = Controller.state;
    const { job } = getMatchEntities({ profile, match });
    return (
      <Dialog
        open={open}
        onClose={this.close}
        paperStyle={{ width: 900 }}
        title={
          <Box row w100>
            <Typography strong>
              {
                (match._shortName || match._name) ||
                (selectedMatch._shortName || selectedMatch._name)
              }
            </Typography>
            <Box row w100 small className='c-black-dark'>
              <Dash mx />
              <Typography>
                {profile._shortName || profile._name}
              </Typography>
            </Box>
          </Box>
        }
        content={
          <Box column w100>
            <Once
              promise={async () => {
                this.setState({
                  engagementFeedbackReasons: await getEngagementFeedbackReasons({
                    jobId: job.id
                  })
                });
              }}
            />
            <Fieldset title={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyNoCategories}>
              <Menu multiple
                value={whyNoCategories}
                onChange={this.handleSelectChanged('whyNoCategories')}
                options={Arr(this.state.engagementFeedbackReasons)}
                autoFocus
              />
            </Fieldset>
            <Fieldset title="Disagreement reason">
              <TextField disabledBlurExport autoFocus
                id="whyNoDetails"
                value={whyNoDetails}
                onChange={(event, whyNoDetails) => {
                  this.setState({ whyNoDetails });
                }}
                placeholder={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyNoDetails}
              />
            </Fieldset>
            <Fieldset title="Why no private note">
              <TextField
                id="whyNoPrivateNote"
                value={whyNoPrivateNote}
                onChange={(event, whyNoPrivateNote) => {
                  this.setState({ whyNoPrivateNote });
                }}
                placeholder={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyNoPrivateNote}
                fullWidth
              />
            </Fieldset>
            <Fieldset title="Should tag">
              <TextField
                id="shouldTag"
                value={shouldTag}
                onChange={(event, shouldTag) => {
                  this.setState({ shouldTag });
                }}
                placeholder={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.shouldTag}
                fullWidth
              />
            </Fieldset>
            <Fieldset title="Should not tag">
              <TextField
                id="shouldNotTag"
                value={shouldNotTag}
                onChange={(event, shouldNotTag) => {
                  this.setState({ shouldNotTag });
                }}
                placeholder={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.shouldNotTag}
                fullWidth
              />
            </Fieldset>
            <Fieldset title="Why need to re-read CV">
              <TextField
                id="whyNeedToReadCV"
                value={whyNeedToReadCV}
                onChange={(event, whyNeedToReadCV) => {
                  this.setState({ whyNeedToReadCV });
                }}
                placeholder={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyNeedToReadCV}
                fullWidth
              />
            </Fieldset>
          </Box>
        }
        actions={[
          <Button outlined minW120
            onClick={this.close}
          >
            Cancel
          </Button>,
          <Button primary minW120
            onClick={this.createDisagreement}
          >
            Proceed
          </Button>
        ]}
      />
    );
  }
}

export default CreateDisagreement;
