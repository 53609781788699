import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Row from '../../Layout/Wrappers/Row';
import {
  PLACEMENT__RIGHT_START
} from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';

export default function RejectionAdditionalInfo(props) {
  const {
    EngagementCardController = {},
    autoFocus = true,
  } = props;
  const [
    { __includeRejectionEmailAdditionalInfo },
    _updateState
  ] = useStateObserved({
    observed: {
      __includeRejectionEmailAdditionalInfo: (
        EngagementCardController.state.__includeRejectionEmailAdditionalInfo
      )
    }
  });
  const _onCheck = async (event) => {
    const update = {
      __includeRejectionEmailAdditionalInfo: !__includeRejectionEmailAdditionalInfo
    }
    await _updateState(update);
    EngagementCardController.setState(Object.assign(EngagementCardController.state, update));
  };
  if (
    EngagementCardController.state.rejectionReasonAdditionalInfo === undefined
  ) {
    EngagementCardController.state.rejectionReasonAdditionalInfo = trim(
      EngagementCardController.state.rejectionReason
    );
  }
  return (
    <Row>
      <Fieldset
        title='Rejection Reason (additional info)'
        wrapperProps={{ className: 'p-0', fullWidth: true }}
        nextToTitle={
          <Checkbox
            title={
              <>
                Check this box if you would like to include the input text in the rejection email.<br />
                The input text will also be prepended to the recruiter feedback log.
              </>
            }
            placement={PLACEMENT__RIGHT_START}
            checked={!!__includeRejectionEmailAdditionalInfo}
            onChange={_onCheck}
            labelProps={{ className: 'c-black-medium f-sm fw-400 nowrap' }}
          >
            Notify recruiter with the added details
          </Checkbox>
        }
      >
        <TextField
          autoFocus={autoFocus}
          name="rejectionPhrase"
          placeholder="Introduce additional info"
          value={Str(EngagementCardController.state.rejectionReasonAdditionalInfo)}
          onChange={async (event) => {
            const update = {
              rejectionReasonAdditionalInfo: Str(event.target.value)
            }
            EngagementCardController.setState(update);
            await _updateState(Object.assign(EngagementCardController.state, update));
          }}
          fullWidth
          size='small'
          variant='standard'
        />
      </Fieldset>
    </Row >
  );
}
