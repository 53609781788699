import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON
} from '../../../lib/Definition';
import {
  OPTIONS__DEFAULT__ADMIN__ENGAGEMENT_FEEDBACK_REASONS,
  OPTIONS__DEFAULT__COMMON__ENGAGEMENT_FEEDBACK_REASONS
} from '../../../lib/models/EngagementFeedbackReason.model';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  COLLECTION__JOBS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';

const cache = {};

/**
 * Get engagement feedback reasons.
 *
 * @param {Object} options - Options
 * @param {string} [options.jobId] - Job ID
 * @param {boolean} [options.filterNotInterested=true] - Filter out "not interested" as a reason
 * @param {string[]} [options.filteredTagIds=[]] - Filter out reason tag IDs
 * @return {Promise<string[]>} - Reason labels
 */
export default async function getEngagementFeedbackReasons({
  jobId = ''
} = {}) {
  const reasons = [];
  if (jobId) {
    // If the cache is older than 1 second, refresh it,
    // to prevent multiple requests for the same job due to React re-renders.
    cache[jobId] = Obj(cache[jobId]);
    const YES__CACHE_IS_OLD = (
      NOT(cache[jobId].timestamp) ||
      ((Date.now() - cache[jobId].timestamp) > 1000)
    );
    if (YES__CACHE_IS_OLD) {
      cache[jobId].timestamp = Date.now();
      cache[jobId].reasons = Arr(
        await readLoopbackRecord({
          collection: COLLECTION__JOBS,
          where: { id: jobId },
          limit: 1,
          fields: ['id', 'engagementFeedbackReasons'],
          mapper: ({ engagementFeedbackReasons }) => {
            return Definition.getLabels(
              DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON,
              Arr(engagementFeedbackReasons).map(({ reason }) => (reason))
            );
          }
        })
      );
    }
    reasons.push(...Arr(cache[jobId].reasons));
  }
  if (NOT(reasons.length)) {
    reasons.push(
      ...Definition.getTags({
        categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON,
        tagIds: (
          Core.isAdmin()
            ? OPTIONS__DEFAULT__ADMIN__ENGAGEMENT_FEEDBACK_REASONS
            : OPTIONS__DEFAULT__COMMON__ENGAGEMENT_FEEDBACK_REASONS
        ).map(({ reason }) => (reason)),
      }
      ).map(
        ({ label }) => (label)
      )
    );
  }
  return reasons;
}
