import {
  CSS_CYAN_DARKER_RGB
} from '../Constants';

const TEMPLATE__DIFFERENT_CANDIDATE_PLATFORM_RATING = {
  subject: '[ Cando Rating Disagreement ] Platform recommends: {{SYSTEM_RATING}} | {{USER}}: {{CANDIDATE_RATING}}',
  body: (`
    <div>
      {{{LINK__CANDIDATE_EDIT}}}
    </div>
    <br/>
    <div>
      <strong style="color: ${CSS_CYAN_DARKER_RGB};">
        Platform rating:&nbsp;
      </strong>
      {{SYSTEM_RATING}}
    </div>
    <br/>
    <div>
      <strong style="color: ${CSS_CYAN_DARKER_RGB};">
        {{USER}} rating:&nbsp;
      </strong>
      {{CANDIDATE_RATING}}
    </div>
    {{#if NOTES__PLATFORM_RATING}}
      <br/>
      <div>
        <strong style="color: ${CSS_CYAN_DARKER_RGB};">
          Why disagree
        </strong><br />
        <span>{{NOTES__PLATFORM_RATING}}</span>
      </div>
    {{/if}}
    {{#if POSITIVE_SIGNALS}}
      <br/>
      <div>
        <strong style="color: ${CSS_CYAN_DARKER_RGB};">
          Positive signals
        </strong><br />
        <span>
          {{POSITIVE_SIGNALS}}
        </span>
      </div>
    {{/if}}
    {{#if NEGATIVE_SIGNALS}}
      <br/>
      <div>
        <strong style="color: ${CSS_CYAN_DARKER_RGB};">
          Negative signals
        </strong><br />
        <span>
          {{NEGATIVE_SIGNALS}}
        </span>
      </div>
    {{/if}}
  `)
};

export default TEMPLATE__DIFFERENT_CANDIDATE_PLATFORM_RATING;
