import {
  Arr
} from '../../../../lib/Array.lib';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON
} from '../../../../lib/Definition';
import useState from '../../../../lib/hooks/useState.hook';
import { joinKeyName } from '../../../../lib/String.lib';
import Box from '../../../Layout/Wrappers/Box';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import IconButton from '../../../Layout/Wrappers/IconButton';
import Menu from '../../../Layout/Wrappers/Menu';
import Table from '../../../Layout/Wrappers/Table';
import {
  JobEditController
} from '../JobEdit';
import MODEL__ENGAGEMENT_FEEDBACK_REASON, {
  OPTIONS__ENGAGEMENT_FEEDBACK_REASON__BY_WHO,
  OPTIONS__ENGAGEMENT_FEEDBACK_REASON__STAGES
} from '../../../../lib/models/EngagementFeedbackReason.model';

export default function FieldsetEngagementFeedbackReasons(
  showSave = true,
  ...props
) {
  const [state, updateState] = useState();
  const data = Arr(state.data || JobEditController().state.engagementFeedbackReasons);
  const _onChange = () => {
    JobEditController().setState({
      engagementFeedbackReasons: data
    });
  }
  const _addReason = async (event) => {
    data.push({ ...MODEL__ENGAGEMENT_FEEDBACK_REASON });
    await updateState({ data });
    _onChange();
  }
  const _removeReason = (index) => async (event) => {
    data.splice(index, 1);
    await updateState({ data });
    _onChange();
  }
  const _updateReasonTagId = (index) => async (reason) => {
    data[index].reason = reason;
    await updateState({ data });
    _onChange();
  }
  const _updateReasonStage = (index) => async (stage) => {
    data[index].stage = stage;
    await updateState({ data });
    _onChange();
  }
  const _updateReasonByWho = (index) => async (byWho) => {
    data[index].byWho = byWho;
    await updateState({ data });
    _onChange();
  }
  return (
    <Fieldset
      title="Engagement Feedback Reasons"
    >
      <Box className='outlined rounded p-05'>
        <Table styled size="small">
          <Table.Head >
            <Table.Row className='border-bottom'>
              <Table.Cell style={{ width: 60 }}>#</Table.Cell>
              <Table.Cell>Reason</Table.Cell>
              <Table.Cell className='d-none'>By who</Table.Cell>
              <Table.Cell className='d-none'>Stage</Table.Cell>
              <Table.Cell className='align-right c-white-hover' style={{ width: 60 }}>
                <IconButton
                  className='c-white icon24 mx-1'
                  onClick={_addReason}
                >
                  <i className="material-icons">add_circle_outline</i>
                </IconButton>
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body className=''>
            {data.map((item, index) => (
              <Table.Row
                key={joinKeyName([
                  'engagementFeedbackReason',
                  index
                ])}
                className='border-bottom hover-bg'
              >
                <Table.Cell>
                  {index + 1}
                </Table.Cell>
                <Table.Cell style={{ width: 300 }}>
                  <Menu dropdown avoidNullOption
                    value={item.reason}
                    onChange={_updateReasonTagId(index)}
                    options={Definition.get(DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON)}
                  />
                </Table.Cell>
                <Table.Cell style={{ width: 300 }} className='d-none'>
                  <Menu dropdown avoidNullOption
                    value={item.byWho}
                    onChange={_updateReasonByWho(index)}
                    options={OPTIONS__ENGAGEMENT_FEEDBACK_REASON__BY_WHO}
                  />
                </Table.Cell>
                <Table.Cell style={{ width: 300 }} className='d-none'>
                  <Menu dropdown avoidNullOption
                    value={item.stage}
                    onChange={_updateReasonStage(index)}
                    options={OPTIONS__ENGAGEMENT_FEEDBACK_REASON__STAGES}
                  />
                </Table.Cell>
                <Table.Cell className='align-right'>
                  <Box row noWrap>
                    <IconButton
                      onClick={_removeReason(index)}
                    >
                      <i className="material-icons">remove_circle_outline</i>
                    </IconButton>
                  </Box>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Box>
    </Fieldset>
  );
}
