import {
  ENGAGEMENT__EMPLOYER_REJECTION_REASON_ORDER,
  STAGE_CONFIRMATION,
  STAGE_REVIEW,
  STAGE_SUBMISSION,
  STATUS_E_10X10,
  STATUS_E_CANDIDATE,
  STATUS_E_EMPLOYER,
  STATUS_E_RECRUITER
} from '../../../dictionaries/Engagement.dic';
import {
  Arr,
  join,
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON
} from '../../../lib/Definition';
import {
  NOT,
  YES
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import Job, {
  prependJobFeedbackLog
} from '../../../lib/Job';
import {
  mapCandidateName
} from '../../../lib/models/candidate';
import {
  getIdsLatestEngagementFeedbackReasons,
  getJoinedFeedbackOrRejectionReason,
  getLabelsLatestEngagementFeedbackReasons,
  getNewPrependedEngagementFeedback
} from '../../../lib/models/EngagementFeedback.model';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  ACTION_SOURCE__ENGAGEMENT,
  ACTION_TYPE__REJECT
} from '../../Employers/EmployerPendings/Libraries/EmployerPendings.dic';
import prependLogEngagementFeedbackToRecruiter from '../../Employers/EmployerPendings/Libraries/prependLogEngagementFeedbackToRecruiter.fun';
import {
  REACT_TABLE__COLUMN_SM
} from '../../Home/useEnhancedReactTable.hook';
import JobFeedbacks, {
  JOB__INTERVIEW_FEEDBACK__TAB,
  JOB__RESUME_FEEDBACK__TAB
} from '../../Jobs/Edit/Forms/JobFeedbacks';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Card from '../../Layout/Wrappers/Card';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Dialog from '../../Layout/Wrappers/Dialog';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import Once from '../../Layout/Wrappers/Once';
import {
  PLACEMENT__LEFT_START
} from '../../Layout/Wrappers/StyledTooltip';
import Title from '../../Layout/Wrappers/Title';
import Typography from '../../Layout/Wrappers/Typography';
import getEngagementFeedbackReasons from '../Libraries/getEngagementFeedbackReasons.fun';
import RejectionAdditionalInfo from './RejectionAdditionalInfo';

export default function RejectionDropdown(props) {

  let {
    setRef = self => null,
    rejectionReasons = [],
    engagement = {},
    EngagementCardController = {},
    onChange = () => null,
    disabled,
    className = '',
  } = props;

  engagement = Obj(engagement);
  let job = Obj(engagement.job);

  const [state, updateState] = useState();

  const isEndByEmployer = (engagement.status === STATUS_E_EMPLOYER);
  const isResumeStage = [
    STAGE_CONFIRMATION,
    STAGE_SUBMISSION,
    STAGE_REVIEW
  ].includes(engagement.stage);

  const _onOpenChange = (open) => async (event) => await updateState({ open });

  const _onReasonChange = (rejectionReason) => async (event) => {
    try {
      await updateState({ open: false, edited: false });
      engagement.rejectionReason = rejectionReason;
      const _reason = getJoinedFeedbackOrRejectionReason(engagement);
      const _note = Str(
        engagement.rejectionReasonAdditionalInfo
      ).replace(/\n/g, ' ').trim();
      if (isEndByEmployer) {
        await prependJobFeedbackLog({
          jobId: engagement.jobId,
          context: {
            ACTION: ACTION_TYPE__REJECT,
            SOURCE: ACTION_SOURCE__ENGAGEMENT,
            CANDIDATE__NAME: mapCandidateName(engagement.candidate),
            USER: Core.getUserName(),
            PRESETS: _reason,
            NOTE: _note
          },
          stage: engagement.stage
        });
      }
      if (
        EngagementCardController.state.__includeRejectionEmailAdditionalInfo === true
      ) {
        await prependLogEngagementFeedbackToRecruiter({
          engagementId: engagement.id,
          context: {
            ACTION: ACTION_TYPE__REJECT,
            ROLE: {
              [STATUS_E_10X10]: '10x10',
              [STATUS_E_EMPLOYER]: 'Employer',
              [STATUS_E_CANDIDATE]: 'Candidate',
              [STATUS_E_RECRUITER]: 'Recruiter'
            }[engagement.status],
            NOTE: _note
          }
        });
      }
      onChange();
    }
    catch (error) {
      Core.showError(error);
    }
  };

  const _onFeedbackReasonChange = (context) => async (event) => {
    const YES__EDITED__FEEDBACK = YES(state.edited);
    const currentReasonIds = getIdsLatestEngagementFeedbackReasons(engagement);
    const clickedReasonId = Definition.getId(
      DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON,
      context.REASON
    );
    if (YES__EDITED__FEEDBACK) {
      if (currentReasonIds.includes(clickedReasonId)) {
        engagement.feedback[0].reasons = currentReasonIds.filter(reason => reason !== clickedReasonId);
      }
      else {
        engagement.feedback[0].reasons = sanitizeArr([...currentReasonIds, clickedReasonId]);
      }
    }
    else {
      engagement.feedback = getNewPrependedEngagementFeedback({
        engagement,
        ids: (
          currentReasonIds.includes(clickedReasonId)
            ? currentReasonIds.filter(reason => reason !== clickedReasonId)
            : [...currentReasonIds, clickedReasonId]
        )
      })
    }
    engagement.rejectionReason = Str(
      Definition.getLabel(
        DEFINITION_CATEGORY__ENGAGEMENT_FEEDBACK_REASON,
        engagement.feedback[0].reasons[0]
      )
    );
    console.debug(
      '_onFeedbackReasonChange',
      context,
      state,
      engagement.feedback
    );
    await updateState({ edited: true });
  }

  const filterMapping = {
    '10x10': rejectionReason => /^10(x|by)10/i.test(rejectionReason),
    'Recruiter': rejectionReason => /^Recruiter/i.test(rejectionReason),
    'Candidate': rejectionReason => /^Cand/i.test(rejectionReason),
    'Employer': rejectionReason => /^Emp/i.test(rejectionReason),
    'Other': rejectionReason => !/^(10(x|by)10|Recruiter|Cand|Emp)/i.test(rejectionReason)
  };

  const groups = {
    '10x10': [],
    'Recruiter': [],
    'Candidate': [],
    'Employer': [],
    'Other': []
  };
  rejectionReasons.forEach(rejectionReason => {
    let header = Object.keys(filterMapping).find(key => filterMapping[key](rejectionReason));
    groups[header] = groups[header] || [];
    groups[header].push(rejectionReason);
  });

  // OVERWRITING EMPLOYER GROUP to use the tag management system instead Streak
  groups.Employer = Arr(state.engagementFeedbackReasons);

  setRef({
    open: async () => await updateState({ open: true }),
    close: async () => await updateState({ open: false })
  });

  const _sorter = (a, b) => (
    ENGAGEMENT__EMPLOYER_REJECTION_REASON_ORDER.indexOf(a) -
    ENGAGEMENT__EMPLOYER_REJECTION_REASON_ORDER.indexOf(b)
  );

  return (
    <>
      <Once
        promise={async () => {
          await updateState({
            engagementFeedbackReasons: await getEngagementFeedbackReasons({
              jobId: job.id
            })
          });
        }}
      />
      <Button outlined inherit small
        title={join([
          getJoinedFeedbackOrRejectionReason(engagement),
          engagement.rejectionReasonAdditionalInfo
        ], ' - ')}
        placement={PLACEMENT__LEFT_START}
        className={joinClassName([
          'f-sm tt-unset py-0 px-1 overflow-hidden nowrap',
          className
        ])}
        style={{ height: 24, width: REACT_TABLE__COLUMN_SM, maxWidth: '100%' }}
        disabled={disabled}
        onClick={_onOpenChange(true)}
      >
        {engagement.rejectionReason || 'Rejection Reason'}
      </Button>
      <Dialog
        title={
          <Box row noWrap
            className='mr-auto'
          >
            <Box row noWrap className='flex-align-left-bottom mr-auto truncate'>
              <Typography strong className='f-xl'>{engagement._name}</Typography>
              <Typography sub cyanDark className='flex-align-left-bottom'>
                &nbsp;{MDASH}&nbsp;
                {engagement.stage}
                &nbsp;{MDASH}&nbsp;
                {engagement.status}
                {engagement.rejectionReason && <>&nbsp;{MDASH}&nbsp;</>}
                <Typography sub error>{engagement.rejectionReason}</Typography>
              </Typography>
            </Box>
          </Box>
        }
        content={
          <>

            <RejectionAdditionalInfo
              EngagementCardController={EngagementCardController}
              autoFocus={false}
            />

            <Fieldset
              title='Select a rejection reason'
            >
              <div
                className='d-flex flex-wrap'
                style={{
                  maxHeight: 'calc(100vh - 12.5rem)',
                  maxWidth: 'calc(100vw - 4rem)'
                }}
              >
                {Object.keys(groups).filter((key) => groups[key].length).map((header, index) => {
                  return (
                    <Card
                      key={`rejection-reason-group-${header}-${index}`}
                      className='bg-white rounded m-1 mt-0 flex-1 flex-column'
                    >
                      <Title className='f-md px-1'>{header}</Title>
                      <Divider />
                      <Box column scrollY
                        style={{ minHeight: 250 }}
                      >
                        {groups[header].sort(_sorter).map(reason => {
                          const YES__EMPLOYER = YES(Str(header).match(/employer/i));
                          const YES__CURRENT_AND_ENGAGEMENTS_ARE_SAME = reason === engagement.rejectionReason;
                          const YES__CURRENT__REASON__SELECTED = (
                            (
                              YES__EMPLOYER &&
                              getLabelsLatestEngagementFeedbackReasons(engagement).includes(reason)
                            ) ||
                            YES__CURRENT_AND_ENGAGEMENTS_ARE_SAME
                          );
                          const NOT__ENGAGEMENT__REASON = NOT(engagement.rejectionReason);
                          const YES__HIGHLIGHT = (
                            YES__CURRENT__REASON__SELECTED
                          );
                          const LABEL__REASON = Str(reason).replace(/^(10x10|recruiter|candidate|employer) - /i, '');
                          return (
                            <Menu.Item
                              key={`rejection-reason-group-${header}-${index}-${reason}`}
                              className={YES__HIGHLIGHT ? `bg-yellow-light` : `hover-bg`}
                              onClick={
                                YES__EMPLOYER
                                  ? _onFeedbackReasonChange({
                                    NOT__ENGAGEMENT__REASON,
                                    REASON: reason,
                                  })
                                  : _onReasonChange(reason)
                              }
                            >
                              <Box row>
                                <Checkbox
                                  acl={YES__EMPLOYER}
                                  checked={YES__CURRENT__REASON__SELECTED}
                                />
                                {LABEL__REASON}
                              </Box>
                            </Menu.Item>
                          );
                        })}
                      </Box>
                    </Card>
                  );
                })}
              </div>
            </Fieldset>
            {isEndByEmployer && (
              <JobFeedbacks
                job={job}
                onChange={async (update) => new Promise((resolve) => {
                  Object.assign(engagement.job, update);
                  updateState(Date.now());
                  resolve();
                  let timeoutKey = [`timeout_${Object.keys(update)[0]}`];
                  clearTimeout(RejectionDropdown[timeoutKey]);
                  RejectionDropdown[timeoutKey] = setTimeout(async () => {
                    Object.keys(update).forEach(key => engagement.job[key] = trim(engagement.job[key]));
                    await Job.update(engagement.jobId, update).catch(Core.showError);
                    updateState(Date.now());
                  }, 3000);
                })}
                className='mt-3 w-100'
                defaultTab={
                  isResumeStage
                    ? JOB__RESUME_FEEDBACK__TAB
                    : JOB__INTERVIEW_FEEDBACK__TAB
                }
              />
            )}
          </>
        }
        actions={[
          <Button outlined minW120
            onClick={_onOpenChange(false)}
          >
            Cancel
          </Button>,
          <Button primary minW120
            onClick={_onReasonChange(engagement.rejectionReason)}
          >
            Next
          </Button>
        ]}
        paperStyle={{
          width: 1600,
          maxWidth: 'calc(100vw - 2rem)'
        }}
        open={state.open}
      />
    </>
  );
}
