import {
  Collapse
} from '@mui/material';
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  mapWrapper
} from '../Libraries/Theme.lib';
import Box from './Box';
import Card from './Card';
import Divider from './Divider';
import Icon from './Icon';
import List from './List';
import {
  PLACEMENT__LEFT_END
} from './StyledTooltip';
import Typography from './Typography';

export default function ExpandableCard({
  acl = true,
  adminOnly = false,
  unmountOnExit = true,
  children = MDASH,
  cardTitle = MDASH,
  cardTitleOnOpen = undefined,
  cardContent = children,
  wrapperProps = {},
  info = undefined,
  ...props
}) {
  const [state, updateState] = useState();
  if (!acl || (adminOnly && !Core.isAdmin())) { return null; }
  if (adminOnly) {
    info = Obj(info);
    info.icon = 'lock';
    info.title = info.title || 'Admin only';
  }
  const {
    icon = 'info',
    placement = PLACEMENT__LEFT_END,
    ..._info
  } = Obj(info);
  const _toggleCollapse = (key) => async (event) => {
    await updateState({ [key]: !state[key] });
  };
  return (
    <Card
      {...mapWrapper({
        role: 'ExpandableCard',
        props: wrapperProps,
        assign: props
      })
      }
    >
      <List className='m-0 p-0'>
        <List.Item button onClick={_toggleCollapse('open')}>
          <Icon
            icon='toc'
            className='mr-1'
          />
          <List.Item.Text>
            <Box row>
              {info &&
                <Icon
                  icon={icon}
                  className='mr-05 icon16 c-black-dark'
                  placement={placement}
                  {..._info}
                />}
              <Typography className='f-md fw-500 c-black-darker'>
                {cardTitleOnOpen && state.open ? cardTitleOnOpen : cardTitle}
              </Typography>
            </Box>
          </List.Item.Text>
          <Icon
            icon={!!state.open ? 'expand_less' : 'expand_more'}
            className='ml-1'
          />
        </List.Item>
        <Collapse
          in={!!state.open}
          timeout="auto"
          unmountOnExit={unmountOnExit}
        >
          <Divider />
          <Box flex1 className='p-2'>
            {cardContent}
          </Box>
        </Collapse>
      </List>
    </Card>
  )
}
