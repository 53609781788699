import {
  Input
} from 'antd';
import {
  isUndefined
} from 'lodash';
import {
  useState
} from 'react';
import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import {
  ENGAGEMENT__STATE_OPEN,
  STAGE_ONSITE,
  STAGE_SCREEN,
  STATUS_W_EMPLOYER_FEEDBACK
} from '../../../dictionaries/Engagement.dic';
import {
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  getFulldayDateISOString
} from '../../../lib/Date.lib';
import Engagement from '../../../lib/Engagement';
import {
  Str
} from '../../../lib/String.lib';
import { getLocation, setLocation } from '../../../lib/URL.lib';
import {
  setURLQueryString
} from '../../../lib/URLQuery.lib';
import getEngagementFeedbackReasons from '../../Engagements/Libraries/getEngagementFeedbackReasons.fun';
import Button from '../../Layout/Wrappers/Button';
import Chip from '../../Layout/Wrappers/Chip';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Once from '../../Layout/Wrappers/Once';
import {
  LoadingImage
} from '../../Shared/Loader';
import {
  EmployerPendings_updateState
} from './EmployerPendings';
import {
  ACTION_TYPE__ACCEPT,
  ACTION_TYPE__ACCEPT_STRONG,
  ACTION_TYPE__ACCEPT_WEAK,
  ACTION_TYPE__FEEDBACK_TO_10X10,
  ACTION_TYPE__FEEDBACK_TO_RECRUITER,
  ACTION_TYPE__NO_ACTION,
  ACTION_TYPE__REJECT,
  ACTION_TYPE__REJECT_STRONG,
  ACTION_TYPE__REJECT_WEAK,
  EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK,
  EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_REVIEW,
} from './Libraries/EmployerPendings.dic';
import {
  sendFeedback
} from './Libraries/EmployerPendings.lib';
import {
  getAcceptAdditionalPresets,
  getAcceptPresets
} from './Libraries/NextSteps.lib';

const { TextArea } = Input;

export function FeedbackInput(props) {
  let { actionType } = props.state;
  let context = { ...props, actionType };
  const YES__ACTION_ACCEPT = [
    ACTION_TYPE__ACCEPT,
    ACTION_TYPE__ACCEPT_WEAK,
    ACTION_TYPE__ACCEPT_STRONG
  ].includes(actionType)
  const YES__ACTION_REJECT = [
    ACTION_TYPE__REJECT,
    ACTION_TYPE__REJECT_WEAK,
    ACTION_TYPE__REJECT_STRONG
  ].includes(actionType);
  const YES__ACTION_IS_FEEDBACK_TO_10X10 = actionType === ACTION_TYPE__FEEDBACK_TO_10X10;
  const YES__ACTION_IS_FEEDBACK_TO_RECRUITER = actionType === ACTION_TYPE__FEEDBACK_TO_RECRUITER;
  const YES__ACTION_IS_FEEDBACK = (YES__ACTION_IS_FEEDBACK_TO_10X10 || YES__ACTION_IS_FEEDBACK_TO_RECRUITER);
  if (YES__ACTION_ACCEPT) {
    return <FeedbackInputAccept {...context} />;
  }
  if (YES__ACTION_REJECT) {
    return <FeedbackInputReject {...context} />;
  }
  if (YES__ACTION_IS_FEEDBACK) {
    return <FeedbackOnlyPanel {...context} />;
  }
  else {
    return <FeedbackMainPanel {...context} />;
  }
}

export function FeedbackMainPanel(props) {
  let { selected = {} } = props;
  const actionType = selected.thread?.actionType;
  const YES__ACTION_ACCEPT = [
    ACTION_TYPE__ACCEPT,
    ACTION_TYPE__ACCEPT_WEAK,
    ACTION_TYPE__ACCEPT_STRONG
  ].includes(actionType)
  const YES__ACTION_REJECT = [
    ACTION_TYPE__REJECT,
    ACTION_TYPE__REJECT_WEAK,
    ACTION_TYPE__REJECT_STRONG
  ].includes(actionType);
  const YES__ACTION_IS_FEEDBACK_TO_10X10 = actionType === ACTION_TYPE__FEEDBACK_TO_10X10;
  const YES__ACTION_IS_FEEDBACK_TO_RECRUITER = actionType === ACTION_TYPE__FEEDBACK_TO_RECRUITER;
  const YES__ACTION_IS_FEEDBACK = (YES__ACTION_IS_FEEDBACK_TO_10X10 || YES__ACTION_IS_FEEDBACK_TO_RECRUITER);
  const YES__ACTION_TYPE = !!actionType;
  const NOT__ACTION_TYPE = !YES__ACTION_TYPE;
  const NOT__CALIBRATION = (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK);
  return (
    <div className='ml-7' style={{ maxWidth: 576 }}>
      {(!!Str(selected.engagementKey).match(
        new RegExp(
          join([
            'feedback',
            EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_REVIEW
          ], '|'), 'i')
      )
        ? (
          <div>
            <Button
              color={
                YES__ACTION_REJECT
                  ? 'inherit'
                  : NOT__ACTION_TYPE
                    ? 'error'
                    : 'gray'
              }
              variant={
                YES__ACTION_REJECT
                  ? 'contained'
                  : 'outlined'
              }
              endIcon={<i className='material-icons c-inherit'>thumb_down</i>}
              className='flex-1 nowrap m-05 tt-unset min-w-120'
              size='small'
              onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__REJECT })}
            >
              Reject
            </Button>
            <Button
              color={
                YES__ACTION_ACCEPT
                  ? 'inherit'
                  : NOT__ACTION_TYPE
                    ? 'secondary'
                    : 'gray'
              }
              variant={
                (NOT__ACTION_TYPE || YES__ACTION_ACCEPT)
                  ? 'contained'
                  : 'outlined'
              }
              endIcon={<i className='material-icons c-inherit'>thumb_up</i>}
              className='flex-1 min-w-120 nowrap m-05 tt-unset min-w-120'
              size='small'
              onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__ACCEPT })}
            >
              {NOT__CALIBRATION
                ? EmployerPendingsUI.middlePanelActionYesInterview
                : EmployerPendingsUI.middlePanelActionYes}
            </Button>
            <Button
              color={
                (YES__ACTION_IS_FEEDBACK)
                  ? 'inherit'
                  : NOT__ACTION_TYPE
                    ? 'inherit'
                    : 'gray'
              }
              variant={
                (YES__ACTION_IS_FEEDBACK)
                  ? 'contained'
                  : 'outlined'
              }
              className='flex-1 min-w-120 nowrap m-05 tt-unset min-w-120'
              size='small'
              onClick={event => EmployerPendings_updateState({
                actionType: ACTION_TYPE__FEEDBACK_TO_10X10
              })}
            >
              Other
            </Button>
          </div>
        ) : (
          <FeedbackOnly {...props} />
        )
      )}
      <FeedbackInputFooter selected={selected} context={props} main />
    </div>
  );
}

export function FeedbackOnlyPanel(props) {
  return (
    <div className='ml-7 outlined rounded px-1 pb-1' style={{ maxWidth: 576 }}>
      <div className='d-flex flex-align-left-center'>
        <div className='f-md fw-600 c-cyan-darker'>
          Inform 10 by 10
        </div>
        <IconButton onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__NO_ACTION })} className='ml-auto'>
          <i className='material-icons'>highlight_off</i>
        </IconButton>
      </div>
      <FeedbackOnly {...props} />
    </div>
  )
}

export function FeedbackOnly(props) {
  let {
    textAreaRef,
    selected,
    feedback,
    feedbacks,
    includeInterviewHappenAction = false
  } = props;
  let [value, setValue] = useState();
  value = Str(!isUndefined(value) ? value : feedback.text);
  const actionType = selected.thread?.actionType;
  const YES__ENGAGEMENT_IS_OPEN = (selected.state === ENGAGEMENT__STATE_OPEN);
  const YES__ACTION_IS_FEEDBACK_TO_10X10 = actionType === ACTION_TYPE__FEEDBACK_TO_10X10;
  const YES__ACTION_IS_FEEDBACK_TO_RECRUITER = actionType === ACTION_TYPE__FEEDBACK_TO_RECRUITER;
  const YES__ACTION_IS_FEEDBACK = (YES__ACTION_IS_FEEDBACK_TO_10X10 || YES__ACTION_IS_FEEDBACK_TO_RECRUITER);
  const YES__LOADING = !!feedback.loading;
  const YES__THREAD = !!selected.thread;
  const NOT__THREAD = !YES__THREAD;
  const NOT__CALIBRATION = (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK);
  return (
    <>
      <TextArea
        id='feedbackInput'
        ref={textAreaRef}
        placeholder={EmployerPendingsUI.middlePanelInputPlaceholder}
        value={value}
        onChange={(event) => {
          if (value !== event.target.value) {
            delete selected.thread;
          }
          setValue(event.target.value);
        }}
        onBlur={(event) => {
          if (selected.id) {
            feedbacks[selected.id] = {
              ...(feedbacks[selected.id] || {}),
              text: value,
              pendingsCounter: selected.pendingsCounter
            };
            EmployerPendings_updateState({ feedbacks });
          }
        }}
        autoSize={{ minRows: 1, maxRows: 12 }}
        autoFocus
        className='mt-05'
      />
      <div className='d-flex flex-wrap flex-align-right-center mt-05'>
        {includeInterviewHappenAction && NOT__CALIBRATION && YES__ENGAGEMENT_IS_OPEN && (
          <Button
            color={YES__ACTION_IS_FEEDBACK ? 'inherit' : !!selected.thread ? 'gray' : 'secondary'}
            variant={'outlined'}
            className='flex-1 nowrap m-05 tt-unset min-w-120'
            size='small'
            onClick={async (event) => {
              try {
                const update = {};
                const date = getFulldayDateISOString();
                if (selected.stage === STAGE_SCREEN) {
                  update.screen3 = date;
                }
                else if (selected.stage === STAGE_ONSITE) {
                  update.onsite2 = date;
                }
                update.status = STATUS_W_EMPLOYER_FEEDBACK;
                await Engagement.update(selected, update);
                Object.assign(selected, update);
                setLocation(
                  setURLQueryString({
                    url: getLocation(),
                    update: { engagementId: selected.id }
                  })
                );
                await EmployerPendings_updateState({
                  selected,
                  actionType: ACTION_TYPE__NO_ACTION
                });
              }
              catch (error) {
                Core.showError(error);
              }
            }}
            disabled={YES__LOADING}
          >
            Interview already happened?
          </Button>
        )}

        {/** NOTIFY TO 10 BY 10 */}
        <Button
          color={YES__ACTION_IS_FEEDBACK_TO_10X10 ? 'inherit' : YES__THREAD ? 'gray' : 'secondary'}
          variant={NOT__THREAD || YES__ACTION_IS_FEEDBACK_TO_10X10 ? 'contained' : 'outlined'}
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({
            ...props,
            actionType: ACTION_TYPE__FEEDBACK_TO_10X10
          })}
          endIcon={YES__LOADING ? LoadingImage : NOT__THREAD && <i className='material-icons c-inherit'>send</i>}
          disabled={YES__LOADING || YES__ACTION_IS_FEEDBACK_TO_10X10}
        >
          {YES__THREAD
            ? EmployerPendingsUI.middlePanelInputActionFeedbackTo10x10Only.actionCompleted
            : EmployerPendingsUI.middlePanelInputActionFeedbackTo10x10Only.actionLabel
          }
        </Button>

        {/** NOTIFY TO RECRUITER */}
        <Button
          acl={Core.isAdmin()}
          color={YES__ACTION_IS_FEEDBACK_TO_RECRUITER ? 'inherit' : YES__THREAD ? 'gray' : 'secondary'}
          variant={NOT__THREAD || YES__ACTION_IS_FEEDBACK_TO_RECRUITER ? 'contained' : 'outlined'}
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({
            ...props,
            actionType: ACTION_TYPE__FEEDBACK_TO_RECRUITER
          })}
          endIcon={YES__LOADING ? LoadingImage : NOT__THREAD && <i className='material-icons c-inherit'>send</i>}
          disabled={YES__LOADING || YES__ACTION_IS_FEEDBACK_TO_RECRUITER}
        >
          {YES__THREAD
            ? EmployerPendingsUI.middlePanelInputActionFeedbackToRecruiterOnly.actionCompleted
            : EmployerPendingsUI.middlePanelInputActionFeedbackToRecruiterOnly.actionLabel
          }
        </Button>
      </div>
    </>
  );
}

export function FeedbackInputAccept(props) {
  let { ref: textAreaRef, selected, feedback, feedbacks, state } = props;
  let { actionType = ACTION_TYPE__NO_ACTION } = state;
  let { loading } = feedback;
  let [value, setValue] = useState();
  value = Str(!isUndefined(value) ? value : feedback.text);
  let loadingAction = (loading && actionType);
  let activePresets = (feedback.presets || []);
  let presets = getAcceptPresets({ activePresets });
  let additionalPresets = getAcceptAdditionalPresets({ preset: activePresets[0] });
  let isStrong = selected.thread?.actionType === ACTION_TYPE__ACCEPT_STRONG;
  let isWeak = selected.thread?.actionType === ACTION_TYPE__ACCEPT_WEAK;
  let isAccepted = selected.thread?.actionType === ACTION_TYPE__ACCEPT;
  const isNotCalibration = (!selected.engagementKey || (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK));
  const YES__ACTION_ACCEPT = [
    ACTION_TYPE__ACCEPT,
    ACTION_TYPE__ACCEPT_WEAK,
    ACTION_TYPE__ACCEPT_STRONG
  ].includes(selected.thread?.actionType)
  const YES__ACTION_REJECT = [
    ACTION_TYPE__REJECT,
    ACTION_TYPE__REJECT_WEAK,
    ACTION_TYPE__REJECT_STRONG
  ].includes(selected.thread?.actionType);
  const YES__ACTION_ACCEPT_OR_REJECT = YES__ACTION_ACCEPT || YES__ACTION_REJECT;
  const YES__ACTION_TYPE__REJECT = (selected.thread?.actionType === ACTION_TYPE__REJECT)
  const NOT__ACTION_ACCEPT_OR_REJECT = !YES__ACTION_ACCEPT_OR_REJECT;
  const NOT__THREAD = !selected.thread;


  return (
    <div className='ml-7 outlined rounded px-1 pb-1' style={{ maxWidth: 576 }}>
      <Fieldset
        wrapperProps={{ className: 'p-0' }} fullWidth
        title={
          <span className='f-md fw-600 c-cyan-darker'>
            {EmployerPendingsUI.middlePanelCardTitleAcceptance}
          </span>
        }
        actionBar={
          <IconButton onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__NO_ACTION })} className='ml-auto'>
            <i className='material-icons'>highlight_off</i>
          </IconButton>
        }
      >
        {isNotCalibration && (
          <>
            <div>
              {presets.map(
                (line, row) => (
                  <div key={`accept-feedback-preset-row-${row}`} className='mb-1'>
                    {line.map((preset, col) => {
                      feedbacks[selected.id] = (feedbacks[selected.id] || {});
                      feedbacks[selected.id].presets = (feedbacks[selected.id].presets || []);
                      feedbacks[selected.id].additionalPresets = (feedbacks[selected.id].additionalPresets || []);
                      let checked = feedbacks[selected.id].presets.includes(preset);
                      function _toggle(event) {
                        if (checked) {
                          feedbacks[selected.id].presets = [];
                          feedbacks[selected.id].additionalPresets = [];
                        }
                        else {
                          feedbacks[selected.id].presets = [preset];
                        }
                        /** @todo [ 2022-10-26 ][ MS: to review logic with AC ] */
                        // _updateBoxText({ feedbacks });
                        EmployerPendings_updateState({ feedbacks });
                      }
                      return (
                        <Chip
                          key={`accept-feedback-preset-row-${row}-col-${col}`}
                          label={preset}
                          size='small'
                          className={`mr-1 mb-05 c-black-medium ${checked ? 'bg-green-lighter' : 'bg-white dashed-darker'}`}
                          onClick={_toggle}
                          onDelete={checked ? _toggle : undefined}
                        />
                      );
                    })}
                  </div>
                )
              )}
            </div>
            {!!additionalPresets.length && (
              <Fieldset
                wrapperProps={{ className: 'p-0' }} fullWidth
                title={
                  <div className='f-sm fw-600 c-cyan-darker'>
                    {EmployerPendingsUI.middlePanelCardHeaderOneAcceptance}
                  </div>
                }
              >
                {additionalPresets.map((additionalPreset, index) => {
                  feedbacks[selected.id] = (feedbacks[selected.id] || {});
                  feedbacks[selected.id].additionalPresets = (feedbacks[selected.id].additionalPresets || []);
                  let checked = feedbacks[selected.id].additionalPresets.includes(additionalPreset);
                  function _toggle(event) {
                    if (checked) {
                      feedbacks[selected.id].additionalPresets = [];
                    }
                    else {
                      feedbacks[selected.id].additionalPresets = [additionalPreset];
                    }
                    /** @todo [ 2022-10-26 ][ MS: to review logic with AC ] */
                    // _updateBoxText({ feedbacks });
                    EmployerPendings_updateState({ feedbacks });
                  }
                  return (
                    <Chip
                      key={`accept-feedback-additional-preset-${index}`}
                      label={additionalPreset}
                      size='small'
                      className={`mr-1 mb-05 c-black-medium ${checked ? 'bg-green-lighter' : 'bg-white dashed-darker'}`}
                      onClick={_toggle}
                      onDelete={checked ? _toggle : undefined}
                    />
                  );
                })}
              </Fieldset>
            )}
          </>
        )}
      </Fieldset>
      <Fieldset
        wrapperProps={{ className: 'p-0' }} fullWidth
        title={
          <div className='f-sm fw-600 c-cyan-darker'>
            {EmployerPendingsUI.middlePanelCardHeaderTwoAcceptance}
          </div>
        }
      >
        <TextArea
          id='feedbackInput'
          ref={textAreaRef}
          placeholder={EmployerPendingsUI.middlePanelInputPlaceholderYes}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onBlur={(event) => {
            if (selected.id) {
              feedbacks[selected.id] = {
                ...(feedbacks[selected.id] || {}),
                text: value,
                pendingsCounter: selected.pendingsCounter
              };
              EmployerPendings_updateState({ feedbacks });
            }
          }}
          autoSize={{ minRows: 1, maxRows: 12 }}
          autoFocus
        />
      </Fieldset>
      <div className='d-flex flex-wrap flex-align-right-center mt-05'>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__ACCEPT_WEAK
            ? 'inherit'
            : (
              NOT__THREAD ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'secondary'
              : 'gray'
          }
          variant={!loading && selected.thread?.actionType === ACTION_TYPE__ACCEPT_WEAK ? 'contained' : 'outlined'}
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__ACCEPT_WEAK })}
          endIcon={(loadingAction === ACTION_TYPE__ACCEPT_WEAK)
            ? LoadingImage
            : isWeak
              ? <i className='material-icons c-inherit'>thumb_up</i>
              : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__ACCEPT_WEAK}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__ACCEPT
            ? 'inherit'
            : (
              NOT__THREAD ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'secondary'
              : 'gray'
          }
          variant={
            !loading && (
              NOT__THREAD ||
              YES__ACTION_TYPE__REJECT ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'contained'
              : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__ACCEPT })}
          endIcon={
            (loadingAction === ACTION_TYPE__ACCEPT)
              ? LoadingImage
              : isAccepted
                ? <i className='material-icons c-inherit'>thumb_up</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__ACCEPT}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__ACCEPT_STRONG
            ? 'inherit'
            : (
              NOT__THREAD ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'secondary'
              : 'gray'
          }
          variant={
            (!loading && selected.thread?.actionType === ACTION_TYPE__ACCEPT_STRONG)
              ? 'contained'
              : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__ACCEPT_STRONG })}
          endIcon={
            (loadingAction === ACTION_TYPE__ACCEPT_STRONG)
              ? LoadingImage
              : isStrong
                ? <i className='material-icons c-inherit'>thumb_up</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__ACCEPT_STRONG}
        </Button>
      </div>
      <FeedbackInputFooter selected={selected} context={props} />
    </div >
  );
}

export function FeedbackInputReject(props) {
  let { ref: textAreaRef, selected, feedback, feedbacks, state } = props;
  let { actionType = ACTION_TYPE__NO_ACTION } = state;
  let { loading } = feedback;
  let [value, setValue] = useState();
  const [presets, setPresets] = useState([[]]);
  value = Str(!isUndefined(value) ? value : feedback.text);
  let loadingAction = (loading && actionType);
  feedbacks[selected.id] = (feedbacks[selected.id] || {});
  feedbacks[selected.id].presets = (feedbacks[selected.id].presets || []);
  let isStrong = selected.thread?.actionType === ACTION_TYPE__REJECT_STRONG;
  let isWeak = selected.thread?.actionType === ACTION_TYPE__REJECT_WEAK;
  let isRejected = selected.thread?.actionType === ACTION_TYPE__REJECT;
  const isNotCalibration = (!selected.engagementKey || (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK));
  const YES__ACTION_ACCEPT = [
    ACTION_TYPE__ACCEPT,
    ACTION_TYPE__ACCEPT_WEAK,
    ACTION_TYPE__ACCEPT_STRONG
  ].includes(selected.thread?.actionType)
  const YES__ACTION_REJECT = [
    ACTION_TYPE__REJECT,
    ACTION_TYPE__REJECT_WEAK,
    ACTION_TYPE__REJECT_STRONG
  ].includes(selected.thread?.actionType);
  const YES__ACTION_ACCEPT_OR_REJECT = YES__ACTION_ACCEPT || YES__ACTION_REJECT;
  const YES__ACTION_TYPE__REJECT = (selected.thread?.actionType === ACTION_TYPE__REJECT)
  const NOT__ACTION_ACCEPT_OR_REJECT = !YES__ACTION_ACCEPT_OR_REJECT;
  const NOT__THREAD = !selected.thread;
  return (
    <div className='ml-7 outlined rounded px-1 pb-1' style={{ maxWidth: 576 }}>
      <Once
        promise={async () => {
          setPresets(
            [
              await getEngagementFeedbackReasons({
                jobId: selected.jobId
              })
            ]
          );
        }}
      />
      <div className='d-flex flex-align-left-center'>
        <div className='f-md fw-600 c-cyan-darker'>
          {isNotCalibration ? 'Select all the reasons' : 'Rejection'}
        </div>
        <IconButton onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__NO_ACTION })} className='ml-auto'>
          <i className='material-icons'>highlight_off</i>
        </IconButton>
      </div>
      <div>
        {presets.map(
          (line, row) => (
            <div key={`accept-feedback-preset-row-${row}`} className='d-flex flex-align-left-center wrap my-1'>
              {line.map((value, col) => {
                let checked = feedbacks[selected.id].presets.includes(value);
                function _toggle(event) {
                  if (checked) {
                    feedbacks[selected.id].presets = feedbacks[selected.id].presets.filter(v => v !== value);
                  }
                  else {
                    feedbacks[selected.id].presets.push(value);
                  }
                  EmployerPendings_updateState({ feedbacks });
                }
                return (
                  <Chip
                    key={`accept-feedback-preset-row-${row}-col-${col}`}
                    label={value}
                    size='small'
                    className={`mr-1 mb-05 c-black-medium ${checked ? 'bg-red-lighter' : 'bg-white dashed-darker'}`}
                    onClick={_toggle}
                    onDelete={checked ? _toggle : undefined}
                  />
                );
              })}
            </div>
          )
        )}
      </div>
      <TextArea
        id='feedbackInput'
        ref={textAreaRef}
        placeholder={EmployerPendingsUI.middlePanelInputPlaceholderNo}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={(event) => {
          if (selected.id) {
            feedbacks[selected.id] = {
              ...(feedbacks[selected.id] || {}),
              text: value,
              pendingsCounter: selected.pendingsCounter
            };
            EmployerPendings_updateState({ feedbacks });
          }
        }}
        autoSize={{ minRows: 1, maxRows: 12 }}
        autoFocus
        className='mt-1'
      />
      <div className='d-flex flex-wrap flex-align-right-center mt-05'>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__REJECT_STRONG
            ? 'inherit'
            : (
              NOT__THREAD ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'error'
              : 'gray'
          }
          variant={!loading && selected.thread?.actionType === ACTION_TYPE__REJECT_STRONG ? 'contained' : 'outlined'}
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__REJECT_STRONG })}
          endIcon={
            (loadingAction === ACTION_TYPE__REJECT_STRONG)
              ? LoadingImage
              : isStrong
                ? <i className='material-icons c-inherit'>thumb_down</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__REJECT_STRONG}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__REJECT
            ? 'inherit'
            : (
              NOT__THREAD ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'error'
              : 'gray'
          }
          variant={
            !loading && (
              NOT__THREAD ||
              YES__ACTION_TYPE__REJECT ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'contained'
              : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__REJECT })}
          endIcon={
            (loadingAction === ACTION_TYPE__REJECT)
              ? LoadingImage
              : isRejected
                ? <i className='material-icons c-inherit'>thumb_down</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__REJECT}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__REJECT_WEAK
            ? 'inherit'
            : (
              NOT__THREAD ||
              NOT__ACTION_ACCEPT_OR_REJECT
            )
              ? 'error'
              : 'gray'
          }
          variant={!loading && (selected.thread?.actionType === ACTION_TYPE__REJECT_WEAK)
            ? 'contained'
            : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__REJECT_WEAK })}
          endIcon={
            (loadingAction === ACTION_TYPE__REJECT_WEAK)
              ? LoadingImage
              : isWeak
                ? <i className='material-icons c-inherit'>thumb_down</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__REJECT_WEAK}
        </Button>
      </div>
      <FeedbackInputFooter selected={selected} context={props} />
    </div >
  );
}

export function FeedbackInputFooter({ selected, context, main }) {
  const actionType = selected.thread?.actionType;
  const contextActionType = context.actionType;
  const YES__ACTION_ACCEPT = [
    ACTION_TYPE__ACCEPT,
    ACTION_TYPE__ACCEPT_WEAK,
    ACTION_TYPE__ACCEPT_STRONG
  ].includes(actionType);
  const YES__ACTION_REJECT = [
    ACTION_TYPE__REJECT,
    ACTION_TYPE__REJECT_WEAK,
    ACTION_TYPE__REJECT_STRONG
  ].includes(actionType);
  const YES__SELECTED_ACTION_IS_DIFF_FROM_CONTEXT = (actionType !== contextActionType);
  const YES__ACTION_IS_ACCEPT_OR_REJECT = (YES__ACTION_ACCEPT || YES__ACTION_REJECT);
  if (main) {
    if (actionType) {
      return (
        <div className='px-1 c-red f-sm mt-05'>
          Prior feedback: {actionType}
        </div>
      );
    }
  }
  else if (
    actionType &&
    YES__SELECTED_ACTION_IS_DIFF_FROM_CONTEXT &&
    YES__ACTION_IS_ACCEPT_OR_REJECT
  ) {
    return (
      <div className='px-1 c-red f-sm mt-05'>
        Prior feedback: {selected.thread.actionType}
      </div>
    );
  }
  return null;
}
