import {
  debounce
} from 'lodash';
import ReactDOMServer from "react-dom/server";
import {
  Arr
} from '../../../lib/Array.lib';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Definition, {
  VISA_CANDIDATE__VISA_STATUS_UNKNOWN
} from '../../../lib/Definition';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import Box from '../../Layout/Wrappers/Box';
import {
  mapQuestionsAnswers
} from '../../PrescreenQuestions/PrescreenQuestions.lib';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import sendEmailDifferentPlatformRating from '../PlatformRating/sendEmailDifferentPlatformRating.fun';
import WarningDifferentPlatformRating from '../PlatformRating/WarningDifferentPlatformRating';
import {
  CandidateEditController
} from './CandidateEdit';

/**
 * If the candidate has some warnings, it will show a confirmation dialog
 * with the warnings.
 *
 * @param {function} onAccept - The function to call when the user accepts the warnings.
 * @param {boolean} exportWarnings - If true, it will return the html string with the warnings by onAccept param.
 *                                    If false, it will open a confirmation dialog.
 *
 * @returns {void}
 */
export function confirmCandidateUpdateAction({
  onAccept = (optionalHtmlString = '') => null,
  exportWarnings = false
}) {
  const candidate = CandidateEditController().state;
  const calculatedPR = Candidate.calculatePlatformRating({ candidate });
  const recommendedPlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: calculatedPR
  });
  const candidatePlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: candidate.platformRating
  });
  candidatePlatformRatingTag.label = (
    candidatePlatformRatingTag.label ||
    'unset'
  );
  Definition.map(candidate, 'positiveSignals');
  Definition.map(candidate, 'negativeSignals');
  const LIST__MISSING_QUESTION_ANSWERS = getMissingQuestionAnswersInData({ candidate });
  const LIST__INVALID_INPUT_VALUES = getCandidateInvalidRows({ candidate });
  const YES__CANDIDATE_WARNINGS = !!(
    !!LIST__INVALID_INPUT_VALUES.length ||
    (
      Core.isAdmin() &&
      (
        +candidate.platformRating
        !==
        +recommendedPlatformRatingTag.id
      )
    ) ||
    (
      Core.isRecruiter() &&
      !(candidate.jobsPermitted || []).length
    ) ||
    !!Arr(LIST__MISSING_QUESTION_ANSWERS).length
  );
  if (YES__CANDIDATE_WARNINGS) {
    const _onAccept = async () => {
      onAccept();
      debounce(sendEmailDifferentPlatformRating, 111)();
    }
    const COMPONENT__WARNINGS = (
      <CandidateUpdateWarnings
        candidate={candidate}
        recommendedPlatformRatingTag={recommendedPlatformRatingTag}
        candidatePlatformRatingTag={candidatePlatformRatingTag}
        missingQuestionsAnswersInData={LIST__MISSING_QUESTION_ANSWERS}
        rows={LIST__INVALID_INPUT_VALUES}
      />
    );
    const YES__EXPORT_WARNINGS = exportWarnings === true;
    if (YES__EXPORT_WARNINGS) {
      const exportedWarnings = ReactDOMServer.renderToString(COMPONENT__WARNINGS);
      return _onAccept(exportedWarnings);
    }
    showConfirm({
      title: 'Confirm',
      content: (COMPONENT__WARNINGS),
      actionOptions: [
        {
          label: 'NO - TAKE ME BACK',
          onClick: () => null, // do nothing
          buttonProps: { style: { minWidth: 180 } }
        },
        {
          label: 'YES - Submit',
          variant: 'contained',
          color: 'primary',
          onClick: _onAccept,
          buttonProps: { style: { minWidth: 180 } }
        }
      ],
      paperStyle: { minWidth: 640 }
    });
  }
  else {
    onAccept();
  }
}

export function CandidateUpdateWarnings(props) {
  let {
    candidate,
    recommendedPlatformRatingTag,
    candidatePlatformRatingTag,
    missingQuestionsAnswersInData,
    rows
  } = props;
  console.debug('HERE_27', candidate, recommendedPlatformRatingTag, candidatePlatformRatingTag)
  return (
    <Box column w100>

      {Core.isAdmin() && (
        candidate.platformRating !== recommendedPlatformRatingTag.id && (
          <WarningDifferentPlatformRating
            candidate={candidate}
            recommendedPlatformRatingTag={recommendedPlatformRatingTag}
            candidatePlatformRatingTag={candidatePlatformRatingTag}
          />
        )
      )}

      {Core.isRecruiter() && (
        !(candidate.jobsPermitted || []).length && (
          <div className='c-red mt-2'>
            You will not have representation for this candidate until the candidate grants permission to be submitted and you add jobs to the “{CANDIDATE_UI.jobsPermitted.title}” section
          </div>
        )
      )}

      {!!rows.length && (
        <div className='align-left'>
          <div className='mt-2'>
            <strong className='c-red underline'>Key Information Missing</strong>&nbsp;
            <div className='f-md'>
              Processing will be delayed due to missing information.
            </div>
          </div>
          <ul className='mt-1'>
            {rows.map(row => {
              return (
                <li className='c-black-medium f-sm' key={`edit-candidate-missing-info-${row.text}`}>
                  <strong className='c-red f-md'>{row.text}</strong>
                  {row.subtext ? ` ${row.subtext} ` : ` `}
                  <span>(page {row.page})</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <MissingQuestionAnswers missingQuestionsAnswersInData={missingQuestionsAnswersInData} />
    </Box>
  );
}

export function getCandidateInvalidRows({ candidate }) {
  return [
    {
      invalid: !candidate.resumes.length,
      text: 'Resume',
      page: 1
    },
    {
      invalid: !candidate.linkedInURL.length,
      text: 'LinkedIn URL',
      page: 1
    },
    {
      invalid: candidate.phone.length <= 4,
      text: 'Phone',
      page: 1
    },
    {
      invalid: !!candidate.roles && !candidate.roles.length,
      text: 'Roles',
      subtext: 'selection',
      page: 3
    },
    {
      invalid: !!candidate.jobsPermitted && !candidate.jobsPermitted.length,
      text: 'Jobs your candidate approved for Submission',
      subtext: 'selection',
      page: 3
    },
    {
      invalid: candidate.visa === VISA_CANDIDATE__VISA_STATUS_UNKNOWN,
      text: 'Candidate Visa',
      subtext: 'status',
      page: 2
    },
    {
      invalid: (
        !candidate.inOfficeRemoteFlags.length ||
        candidate.inOfficeRemoteFlags.includes(0)
      ),
      text: 'Desirable Work Region',
      subtext: 'preference',
      page: 2
    },
    {
      invalid: !candidate.candidateLocations.length,
      text: 'Home location',
      subtext: '',
      page: 2
    },
    {
      invalid: !candidate.inOfficeRemoteFlags.length,
      text: 'Desired Location Options',
      subtext: '',
      page: 2
    },
    {
      invalid: (!candidate.minimumSalary),
      text: 'Minimum Salary',
      subtext: 'requirement',
      page: 2
    },
    {
      invalid: (
        candidate.yearsOfExperience === -1 ||
        candidate.yearsOfExperience === ''
      ),
      text: 'Years of Relevant Work Experience',
      subtext: 'selection',
      page: 1
    },
    {
      invalid: (!!candidate.technicalSkills && !candidate.technicalSkills.length),
      text: 'Technical Skills',
      subtext: 'selection',
      page: 2
    }
  ].filter(row => !!row && !!row.invalid);
}

export function MissingQuestionAnswers({ missingQuestionsAnswersInData }) {
  missingQuestionsAnswersInData = Arr(missingQuestionsAnswersInData);
  let prev = '';
  return !!Arr(missingQuestionsAnswersInData).length && (
    <div className='d-flex flex-align-left-top mt-2 px-1'>
      <div>
        <div>
          <strong className='c-red underline'>
            Submission Blockers:
          </strong>&nbsp;
          Missing information for selected jobs
        </div>
        <ul>
          {missingQuestionsAnswersInData.map(question => {
            let title = '';
            if (prev !== question.title) {
              title = question.title;
              prev = title;
            }
            return (
              title
                ? (
                  <li>
                    <strong className='c-purple'>
                      {title}
                    </strong>
                    <ul>
                      <li key={`cand_edit_lib_miss_q_a_${question.question}`}>{question.question}</li>
                    </ul>
                  </li>
                )
                : (
                  <ul>
                    <li key={`cand_edit_lib_miss_q_a_${question.question}`}>{question.question}</li>
                  </ul>
                )
            )
          })}
        </ul>
      </div>
    </div>
  );
};

export function CandidateWarningInvalidFieldsForEmail({ candidate }) {
  let rows = getCandidateInvalidRows({ candidate });
  return (!!rows.length
    ? (`
      <tr>
        <td>
        <ul className='mt-1'>
          ${rows.map(row => (`
            <li>
              <strong>${row.text}</strong>
              ${row.subtext ? ` ${row.subtext} ` : ` `}
              <span>(page ${row.page})</span>
            </li>
          `))}
        </ul>
        </td>
      </tr>
    `) : ''
  );
};

export function getMissingQuestionAnswersInData({ candidate }) {
  let {
    permittedJobs: jobs,
    __globalQuestions: globalQuestions
  } = candidate;
  globalQuestions = Obj(globalQuestions);
  let {
    missedQuestions
  } = mapQuestionsAnswers({
    globalQuestions,
    candidate,
    jobs,
  });
  return missedQuestions;
};
