import {
  compile
} from 'handlebars';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Candidate from '../../../lib/Candidate';
import {
  TEN_BY_TEN_ADMIN_CONTACT
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition from '../../../lib/Definition';
import {
  getHTMLLinkString
} from '../../../lib/HTML.lib';
import {
  mapContactsToStrings,
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import {
  compileText,
  trim
} from '../../../lib/String.lib';
import TEMPLATE__DIFFERENT_CANDIDATE_PLATFORM_RATING from '../../../lib/templates/DifferentCandidatePlatformRating.template';
import {
  CandidateEditController
} from '../Edit/CandidateEdit';
import {
  InputNotePlatformRatingController
} from './InputNotePlatformRating';

export default function sendEmailDifferentPlatformRating() {
  const candidate = CandidateEditController().state;
  const NOTE = trim(InputNotePlatformRatingController().state?.note);
  if (NOT(NOTE)) { return; }
  const calculatedPR = Candidate.calculatePlatformRating({ candidate });
  const recommendedPlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: calculatedPR
  });
  const candidatePlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: candidate.platformRating
  });
  return sendSafeEmail({
    to: mapContactsToStrings([TEN_BY_TEN_ADMIN_CONTACT]),
    subject: compileText(TEMPLATE__DIFFERENT_CANDIDATE_PLATFORM_RATING.subject, {
      SYSTEM_RATING: recommendedPlatformRatingTag.label,
      USER: Core.getUserName(),
      CANDIDATE_RATING: candidatePlatformRatingTag.label
    }),
    html: compile(TEMPLATE__DIFFERENT_CANDIDATE_PLATFORM_RATING.body)({
      LINK__CANDIDATE_EDIT: getHTMLLinkString({
        url: Core.getPath(`candidate/edit/${candidate.id}`), name: candidate._name || '[candidate]'
      }),
      SYSTEM_RATING: recommendedPlatformRatingTag.label,
      USER: Core.getUserName(),
      CANDIDATE_RATING: candidatePlatformRatingTag.label,
      NOTES__PLATFORM_RATING: NOTE,
      POSITIVE_SIGNALS: candidate._positiveSignals,
      NEGATIVE_SIGNALS: candidate._negativeSignals
    })
  });
}
