import AppUI from '../dictionaries/AppUI.dic';
import Core from "./Core";
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH
} from './Definition';
import Engagement from './Engagement';
import {
  newModel
} from './GenericTools.lib';
import Http from "./Http";
import Job from "./Job";
import {
  Obj
} from './Object.lib';
import {
  compileText,
  Str
} from './String.lib';
import {
  mapEngagement
} from './models/engagement';
import {
  model as engagementDisagreement,
  mapDisagreements
} from "./models/engagement-disagreement";
import {
  QUERY__MATCH_DECISION
} from './queries/MatchDecision.query';
import {
  COLLECTION__ENGAGEMENTS,
  COLLECTION__MATCH_DECISION_TABLES,
  createLoopbackRecord,
  readLoopbackRecord
} from './services/BE/loopback.api';
import getStateModel from "./tools/getStateModel";

const cache = {};

const EngagementDisagreement = {

  cleanCache() {
    Object.keys(cache).forEach((key) => { delete cache[key] });
  },

  get: async (callback = (results) => results, requestOptions) => {
    const { where = {}, fields = [] } = requestOptions;
    const _requestOptions = {
      filter: JSON.stringify({ where, fields })
    };
    cache[_requestOptions.filter] = (
      cache[_requestOptions.filter] ||
      Http.get(
        Core.getApi("MatchDecisionTables"),
        _requestOptions
      ).then(mapDisagreements)
    );
    callback(await cache[_requestOptions.filter]);
    return cache[_requestOptions.filter];
  },

  findOrCreate: async (disagreement) => {
    try {

      if (!disagreement.engagementId) {
        throw new Error(
          `Missing disagreement's engagement id (${disagreement.engagementId})`
        );
      }

      console.debug(
        'EngagementDisagreement.findOrCreate...',
        '\n', disagreement,
      );

      const existingRecord = Obj(
        await readLoopbackRecord(
          compileText(
            QUERY__MATCH_DECISION,
            {
              ENGAGEMENT_ID: disagreement.engagementId,
              ANNOTATOR: Core.getUser().email
            }
          )
        )
      );

      if (existingRecord.id) {
        return EngagementDisagreement.update({
          ...existingRecord,
          ...disagreement,
          annotator: existingRecord.annotator
        });
      }
      else {
        return EngagementDisagreement.post(disagreement);
      }

    }
    catch (error) {
      if (Core.isProduction()) {
        Core.showError(AppUI.unexpectedError.message, error);
      }
      else {
        Core.showError(error);
      }
    }
  },

  post: (disagreement, onSuccess, onFailure) => {

    if (!disagreement.engagementId) {
      return Core.showError(
        `Missing disagreement's engagement id (${disagreement.engagementId})`,
        new Error()
      );
    }

    EngagementDisagreement.cleanCache();

    console.debug('EngagementDisagreement.post', disagreement);

    disagreement = {
      ...disagreement,
      candidateJobId: disagreement.jobId + '-' + disagreement.candidateId
    };

    Job.getMlMatchingScore(
      {
        params: {
          jobId: disagreement.jobId,
          candidateId: disagreement.candidateId
        }
      },
      // ON SUCCESS
      (response) => {

        console.debug('Job.getMlMatchingScore:onSuccess', response);

        let matchDetails = Obj(response.match);
        let matchDecision = Str(matchDetails.match || matchDetails.msg);
        let probScore = matchDetails.score;

        disagreement = {
          ...disagreement,
          probScore,
        };

        const update = {
          probScore,
          matchDetails
        };

        update.matchStrength = Definition.getId(
          DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
          disagreement.matchDecision
        );
        update.matchedByWho = {
          email: disagreement.annotator
        };

        // UPDATE - Engagement.probScore
        Engagement.update(
          { id: disagreement.engagementId },
          update
        ).catch(Core.showError);

        // CREATE - User match decision
        createLoopbackRecord({
          collection: COLLECTION__MATCH_DECISION_TABLES,
          record: disagreement,
          model: newModel(engagementDisagreement)
        }).then(onSuccess).catch(onFailure);

        /**
         * [2025-01-23][11824]
         * @note
         * We are deprecating the use of the following fields.
         * - whyNoFieldsValues: []
         * - whyYesFieldsValues: []
         * They were used to create the record for the ML match decision table.
         * These fields contained matching details between candidates and jobs based on the picked reasons.
         * However, now we will use the unified Engagement Feedback Reason from the Tag Management System.
         * Existing fields and their values will be retained in the collection and associated records.
         */

        // CREATE - ML match decision
        createLoopbackRecord({
          collection: COLLECTION__MATCH_DECISION_TABLES,
          record: {
            ...disagreement,
            annotator: 'ML',
            matchDecision: 'ML_' + matchDecision,
            whyNoCategories: [],
            whyNoDetails: '',
            whyNoPrivateNote: '',
            whyYesCategories: [],
            whyYesDetails: '',
            whyYesPrivateNote: '',
            shouldTag: '',
            shouldNotTag: '',
            whyNeedToReadCV: '',
          }
        });

      },
      // ON FAILURE
      async (error) => {

        console.debug('Job.getMlMatchingScore:onFailure', error);

        // CREATE - User match decision
        await createLoopbackRecord({
          collection: COLLECTION__MATCH_DECISION_TABLES,
          record: disagreement,
          model: newModel(engagementDisagreement)
        }).then(onSuccess).catch(onFailure);

      }
    )
  },
  update: async (disagreement, success) => {

    if (!disagreement.engagementId) {
      return Core.showError(
        `Missing disagreement's engagement id (${disagreement.engagementId})`,
        new Error()
      );
    }

    EngagementDisagreement.cleanCache();

    console.debug('EngagementDisagreement.update', disagreement);
    let engagementMatchInfo = await readLoopbackRecord({
      collection: COLLECTION__ENGAGEMENTS,
      where: { id: disagreement.engagementId },
      fields: ['id', 'matchStrength', 'matchedByWho'],
      limit: 1,
      mapper: mapEngagement
    });
    let update = {};
    console.debug(
      'CURRENT DECISION',
      engagementMatchInfo.matchedByWho.email,
      engagementMatchInfo._matchStrength,
      engagementMatchInfo.matchStrength
    );
    if (
      /** @note [2024-10-29][11431] Removed the condition for same annotator. */
      (disagreement.matchDecision !== engagementMatchInfo._matchStrength)
    ) {
      update.matchStrength = Definition.getId(
        DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
        disagreement.matchDecision
      );
      update.matchedByWho = {
        email: disagreement.annotator
      };
      console.debug(
        'NEW DECISION',
        disagreement.annotator,
        disagreement.matchDecision,
        update.matchStrength
      );
    }
    if (!disagreement.probScore) {
      Job.getMlMatchingScore(
        {
          params: {
            jobId: disagreement.jobId,
            candidateId: disagreement.candidateId
          }
        },
        // ON SUCCESS
        (response) => {
          let matchDetails = Obj(response.match);
          let probScore = matchDetails.score;
          disagreement.probScore = probScore;
          update = { ...update, probScore, matchDetails };
        }
      );
    }
    if (Object.keys(update).length) {
      Engagement.update(engagementMatchInfo, update).catch(Core.showError);
    }
    return Http.patch(
      Core.getApi("MatchDecisionTables/" + disagreement.id),
      getStateModel(disagreement, engagementDisagreement),
      success
    );
  },

};

export default EngagementDisagreement;
