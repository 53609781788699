import {
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import Box from './Box';
import Icon from './Icon';
import {
  PLACEMENT__LEFT_END
} from './StyledTooltip';

export default function OnlyAdmin({
  icon = 'lock',
  placement = PLACEMENT__LEFT_END,
  title = undefined,
  render: Render,
  renderProps = {},
  className = '',
  ...props
}) {
  if (!Core.isAdmin()) { return null; }
  return (
    <Box row
      {...props}
      className={className}
    >
      <Icon
        icon={icon}
        title={join(['Admin only', title], ': ')}
        placement={placement}
        className='mr-05 icon16'
      />
      <Render {...renderProps} />
    </Box>
  );
}
