import {
  Component
} from "react";
import Core from '../../../../lib/Core';
import Definition, {
  EMP_MSG_TYPE__REMINDER_ID
} from "../../../../lib/Definition";
import {
  getEmployerAccounts
} from '../../../../lib/Employer';
import {
  EMPLOYER__PRIMARY_CONTACT_NOTES
} from "../../../../lib/models/employer";
import formatURL from "../../../../lib/tools/formatURL";
import {
  EmailsListClass
} from "../../../Forms/EmailsList";
import Button from '../../../Layout/Wrappers/Button';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import IconLinkLauncher from '../../../Layout/Wrappers/IconLinkLauncher';
import List from '../../../Layout/Wrappers/List';
import RichTextBox from "../../../Layout/Wrappers/RichTextBox";
import Row from "../../../Layout/Wrappers/Row";
import TextField from '../../../Layout/Wrappers/TextField';

class JobEmailsList extends Component {
  constructor() {
    super(...arguments);
    this.state = this.props.job;
  }
  render() {
    let {
      job,
      index,
      EmployerEditController
    } = this.props;
    return (
      <EmailsListClass
        name={'employers_forms_contact__job_contacts'}
        title={`${job.jobTitle} (job)`}
        model={{
          ...this.state,
          __accounts: EmployerEditController.state.__accounts
        }}
        onSave={({ emailsList = [] }) => EmployerEditController.setState(state => {
          state.jobsCollection[index].emailsList = emailsList;
          state.updatedJobs[job.id] = job;
          return state;
        })}
        messageTypeTags={Definition.get('employerMessageType')}
        disabledExclude
      />
    );
  }
}

class Contact extends Component {

  render() {

    const EmployerEditController = this.props.parent;
    let {
      state: employer = {},
      state = {}
    } = EmployerEditController;
    let { __accounts = {} } = state;

    const updateState = async update => {
      return new Promise(resolve => {
        EmployerEditController.setState(prevState => {
          resolve();
          return { ...prevState, ...update };
        });
      });
    }

    const fetchAccounts = async () => {
      __accounts.error = false;
      __accounts.fetching = true;
      __accounts.fetched = false;
      await updateState({ __accounts });
      __accounts.list = await getEmployerAccounts({ employerId: employer.id }).catch(Core.showError);
      if (Array.isArray(__accounts.list)) {
        __accounts.error = false;
        __accounts.fetching = false;
        __accounts.fetched = true;
      }
      else {
        __accounts.error = true;
        __accounts.fetching = false;
        __accounts.fetched = false;
      }
      employer.__accounts = __accounts;
      await updateState({ __accounts, employer });
    }

    if (state.id && !__accounts.error && !__accounts.fetching && !__accounts.fetched) {
      fetchAccounts();
    }

    return (
      <List className="EmployerForm Basics">
        <List.Item
          className="form-header"
          disabled={true}
        >
          CONTACT
        </List.Item>

        <Row>
          <Fieldset
            title='Contact Name'
          >
            <TextField
              name="primaryContactName"
              value={EmployerEditController.state.primaryContactName}
              onChange={(ev, primaryContactName) =>
                EmployerEditController.setState({ primaryContactName })
              }
              fullWidth
            />
          </Fieldset>
          <Fieldset
            title='Title / Position'
          >
            <TextField
              name="primaryContactTitle"
              value={EmployerEditController.state.primaryContactTitle}
              onChange={(ev, primaryContactTitle) =>
                EmployerEditController.setState({ primaryContactTitle })
              }
              fullWidth
            />
          </Fieldset>
        </Row>

        <Row>
          <Fieldset
            title='Email'
          >
            <TextField
              name="primaryContactEmail"
              value={EmployerEditController.state.primaryContactEmail}
              onChange={(ev, primaryContactEmail) =>
                EmployerEditController.setState({ primaryContactEmail })
              }
              fullWidth
            />
          </Fieldset>
          <Fieldset
            title='Phone'
          >
            <TextField
              name="primaryContactPhone"
              value={EmployerEditController.state.primaryContactPhone}
              onChange={(ev, primaryContactPhone) =>
                EmployerEditController.setState({ primaryContactPhone })
              }
              fullWidth
            />
          </Fieldset>
        </Row>

        <Row>
          <Fieldset
            title='LinkedIn URL'
            nextToTitle={
              <IconLinkLauncher
                url={EmployerEditController.state.primaryContactLinkedin}
              />
            }
          >
            <TextField
              name="primaryContactLinkedin"
              value={EmployerEditController.state.primaryContactLinkedin}
              onChange={(ev, primaryContactLinkedin) =>
                EmployerEditController.setState({
                  primaryContactLinkedin: formatURL(primaryContactLinkedin)
                })
              }
              fullWidth
            />
          </Fieldset>
          <Fieldset
            title='Careers Page'
            nextToTitle={
              <IconLinkLauncher
                url={EmployerEditController.state.careersPage}
              />
            }
          >
            <TextField
              name="careersPage"
              value={EmployerEditController.state.careersPage}
              onChange={(ev, careersPage) =>
                EmployerEditController.setState({
                  careersPage: formatURL(careersPage)
                })
              }
              fullWidth
            />
          </Fieldset>
        </Row>

        <Fieldset
          title='Notes'
          nextToTitle={
            <Button
              variant="outlined"
              color="secondary"
              className="ml-2"
              onClick={ev => {
                EmployerEditController.setState({
                  primaryContactNotes: `${EMPLOYER__PRIMARY_CONTACT_NOTES}<br/>${EmployerEditController.state.primaryContactNotes}`
                });
              }}
              small
            >
              <span className="capitalize">
                Insert template
              </span>
            </Button>
          }
          subtitle='Who did the intake, role (founder, hiring manager, recruiter, coordinator), best way to contact, how firm is SLA, ok to text call'
        >
          <RichTextBox
            value={EmployerEditController.state.primaryContactNotes}
            onChange={(primaryContactNotes) =>
              EmployerEditController.setState({ primaryContactNotes })
            }
          />
        </Fieldset>

        <EmailsListClass
          name={'employers_forms_contact__employer_contacts'}
          title={`${employer._name} (employer)`}
          model={EmployerEditController.state}
          onSave={update => EmployerEditController.setState(update)}
          messageTypeTags={
            Definition.get('employerMessageType')
              /** 
               * @todo 
               * filtered options by a task request.
               * remove it in future if added more employer message types.
               * 
               * story-3927-M15 | 2021-09-09 Thu µ
               */
              .filter(({ id }) => id === EMP_MSG_TYPE__REMINDER_ID)
          }
        />

        {!!EmployerEditController.state.jobsCollection.length && (
          EmployerEditController.state.jobsCollection.map((job, index) => (
            <JobEmailsList
              key={`job-emails-list-${job.id}`}
              job={job}
              index={index}
              EmployerEditController={EmployerEditController}
            />
          ))
        )}

      </List>
    );
  }
}

export default Contact;
