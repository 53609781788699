import {
  Obj
} from './Object.lib';
import {
  trim
} from './String.lib';

export function join(input = [], div = ', ') {
  return Arr(
    input
  ).map(
    (v) => trim(v)
  ).filter(
    (v) => !!(v)
  ).join(
    div
  );
}

/**
 * 
 * @param {any} variable 
 * @returns {any[]}
 */
export function getArray(variable) {
  return Array.isArray(variable) ? variable : [];
}
export const Arr = getArray;

export function rearrangeElement(array, currentIndex, newIndex) {
  const _normalizeIndex = (value) => {
    const min = 0;
    const max = array.length - 1;
    value = parseInt(value);
    if ((min >= 0) && (max >= 0) && (min <= max)) {
      if (value > max) { value = max; }
      if (value < min) { value = min; }
    }
    return parseInt(value);
  }
  currentIndex = _normalizeIndex(currentIndex);
  newIndex = _normalizeIndex(newIndex);
  const element = array.splice(currentIndex, 1)[0];
  array.splice(newIndex, 0, element);
  return array;
}


/**
 * Sanitizes an array by removing duplicates and null/undefined values.
 * @param {any[]} arr
 * @returns {any[]}
 */
export function sanitizeArr(arr) {
  return [...new Set(Arr(arr).filter((v) => !!(v)))];
}

export function filterACL(arr) {
  return [
    ...new Set(
      Arr(arr).filter(
        (option) => {
          const { acl } = Obj(option);
          return (!!option && ((acl === undefined) || (acl === true)))
        }
      )
    )
  ];
}

export function ArrInt(arr) {
  return Arr(arr).map((tagId) => parseInt(tagId));
}
