import {
  Avatar
} from '@mui/material';
import {
  useState
} from 'react';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import CrunchbaseLib from '../../../lib/Crunchbase.lib';
import Definition from '../../../lib/Definition';
import {
  capitalize,
  evalSameName
} from '../../../lib/GenericTools.lib';
import {
  MODEL_NAME__COMPANY
} from '../../../lib/models/company.model';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  evalDefaultDegree
} from '../../../lib/School';
import { Str } from '../../../lib/String.lib';
import Button from '../../Layout/Wrappers/Button';
import Chip from '../../Layout/Wrappers/Chip';
import Col from '../../Layout/Wrappers/Col';
import Divider from '../../Layout/Wrappers/Divider';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import {
  ErrorChipMessage,
  ErrorMessage,
  WarningChipMessage,
  WarningMessage
} from '../../Layout/Wrappers/Message';
import OnlyAdmin from '../../Layout/Wrappers/OnlyAdmin';
import Row from '../../Layout/Wrappers/Row';
import StyledTooltip, { PLACEMENT__TOP_START } from '../../Layout/Wrappers/StyledTooltip';
import {
  SUGGESTION_TYPE_HEADER
} from '../../Layout/Wrappers/SuggestionList';
import {
  CrunchbaseOrganizationDetails
} from '../../Shared/CrunchbaseOrganization';
import LinkedinFormattedDate from '../../Shared/LinkedinFormattedDate';
import {
  removeMappedSignalsFlag
} from '../CandidateSignalTags.lib';
import EmploymentGapFieldset from '../Forms/EmploymentGapFieldset';
import {
  HistoryName
} from '../Forms/HistoryName';
import JobHopperFieldset from '../Forms/JobHopperFieldset';
import SignalChips, {
  SIGNAL_CHIPS__VARIANT_COMPACT
} from '../Forms/SignalChips';
import YearsOfExperienceRow from '../Forms/YearsOfExperienceRow';
import HISTORIES_DERIVATOR from './Histories.derivator';
import HistoryEdit from './HistoryEdit';

export default function HistorySection({
  className = '',
  candidate,
  onChange,
  context = { ...HISTORIES_DERIVATOR.experience }
}) {
  const {
    sectionKey,
    labelSectionTitle,
    labelMostRelevantTitle,
    entityModelName,
    entityModelNamePlural,
    historiesKey,
    historyKey,
    historyNameKey,
    pieceKey,
    piecesKey,
    pieceNameKey,
    getHistoryModel,
    setRelevantEntity,
    unsetRelevantEntity,
    getRelevantEntity,
    getHistoryPieceId,
    sortHistories,
    CandidateEditController
  } = context;
  const isForCompany = (entityModelName === MODEL_NAME__COMPANY);
  function _addHistory() {
    Arr(candidate[historiesKey]).unshift(
      getHistoryModel({ __newRecord: true })
    );
    onChange({
      [historiesKey]: candidate[historiesKey],
      key: historiesKey
    });
  }

  let __listMostRelevant = candidate[historiesKey].map(
    history => {
      let pieces = history[piecesKey].filter(
        p => !!(p[pieceNameKey] || '').trim()
      );
      return history[historyNameKey] ? [
        !!pieces.length && {
          label: history[historyNameKey],
          type: SUGGESTION_TYPE_HEADER
        },
        ...pieces.map(piece => {
          if (!getRelevantEntity({ candidate })) {
            setRelevantEntity({ candidate, history, piece });
            onChange(candidate);
          }
          return {
            id: getHistoryPieceId({
              historyName: history[historyNameKey],
              piece
            }),
            label: (
              isForCompany
                ? piece[pieceNameKey]
                : join(
                  [
                    (
                      Definition.getTag({
                        categoryKey: 'undergraduateDegree', tagLabel: piece.degreeName
                      })?.label ||
                      piece.degreeName
                    ),
                    capitalize(piece.degreeMajor)
                  ], ' > '
                )
            ),
            [historyKey]: history,
            [pieceKey]: piece
          }
        })
      ].filter(v => !!v) : [];
    }
  ).flat();

  const __selectedMostRelevant = getRelevantEntity({ candidate });

  return (
    Core.isAdmin() && candidate && (
      <div className={`bg-main rounded m-1 py-1 ${className}`.trim()}>

        <div className='d-flex flex-align-left-bottom w-100 px-2'>
          <OnlyAdmin
            render={() => (
              <>
                {labelSectionTitle}
              </>
            )}
            title='This section is only visible to admins'
            placement={PLACEMENT__TOP_START}
            className='f-xl fw-700 mr-1 c-cyan-darker'
          />
        </div>

        {isForCompany && Core.isAdmin() && (
          <YearsOfExperienceRow candidate={candidate} className='mt-2' />
        )}

        {!!__listMostRelevant?.length && (
          <>
            <Row className='mt-4'>
              <Col fullWidth>
                <label>
                  {labelMostRelevantTitle}
                </label>
                <Menu dropdown
                  value={__selectedMostRelevant}
                  onClick={() => {
                    sortHistories({ candidate });
                    onChange(candidate);
                  }}
                  onChange={(value, option, previousOption) => {
                    if (option.id) {
                      setRelevantEntity({ candidate, option });
                    }
                    else {
                      const history = candidate[historiesKey].find(({ id }) => (id === previousOption[historyKey].id));
                      const piece = history[piecesKey].find(({ id }) => (id === previousOption[pieceKey].id));
                      console.debug('OPT VS PREV', candidate, option, previousOption, history, piece);
                      unsetRelevantEntity({
                        candidate,
                        history,
                        piece
                      });
                    }
                    sortHistories({ candidate });
                    onChange(candidate);
                  }}
                  optionLabelMapper={(option, defaultElement) => (
                    option.id
                      ? join(
                        [
                          Str(Obj(option[historyKey])[historyNameKey]),
                          option.label
                        ], ' > '
                      ) : defaultElement
                  )}
                  options={__listMostRelevant}
                />
              </Col>
            </Row>
            {isForCompany ? (
              <WarningMessage show={!candidate.currentEmployer}>
                {`No {pieceKey} has been selected as most recent`}
              </WarningMessage>
            ) : (
              <WarningMessage show={!candidate.undergraduateSchool}>
                {`No {pieceKey} has been selected as most relevant`}
              </WarningMessage>
            )}
          </>
        )}

        <Row className='mt-4'>
          <Col className='w-100 py-0'>
            <Divider className='d-none' />
            <div className='d-flex flex-align-left-center w-100'>
              <strong
                className='f-lg fw-500 c-cyan-darker mr-1'
              >
                {entityModelNamePlural}
                {!!candidate[historiesKey]?.length && (
                  <>: ({candidate[historiesKey]?.length})</>
                )}
              </strong>
              {isForCompany && candidate.averageMonthsPerEmployer && (
                <small className='c-red mr-1'>
                  &nbsp;&nbsp;Average{' '}
                  {Math.round((candidate.averageMonthsPerEmployer / 12) * 10) / 10} years per
                  company
                </small>
              )}
              {!!candidate[historiesKey]?.length && (
                <div className='d-flex flex-align-left-center ml-auto'>
                  <IconButton
                    title={`Add ${sectionKey}`}
                    onClick={_addHistory}
                    className='ml-auto c-black-medium'
                  >
                    <i className='material-icons c-inherit'>add</i>
                  </IconButton>
                </div>
              )}
            </div>
          </Col>
        </Row>

        {
          !candidate[historiesKey]?.length && (
            <>
              <div className='d-flex px-2'>
                <Button
                  variant='outlined'
                  className='my-1 mx-auto w-100'
                  startIcon={<i className='material-icons c-inherit'>add</i>}
                  onClick={_addHistory}
                >
                  <span style={{ height: 20 }}>
                    {`Add ${sectionKey}`}
                  </span>
                </Button>
              </div>
            </>
          )
        }

        {
          isForCompany && !!candidate[historiesKey]?.length && (
            <>
              <JobHopperFieldset CandidateEditController={CandidateEditController} />
              <EmploymentGapFieldset CandidateEditController={CandidateEditController} />
            </>
          )
        }

        <Pieces
          className='mt-2'
          candidate={candidate}
          onChange={onChange}
          context={context}
        />

      </div >
    )
  );
}

export function Pieces({
  className,
  candidate,
  onChange,
  maxVisible = 3,
  context = { ...HISTORIES_DERIVATOR.experience }
}) {
  const {
    sectionKey,
    historiesKey
  } = context;
  const [expanded, setExpanded] = useState(false);
  let count = 0;
  return !!candidate[historiesKey]?.length && (
    <div className={`px-2 ${className || ''}`.trim()}>
      {expanded ? (
        candidate[historiesKey]
          .map((history, index) => (
            <History
              key={`history-${history.id}`}
              candidate={candidate}
              history={history}
              index={index}
              onChange={onChange}
              context={context}
            />
          ))
      ) : (
        candidate[historiesKey]
          .filter(history => (count++ < maxVisible))
          .map((history, index) => (
            <History
              key={`history-${history.id}`}
              candidate={candidate}
              history={history}
              index={index}
              onChange={onChange}
              setExpanded={setExpanded}
              context={context}
            />
          ))
      )}
      {(candidate[historiesKey].length > maxVisible) && (
        <div className='d-flex'>
          <Button flat
            size='small'
            onClick={event => setExpanded(!expanded)}
            className='mb-1 tt-unset c-black-darker c-purple-hover px-2 w-100'
            style={{ marginTop: '-0.5rem' }}
            endIcon={<i className='material-icons c-inherit'>{expanded ? 'expand_less' : 'expand_more'}</i>}
          >
            {expanded ? `Show less ${sectionKey}s` : `Show all ${candidate[historiesKey].length} ${sectionKey}s`}
          </Button>
        </div>
      )}
    </div>
  );
}

export function History({
  candidate,
  history,
  index,
  onChange = candidateUpdate => null,
  setExpanded = expanded => null,
  context = { ...HISTORIES_DERIVATOR.experience }
}) {
  const {
    sectionKey,
    entityIdKey,
    historiesKey,
    historyKey,
    historyNameKey,
    piecesKey,
    entityModelName,
    unsetRelevantEntity,
    sortHistories
  } = context;
  const isForCompany = (entityModelName === MODEL_NAME__COMPANY);
  const entityModelNameLowerCase = entityModelName.toLowerCase();
  const linkedInEntityConfigs = (
    isForCompany
      ? {
        currentlyEmployed: history.__hasPositionCurrentlyEmployed
      }
      : {
        startDateRequired: false,
        endDateRequired: false
      }
  );
  let organization = (
    (candidate.__dicOrganizations || {})[(
      (candidate.__dicSignals || {})[
        history[entityIdKey]
      ]?.crunchbaseOrganizationId
    )] || {}
  );

  let entityName = (candidate.__dicSignals || {})[history[entityIdKey]]?.name;

  return (
    <div className='py-2' data-id={`history-${history.id}-${history[historyNameKey]}`}>
      <div className='d-flex flex-align-left-top nowrap'>
        <StyledTooltip
          title={<CrunchbaseOrganizationDetails organization={organization} />}
          placement='top-start'
        >
          <IconButton
            className={`p-0 mr-2 ${organization.cb_url ? 'pointer' : 'cursor-default'}`.trim()}
            color='secondary'
            onClick={event => organization.cb_url && Core.openPopUp(organization.cb_url, 1200)}
          >
            <Avatar src={organization.logo_url} sx={{ width: 48, height: 48 }} className='bg-cyan'>
              {(history[historyNameKey] || '')[0]}
            </Avatar>
          </IconButton>
        </StyledTooltip>
        <div className='mr-1 flex-1'>
          <div className='flex-column'>
            <HistoryName
              history={history}
              type={entityModelName}
              organization={organization}
            />
            {(
              !!organization.id && !evalSameName(history[historyNameKey], organization.name)
                ? (
                  <small className='c-black-medium' style={{ marginTop: -3 }}>
                    {organization.name}
                  </small>
                ) : !!history[entityIdKey] && !evalSameName(history[historyNameKey], entityName) && (
                  <small className='c-black-medium' style={{ marginTop: -3 }}>
                    {entityName}
                  </small>
                )
            )}
          </div>
          {(history[piecesKey]?.length === 1)
            ? (
              <LinkedinFormattedDate
                startDateYear={history[piecesKey][0].startDateYear}
                startDateMonth={history[piecesKey][0].startDateMonth}
                endDateYear={history[piecesKey][0].endDateYear}
                endDateMonth={history[piecesKey][0].endDateMonth}
                durationYears={history.__durationYears}
                durationMonthsModule={history.__durationMonthsModule}
                {...linkedInEntityConfigs}
              />
            )
            : !!history[piecesKey]?.length && (
              <LinkedinFormattedDate
                startDate={history.__startDate}
                endDate={history.__endDate}
                durationYears={history.__durationYears}
                durationMonthsModule={history.__durationMonthsModule}
                {...linkedInEntityConfigs}
              />
            )}
        </div>
        <div className='ml-auto'>
          <div className='d-flex flex-align-left-center'>
            {(
              candidate.__dicOrganizations &&
              candidate.__dicSignals &&
              !history.__newRecord && (
                <span className='mr-1'>
                  <WarningChipMessage
                    show={history[historyNameKey] && !history[entityIdKey]}
                    title={`This ${sectionKey} is not linked to any ${entityModelNameLowerCase}, click on the pencil to correct it`}
                  >
                    {`Unknown ${entityModelNameLowerCase}`}
                  </WarningChipMessage>
                  <WarningChipMessage
                    show={CrunchbaseLib.isEnabled() && !!history[entityIdKey] && !organization.id}
                    title={`This ${sectionKey} is not linked to crunchbase, click on the pencil to correct it`}
                  >
                    Unknown crunchbase
                  </WarningChipMessage>
                </span>
              )
            )}
            <HistoryEdit
              className='c-black-medium'
              history={history}
              onClick={event => setExpanded(true)}
              onChange={update => {
                candidate[historiesKey][index] = update;
                removeMappedSignalsFlag();
                onChange({
                  [historiesKey]: candidate[historiesKey],
                  key: historiesKey
                });
              }}
              onDelete={async (event) => {
                candidate[historiesKey].splice(index, 1);
                if (
                  candidate.currentEmployer === history[historyNameKey]
                ) {
                  unsetRelevantEntity({
                    candidate,
                    history
                  });
                }
                onChange({
                  [historiesKey]: candidate[historiesKey],
                  key: historiesKey
                });
              }}
              onClose={() => {
                sortHistories({ candidate });
                onChange(candidate);
              }}
              context={{
                ...context,
                candidate
              }}
            />
          </div>
        </div>
      </div>
      <div className='ml-8'>
        {history[historyNameKey] && (
          <SignalChips
            {...{ [historyKey]: history }}
            signalsHash={candidate.__dicSignals}
            onChange={async update => {
              history = { ...history, ...update };
              candidate[historiesKey][index] = history;
              removeMappedSignalsFlag();
              onChange({
                [historiesKey]: candidate[historiesKey],
                key: historiesKey
              });
            }}
            variant={SIGNAL_CHIPS__VARIANT_COMPACT}
          />
        )}
        <HistoryPieces
          history={history}
          candidate={candidate}
          context={context}
        />
      </div>
    </div>
  );
}

export function HistoryPieces({
  maxVisible = 2,
  candidate,
  history,
  context = { ...HISTORIES_DERIVATOR.experience }
}) {
  const {
    sectionKey,
    piecesKey,
    pieceKey,
    pieceNameKey
  } = context;
  const [expanded, setExpanded] = useState(false);
  return (
    <div className='mt-2'>
      <ErrorMessage show={!history.__newRecord && !history[piecesKey]?.length}>
        {`${capitalize(pieceKey)} details is required, click on the pencil above to edit this ${sectionKey}`}
      </ErrorMessage>
      {(history[piecesKey] || []).slice(0, maxVisible).map((piece, index) =>
        <HistoryPiece
          key={`piece-${piece.id}-${piece[pieceNameKey]}-a`}
          candidate={candidate}
          history={history}
          piece={piece}
          index={index}
          expanded={!!expanded}
          showDate={history[piecesKey]?.length !== 1}
          context={context}
        />
      )}
      {expanded && (history[piecesKey] || []).slice(maxVisible).map((piece, index) =>
        <HistoryPiece
          key={`piece-${piece.id}-b${piece[pieceNameKey]}-b`}
          candidate={candidate}
          history={history}
          piece={piece}
          index={index}
          expanded={!!expanded}
          context={context}
        />
      )}
      {(history[piecesKey]?.length > maxVisible) && (
        <div className='d-flex'>
          <Button flat
            size='small'
            onClick={event => setExpanded(!expanded)}
            className='mb-1 tt-unset c-gray-common px-2 c-purple-hover'
            style={{ marginTop: '-0.5rem' }}
            endIcon={<i className='material-icons c-inherit'>{expanded ? 'expand_less' : 'expand_more'}</i>}
          >
            {expanded ? `Show less ${pieceKey}s` : `Show all ${history[piecesKey].length} ${pieceKey} details`}
          </Button>
        </div>
      )}
    </div>
  )
}

export function HistoryPiece({
  candidate,
  history,
  piece,
  index,
  expanded,
  showDate = true,
  showDescriptionAfter = 1,
  context = { ...HISTORIES_DERIVATOR.experience }
}) {
  const {
    entityModelName,
    pieceKey,
    pieceNameKey,
    evalRelevantEntity
  } = context;
  const isForCompany = (entityModelName === MODEL_NAME__COMPANY);
  let [state, setState] = useState({});
  async function updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let {
    showFullDescription,
    prev = false
  } = state;

  (async () => {
    if (prev !== expanded) {
      await updateState({
        prev: !!expanded,
        showFullDescription: !!expanded
      });
    }
  })();
  const Info = () => (
    <Button flat
      size='small'
      className='nowrap tt-unset w-10 c-black-medium p-0 px-1 fw-normal f-sm ml-auto'
      onClick={event => {
        updateState({ showFullDescription: !showFullDescription });
      }}
    >
      {showFullDescription ? 'See less' : 'See more'}
    </Button>
  );
  return (
    <div className='mb-2'>
      {isForCompany ? (
        <div className='d-flex flex-align-left-center'>
          <strong className='capitalize c-black-medium mr-1'>
            {piece[pieceNameKey] || (
              <ErrorChipMessage title='Edit item to correct this'>
                <div className='statement fw-normal'>A {pieceKey} ${pieceNameKey} is required</div>
              </ErrorChipMessage>
            )}
          </strong>
          {showDate && (
            <LinkedinFormattedDate {...piece} className='c-black-medium mr-1' />
          )}
          {(
            evalRelevantEntity({
              candidate,
              history,
              piece
            }) &&
            (
              <StyledTooltip
                title={
                  `This ${pieceKey} is the candidate's most recent, relevant ${pieceKey}. You can select a different ${pieceKey} by editing the corresponding history`
                }
                placement='top-start'
                paperStyle={{ width: 320 }}
              >
                <Chip
                  className='c-black-medium py-0'
                  size='small'
                  variant='outlined'
                  label='most recent'
                  style={{ height: 16 }}
                />
              </StyledTooltip>
            )
          )}
          <small className='ml-auto'>
            {(
              piece.description &&
              !expanded &&
              !showFullDescription &&
              (index >= showDescriptionAfter) && <Info />
            )}
          </small>
        </div>
      ) : (
        <div className='d-flex flex-align-left-center'>
          <strong className='tt-unset c-black-medium'>
            {Definition.getTag({
              categoryKey: 'undergraduateDegree',
              tagLabel: piece.degreeName
            })?.label || piece.degreeName || (
                <ErrorChipMessage title='Edit item to correct this'>
                  <div className='tt-unset fw-normal'>
                    A degree title is required
                  </div>
                </ErrorChipMessage>
              )}
          </strong>
          {!!piece.degreeMajor && (
            <>
              &nbsp;-&nbsp;
              <strong className='c-black-medium mr-1'>
                {piece.degreeMajor}
              </strong>
            </>
          )}
          {showDate && (
            <LinkedinFormattedDate
              {...piece}
              className='c-black-medium ml-1 mr-auto'
              startDateRequired={false}
              endDateRequired={false}
            />
          )}
          {(
            evalDefaultDegree({
              candidate,
              education: history,
              degree: piece
            }) &&
            (
              <StyledTooltip
                title={
                  `This ${pieceKey} is the candidate's most relevant ${pieceKey}. You can select a different ${pieceKey} by editing the corresponding history`
                }
                placement='top-start'
                paperStyle={{ width: 320 }}
              >
                <Chip
                  className='c-black-medium py-0 ml-1'
                  size='small'
                  variant='outlined'
                  label='most relevant'
                  style={{ height: 16 }}
                />
              </StyledTooltip>
            )
          )}
          <small className='ml-auto'>
            {(
              piece.description &&
              !expanded &&
              !showFullDescription &&
              (index >= showDescriptionAfter) && <Info />
            )}
          </small>
        </div>
      )}
      {(
        piece.description &&
        (expanded || showFullDescription || (index < showDescriptionAfter)) && (
          <div className='d-flex flex-align-left-bottom'>
            <small className={`c-black-medium mr-1 ${(
              showFullDescription ? '' : 'line-clamp-2'
            )}`.trim()}>
              {piece.description}
            </small>
            {(piece.description.length >= 200) && <Info />}
          </div>
        )
      )}
    </div>
  )
}
