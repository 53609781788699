import {
  isString
} from 'lodash';
import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT,
  YES
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  compileText,
  Str,
  trim
} from '../../../lib/String.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  QUERY__MATCH_DECISION
} from '../../../lib/queries/MatchDecision.query';
import {
  COLLECTION__MATCH_DECISION_TABLES,
  createLoopbackRecord,
  readLoopbackRecord,
  updateLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import getEngagementFeedbackReasons from '../../Engagements/Libraries/getEngagementFeedbackReasons.fun';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Dialog from '../../Layout/Wrappers/Dialog';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import Once from '../../Layout/Wrappers/Once';
import TextField from '../../Layout/Wrappers/TextField';
import {
  isMatchDecisionNo,
  isMatchDecisionYes
} from '../Libraries/MatchDecisions.lib';

export const MATCH_DECISION_DETAILS__CONFIG = {
  name: 'match_decision_details',
  dialog: {
    title: 'Match decision details',
    onAcceptLabel: 'Save',
    noRecord: 'You have not entered a match decision for the candidate and job'
  },
  fieldLabels: {
    whyYesCategories: 'Pick reason from options',
    whyNoCategories: 'Pick reason from options',
    whyYesDetails: 'Why yes - shareable to recruiter',
    whyNoDetails: 'Why no - add to Rejection reason, sharable to recruiter',
    whyNoPrivateNote: 'Why no - private to 10x10',
    whyYesPrivateNote: 'Why yes - private to 10x10',
    shouldTag: 'Should tag - missed the tag',
    shouldNotTag: 'Bad tag - tagged incorrectly',
    whyNeedToReadCV: 'Why do you need to re-read CV again'
  }
};

export default function MatchDecisionDetails({
  acl = true,
  Controller = {},
  matchDecisionDetails = {},
  ...props
}) {

  const _defaultState = {
    record: {},
    open: false,
  };

  const [{
    record = {},
    open = false,
    ...state
  }, updateState] = useState(_defaultState);

  if (!acl) { return null; }

  Object.assign(matchDecisionDetails,
    {
      async open(event, options) {
        try {
          const record = Obj(await _fetchRecord());
          if (NOT(record.id)) {
            return Core.showError(MATCH_DECISION_DETAILS__CONFIG.dialog.noRecord);
          }
          await updateState({ open: true });
        }
        catch (error) {
          Core.showError(error);
        }
      },
      async close(event) {
        await updateState(_defaultState);
        Controller.handlerOnSelect();
      }
    }
  );
  Object.assign(Controller, { matchDecisionDetails });

  const {
    engagement = {},
  } = Controller.state;

  let {
    id,
    matchDecision = '',
    whyNoDetails = '',
    whyNoCategories = [],
    whyNoPrivateNote = '',
    whyYesPrivateNote = '',
    whyYesDetails = '',
    whyYesCategories = [],
    shouldTag = '',
    shouldNotTag = '',
    whyNeedToReadCV = '',
    reviewRequested = false
  } = record;

  let _isMatchDecisionYes = isMatchDecisionYes({ label: matchDecision });
  let _isMatchDecisionNo = isMatchDecisionNo({ label: matchDecision });

  const _updateRecord = (options, flag) => {
    let {
      key: fieldKey = '',
      trim: toTrim = false,
      value = 'value'
    } = Obj(options);
    if (isString(options)) {
      fieldKey = options;
    }
    toTrim = YES(flag || toTrim);
    return async (event) => {
      if (fieldKey === 'whyNoCategories') {
        Object.assign(record, { [fieldKey]: event });
      }
      else if (fieldKey === 'whyYesCategories') {
        Object.assign(record, { [fieldKey]: event });
      }
      else {
        Object.assign(record, {
          [fieldKey]: toTrim
            ? trim(event.target[value])
            : Str(event.target[value])
        });
      }
      await updateState({ record });
    };
  };

  const _fetchRecord = async () => {
    return readLoopbackRecord(
      compileText(
        QUERY__MATCH_DECISION,
        {
          ENGAGEMENT_ID: engagement.id,
          ANNOTATOR: Core.getUser().email
        }
      )
    ).then(async (record) => {
      await updateState({ record });
      return record;
    });
  };

  const _saveRecord = async () => {
    let model = {
      whyYesDetails,
      whyYesCategories,
      whyNoDetails,
      whyNoCategories,
      whyNoPrivateNote,
      whyYesPrivateNote,
      whyNeedToReadCV,
      shouldTag,
      shouldNotTag,
      reviewRequested
    };
    if (id) {
      return updateLoopbackRecord({
        collection: COLLECTION__MATCH_DECISION_TABLES,
        id,
        record,
        model
      });
    }
    else {
      return createLoopbackRecord({
        collection: COLLECTION__MATCH_DECISION_TABLES,
        record,
        model
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={matchDecisionDetails.close}
      name={MATCH_DECISION_DETAILS__CONFIG.name}
      title={MATCH_DECISION_DETAILS__CONFIG.dialog.title}
      content={
        <div className="flex-column w-100">
          <Once
            promise={async () => {
              updateState({
                engagementFeedbackReasons: await getEngagementFeedbackReasons({
                  jobId: engagement.jobId,
                })
              });
            }}
          />
          <Fieldset
            title={
              MATCH_DECISION_DETAILS__CONFIG.fieldLabels[
              _isMatchDecisionYes
                ? 'whyYesCategories'
                : _isMatchDecisionNo
                  ? 'whyNoCategories'
                  : ''
              ]
            }
          >
            <Menu multiple
              value={
                _isMatchDecisionYes
                  ? whyYesCategories
                  : _isMatchDecisionNo
                    ? whyNoCategories
                    : []
              }
              onChange={
                (
                  _isMatchDecisionYes
                ) ? (
                  _updateRecord('whyYesCategories')
                ) : (
                  _isMatchDecisionNo
                ) && (
                  _updateRecord('whyNoCategories')
                )
              }
              options={Arr(state.engagementFeedbackReasons)}
            />
          </Fieldset>

          {_isMatchDecisionYes && (
            <>
              <Fieldset
                title={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyYesDetails}
              >
                <TextField disabledBlurExport autoFocus
                  value={Str(whyYesDetails)}
                  onChange={_updateRecord('whyYesDetails')}
                  onBlur={_updateRecord('whyYesDetails', true)}
                  placeholder={''}
                  variant="standard"
                />
              </Fieldset>
              <Fieldset
                title={
                  MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyYesPrivateNote
                }
              >
                <TextField disabledBlurExport
                  value={Str(whyYesPrivateNote)}
                  onChange={_updateRecord('whyYesPrivateNote')}
                  onBlur={_updateRecord('whyYesPrivateNote', true)}
                  variant="standard"
                />
              </Fieldset>
            </>
          )}

          {_isMatchDecisionNo && (
            <>
              <Fieldset
                title={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyNoDetails}
              >
                <TextField disabledBlurExport autoFocus
                  value={Str(whyNoDetails)}
                  onChange={_updateRecord('whyNoDetails')}
                  onBlur={_updateRecord('whyNoDetails', true)}
                  placeholder={''}
                  variant="standard"
                />
              </Fieldset>
              <Fieldset
                title={
                  MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyNoPrivateNote
                }
              >
                <TextField disabledBlurExport
                  value={Str(whyNoPrivateNote)}
                  onChange={_updateRecord('whyNoPrivateNote')}
                  onBlur={_updateRecord('whyNoPrivateNote', true)}
                  variant="standard"
                />
              </Fieldset>
            </>
          )}

          <Fieldset
            title={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.shouldTag}
          >
            <TextField disabledBlurExport
              value={Str(shouldTag)}
              onChange={_updateRecord('shouldTag')}
              onBlur={_updateRecord('shouldTag', true)}
              variant="standard"
            />
          </Fieldset>

          <Fieldset
            title={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.shouldNotTag}
          >
            <TextField disabledBlurExport
              value={Str(shouldNotTag)}
              onChange={_updateRecord('shouldNotTag')}
              onBlur={_updateRecord('shouldNotTag', true)}
              variant="standard"
            />
          </Fieldset>

          <Fieldset
            title={MATCH_DECISION_DETAILS__CONFIG.fieldLabels.whyNeedToReadCV}
          >
            <TextField disabledBlurExport
              value={Str(whyNeedToReadCV)}
              onChange={_updateRecord('whyNeedToReadCV')}
              onBlur={_updateRecord('whyNeedToReadCV', true)}
              variant="standard"
            />
          </Fieldset>
        </div>
      }
      actionBar={
        <Checkbox
          checked={!!reviewRequested}
          onChange={_updateRecord({ key: 'reviewRequested', value: 'checked' })}
          labelProps={{ className: 'f-md c-black-medium' }}
        >
          Request review
        </Checkbox>
      }
      actions={[
        <Button outlined minW120>
          Cancel
        </Button>,
        <Button primary minW120
          onClick={_saveRecord}
        >
          {MATCH_DECISION_DETAILS__CONFIG.dialog.onAcceptLabel}
        </Button>
      ]}
      paperStyle={{ minWidth: sizes.l2 }}
    />
  );
}
