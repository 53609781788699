import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Definition from '../../../lib/Definition';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import Box from '../../Layout/Wrappers/Box';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import TextField from '../../Layout/Wrappers/TextField';
import { CandidateEditController } from '../Edit/CandidateEdit';
import WarningDifferentPlatformRating from './WarningDifferentPlatformRating';

export default function InputNotePlatformRating() {
  const [state, updateState] = useState();
  setInputNotePlatformRatingController({ state, updateState });
  const candidate = CandidateEditController().state;
  const calculatedPR = Candidate.calculatePlatformRating({ candidate });
  const recommendedPlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: calculatedPR
  });
  const candidatePlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: candidate.platformRating
  });
  return (
    <Box column>
      <WarningDifferentPlatformRating
        candidate={candidate}
        recommendedPlatformRatingTag={recommendedPlatformRatingTag}
        candidatePlatformRatingTag={candidatePlatformRatingTag}
      />
      <Fieldset
        title='Why disagree'
        wrapperProps={{ className: 'mt-05' }}
      >
        <TextField
          value={state.note}
          onChange={(event) => updateState({ note: event.target.value })}
          multiline
          autoFocus
        />
      </Fieldset>
    </Box>
  )
}

export function InputNotePlatformRatingController() {
  return Obj(Core.getKeyValue('InputNotePlatformRatingController'));
}

export function setInputNotePlatformRatingController(controller) {
  return Core.setKeyValue('InputNotePlatformRatingController', controller);
}
