import {
  Component
} from "react";
import AppUI from '../../../../dictionaries/AppUI.dic';
import Core from "../../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  ATS_TYPE__LEVER_API_ID,
  EMPLOYER_SUBMISSION_METHOD__ATS,
  EMP_MSG_TYPE__REMINDER_ID
} from "../../../../lib/Definition";
import {
  getEmployerAccounts
} from '../../../../lib/Employer';
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  Str
} from '../../../../lib/String.lib';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import {
  mapAccount
} from "../../../../lib/models/account";
import InternalSubmissionInstructions from '../../../Employers/Edit/Forms/InternalSubmissionInstructions';
import EmployerUI from '../../../Employers/EmployerUI.dic';
import Chips from "../../../Forms/Chips";
import {
  EmailsListClass
} from "../../../Forms/EmailsList";
import StepHeader from '../../../Forms/StepHeader';
import Box from '../../../Layout/Wrappers/Box';
import Checkbox from '../../../Layout/Wrappers/Checkbox';
import Col from "../../../Layout/Wrappers/Col";
import DatePicker from '../../../Layout/Wrappers/DatePicker';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import IconButton from '../../../Layout/Wrappers/IconButton';
import InputNumber from '../../../Layout/Wrappers/InputNumber';
import Menu from '../../../Layout/Wrappers/Menu';
import RadioGroup from '../../../Layout/Wrappers/RadioGroup';
import RichTextBox from "../../../Layout/Wrappers/RichTextBox";
import Row from "../../../Layout/Wrappers/Row";
import TextField from '../../../Layout/Wrappers/TextField';
import {
  Label
} from '../../../Layout/Wrappers/Typography';
import {
  PrescreenQuestionsEdit
} from '../../../PrescreenQuestions/PrescreenQuestionsSection';
import {
  JOB_SECTION__INTERVIEW_PROCESSES,
  JOB_SECTION__PUBLIC_NOTES,
  JOB_SECTION__SUMMARY,
  openJobView
} from '../../Card/JobDetails';
import FieldsetEngagementFeedbackReasons from '../Forms/FieldsetEngagementFeedbackReasons';

class Process extends Component {
  render() {
    const JobEditController = this.props.parent;

    let {
      state = {}
    } = JobEditController;
    const {
      atsJobId,
      employer = {},
      submissionMethods = [],
      atsContext = {},
      __accounts = {}
    } = state;
    const {
      atsTypeId
    } = employer;
    const { postId } = Obj(atsContext[atsTypeId]);
    const isAtsLeverApi = (atsTypeId === ATS_TYPE__LEVER_API_ID);
    const isAtsAvailable = (
      submissionMethods.includes(EMPLOYER_SUBMISSION_METHOD__ATS) &&
      (
        (atsTypeId === ATS_TYPE__GH_HARVEST_ID) ||
        (atsTypeId === ATS_TYPE__GH_INGESTION_ID) ||
        isAtsLeverApi
      )
    );

    const updateState = async update => {
      return new Promise(resolve => {
        JobEditController.setState(prevState => {
          resolve();
          return { ...prevState, ...update };
        });
      });
    }

    const fetchAccounts = async () => {
      __accounts.error = false;
      __accounts.fetching = true;
      __accounts.fetched = false;
      await updateState({ __accounts });
      __accounts.list = await getEmployerAccounts({ employerId: employer.id }).catch(Core.showError);
      if (Array.isArray(__accounts.list)) {
        __accounts.error = false;
        __accounts.fetching = false;
        __accounts.fetched = true;
      }
      else {
        __accounts.error = true;
        __accounts.fetching = false;
        __accounts.fetched = false;
      }
      employer.__accounts = __accounts;
      await updateState({ __accounts, employer });
    }

    if (state.id && !__accounts.error && !__accounts.fetching && !__accounts.fetched) {
      fetchAccounts();
    }

    return (
      <Box column flex1>

        <StepHeader {...this.props} />

        <Row>
          <Col>
            <Chips
              name="employerSubmissionMethod"
              label={<label>Submission Method(s)</label>}
              sub="Please select all the submission methods your are interested in"
              values={JobEditController.state.submissionMethods || []}
              onChange={(submissionMethods) => {
                JobEditController.setState({ submissionMethods });
              }}
            />
            {isAtsAvailable && (
              isAtsLeverApi ? (
                <>
                  <label className='tt-unset'>ATS posting id</label>
                  <small className="c-black-medium">
                    The ID of the posting you want to apply for this candidate
                  </small>
                  <TextField
                    name='postId'
                    type='text'
                    value={postId}
                    onChange={(ev, postId) => JobEditController.setState(state => {
                      state.atsContext = Obj(state.atsContext);
                      state.atsContext[atsTypeId] = Obj(state.atsContext[atsTypeId]);
                      state.atsContext[atsTypeId].postId = Str(postId);
                      return state;
                    })}
                    fullWidth
                  />
                </>
              ) : (
                <>
                  <label className='tt-unset'>ATS job ID</label>
                  <small className="c-black-medium">
                    The ID of the job you want to create an application to for this candidate
                  </small>
                  <InputNumber
                    name="atsJobId"
                    type="number"
                    value={atsJobId}
                    onChange={(atsJobId) => JobEditController.setState({ atsJobId })}
                  />
                </>
              )
            )}
          </Col>
          <Col>
            <label>Submission Method (obsolete)</label>
            <Menu
              name="submissionMethod"
              value={parseInt(JobEditController.state.submissionMethod)}
              onChange={(submissionMethod) =>
                JobEditController.setState({ submissionMethod })
              }
              fullWidth
              options={Definition.get("employerSubmissionMethod")}
            />
          </Col>
        </Row>

        <FieldsetEngagementFeedbackReasons />

        <InternalSubmissionInstructions
          title={
            <>
              Employer:
              &nbsp;
              {EmployerUI.internalSubmissionInstructions.title}
            </>
          }
          entity={JobEditController.state.employer}
          onChange={
            async (update) => new Promise((resolve) =>
              JobEditController.setState({
                employer: Object.assign(
                  JobEditController.state.employer,
                  update
                )
              }, resolve)
            )
          }
          autoSave={false}
        />

        <InternalSubmissionInstructions
          title={
            <>
              Job:
              &nbsp;
              {EmployerUI.internalSubmissionInstructions.title}
            </>
          }
          entity={JobEditController.state}
          onChange={
            async (update) => new Promise((resolve) =>
              JobEditController.setState(
                update,
                resolve
              )
            )
          }
          autoSave={false}
        />

        <PrescreenQuestionsEdit
          Controller={JobEditController}
          className='mt-3'
        />

        <Fieldset
          title={
            <>Employer Generic Interview Process</>
          }
          subtitle=''
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: JobEditController.state.id,
              sectionName: JOB_SECTION__INTERVIEW_PROCESSES
            })
          }}
        >
          <RichTextBox
            name="interviewProcess"
            value={JobEditController.state.employer.interviewProcess}
            disabled
          />
        </Fieldset>

        <Fieldset
          title={
            <>Job Specific Interview Process</>
          }
          subtitle={
            <i>Employer's generic interview process is shown above. The job-specific interview process is detailed here</i>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: JobEditController.state.id,
              sectionName: JOB_SECTION__INTERVIEW_PROCESSES
            })
          }}
        >
          <RichTextBox
            name="jobInterviewProcess"
            value={JobEditController.state.jobInterviewProcess}
            onChange={jobInterviewProcess => {
              JobEditController.setState({
                jobInterviewProcess,
              });
            }}
          />
        </Fieldset>

        <Fieldset
          title='Public Notes'
          subtitle=''
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: JobEditController.state.id,
              sectionName: JOB_SECTION__PUBLIC_NOTES
            })
          }}
        >
          <RichTextBox
            name="publicNotes"
            value={JobEditController.state.publicNotes}
            onChange={publicNotes => {
              JobEditController.setState({
                publicNotes,
              });
            }}
          />
        </Fieldset>

        <Box>
          <EmailsListClass
            name={'jobs_forms_process__employer_contacts'}
            title={<Label className='tt-unset tt-none'>Employer contacts</Label>}
            subtitle={
              <>
                Email notification subscription for {JobEditController.state.employer?.name}
              </>
            }
            model={employer}
            onSave={update => {
              JobEditController.setState({ employer: { ...employer, ...update }, saveEmployer: true });
            }}
            messageTypeTags={Definition.get('employerMessageType').filter(({ id }) => id === EMP_MSG_TYPE__REMINDER_ID)}
            actionBar={
              <IconButton icon='open_in_new'
                title='Edit Employer'
                acl={Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER })}
                onClick={event => Core.openPopUp(`/#/employer/edit/${JobEditController.state.employer?.id}`, 1600)}
              />
            }
          />
          <EmailsListClass
            name={'jobs_forms_process__job_contacts'}
            title={<Label className='tt-unset tt-none'>Job contacts</Label>}
            subtitle={'Email notification subscription'}
            model={JobEditController.state}
            onSave={update => JobEditController.setState(update)}
            messageTypeTags={Definition.get('employerMessageType')}
            disabledExclude
          />
        </Box>

        <Box column
          className='mt-3 px-1'
        >
          <Box column
            className="outlined rounded-sm bg-green-lighter p-1"
          >

            <Row>
              <Col>
                <h4>For 10 by 10 use only</h4>
              </Col>
            </Row>

            <Row>
              <Fieldset
                title={
                  <>Current State</>
                }
                info={AppUI.publicInfo.tooltip}
                infoIconName={MATERIAL_ICON_NAME__EYE}
                infoProps={{
                  onClick: openJobView({
                    jobId: JobEditController.state.id,
                    sectionName: JOB_SECTION__SUMMARY
                  })
                }}
                fullWidth={false}
              >
                <RadioGroup column
                  name="current_state"
                  options={Definition.get("state")
                    .filter((tag) =>
                      Core.isAdminOrCoordinator()
                        ? true
                        : Definition.test("state", tag.id, /draft/)
                    )}
                  value={JobEditController.state.state}
                  onChange={(event, state) => JobEditController.setState({ state })}
                />
              </Fieldset>
              <Fieldset
                title={
                  <>Flags</>
                }
                fullWidth={false}
              >
                <Col>
                  {Definition.get("flags").map((tag) => (
                    <Checkbox
                      key={tag.id}
                      label={tag.label}
                      style={{ margin: "16px 0" }}
                      checked={!!~JobEditController.state.flags.indexOf(tag.id)}
                      onCheck={(ev, checked) => {
                        JobEditController.setState((state) => {
                          state = { flags: JobEditController.state.flags };
                          if (checked) {
                            if (!state.flags.find((id) => id === tag.id)) {
                              state.flags.push(tag.id);
                            }
                          } else {
                            state.flags = state.flags.filter((id) => id !== tag.id);
                          }
                          Core.log({ state });
                          return state;
                        });
                      }}
                    />
                  ))}
                </Col>
              </Fieldset>
            </Row>

            <Row>
              <Fieldset
                title={
                  <>Placement Fee</>
                }
              >
                <InputNumber
                  name="placementFee"
                  max={100}
                  maxLength={5}
                  value={JobEditController.state.placementFee}
                  onChange={(placementFee) =>
                    JobEditController.setState({
                      placementFee: Number(
                        placementFee > 100
                          ? 100
                          : String(placementFee).slice(0, 5)
                      ),
                    })
                  }
                  units='%'
                />
              </Fieldset>
              <Fieldset
                title={
                  <>Guarantee</>
                }
              >
                <InputNumber
                  name="guarantee"
                  maxLength={3}
                  value={JobEditController.state.guarantee}
                  onChange={(guarantee) =>
                    JobEditController.setState({
                      guarantee:
                        guarantee > 999
                          ? 999
                          : String(Math.round(guarantee)).slice(0, 3),
                    })
                  }
                  units='days'
                />
              </Fieldset>
            </Row>

            <Row className='mt-3'>
              <Col fullWidth>
                <label>Additional Contract Information</label>
                <TextField
                  name="additionalContractInfo"
                  type="text"
                  rows={1}
                  rowsMax={3}
                  value={JobEditController.state.additionalContractInfo}
                  onChange={(ev, additionalContractInfo) =>
                    JobEditController.setState({
                      additionalContractInfo,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col fullWidth>
                <label>Default Resume Submission Template</label>
                <Menu
                  name="emailTemplates"
                  value={JobEditController.state.resumeSubmissionEmailTemplateId}
                  onChange={(resumeSubmissionEmailTemplateId) => {
                    JobEditController.setState({ resumeSubmissionEmailTemplateId });
                  }}
                  fullWidth
                  options={JobEditController.state.emailTemplates}
                />
              </Col>
            </Row>
            <Row className="edit-account-list-row">
              {!!JobEditController.state.employer &&
                JobEditController.state.employer.employerSourceList && (
                  <Col className="edit-account-list-col rounded">
                    <label>Employer Source List</label>
                    {JobEditController.state.employer.employerSourceList.map((item) => {
                      item = mapAccount(item);
                      return (
                        <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                      );
                    })}
                  </Col>
                )}
              {!!JobEditController.state.jobSourceList && (
                <Col className="edit-account-list-col rounded">
                  <label>Job Source List</label>
                  {JobEditController.state.jobSourceList.map((item) => {
                    item = mapAccount(item);
                    return <li key={item.id}>{item._name || <i>&mdash;</i>}</li>;
                  })}
                </Col>
              )}
            </Row>
            <Row className='mt-3'>
              <Col fullWidth>
                <label>Private Notes</label>
                <RichTextBox
                  name="privateNotes"
                  value={JobEditController.state.privateNotes}
                  onChange={privateNotes => {
                    JobEditController.setState({
                      privateNotes,
                    });
                  }}
                />
              </Col>
            </Row>
          </Box>
        </Box>

        <Row className='mt-3'>
          <Col>
            <label>Creation Date</label>
            <DatePicker
              name="createdAt"
              value={
                JobEditController.state.createdAt ? new Date(JobEditController.state.createdAt) : null
              }
              onChange={(createdAt) => {
                JobEditController.setState({ createdAt: createdAt.toISOString() });
              }}
            />
          </Col>
          <Col>
            <label>Last Update</label>
            <DatePicker
              name="updatedAt"
              value={
                JobEditController.state.updatedAt ? new Date(JobEditController.state.updatedAt) : null
              }
              onChange={(updatedAt) => {
                JobEditController.setState({ updatedAt: updatedAt.toISOString() });
              }}
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <label>Closed Date</label>
            <DatePicker
              name="closeDate"
              value={
                JobEditController.state.closeDate ? new Date(JobEditController.state.closeDate) : null
              }
              onChange={(closeDate) => {
                JobEditController.setState({ closeDate: closeDate.toISOString() });
              }}
            />
          </Col>
        </Row>
      </Box>
    );
  }
}

export default Process;
