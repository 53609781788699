import {
  Icon,
  CardContent as MuiCardContent,
  Typography
} from "@mui/material";
import Divider from './Divider';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function CardContent({
  icon,
  title = '',
  subtitle = '',
  children = '',
  className,
  style,
}) {
  return (
    <>
      <MuiCardContent className={className} style={style}>
        <div className="d-flex flex-align-left-center">
          {icon && <Icon className="mr-1">{icon}</Icon>}
          <Typography gutterBottom variant="h6" className="text-uppercase c-black-darker m-0 f-md">
            {title}
          </Typography>
        </div>
        {!!subtitle && (
          <div className='d-flex flex-align-left-center f-sm c-black-medium'>
            {subtitle}
          </div>
        )}
        <Typography variant="body2" component="div" className="c-black-medium pt-1 mt-1">
          {children}
        </Typography>
      </MuiCardContent>
      <Divider />
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { CardContent, CardContent as default };

/* ============================================ */
