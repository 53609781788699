import {
  compile
} from 'handlebars';
import moment from "moment";
import {
  Component
} from "react";
import Box from '../../components/Layout/Wrappers/Box';
import Button from '../../components/Layout/Wrappers/Button';
import NavLink from '../../components/Layout/Wrappers/NavLink';
import {
  ENGAGEMENT__INFO_STATUSES,
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  REJECTION_REASON__CANDIDATE__NOT_INTERESTED,
  STAGE_CONFIRMATION,
  STATUS_E_CANDIDATE,
  STATUS_H_10X10,
  STATUS_H_CANDIDATE,
  STATUS_H_EMPLOYER,
  STATUS_W_10X10,
  STATUS_W_CANDIDATE,
  STATUS_W_CANDIDATE_PERMISSION,
  STATUS_W_EMPLOYER,
  STATUS_W_EMPLOYER_FEEDBACK,
  STATUS_W_ONSITE,
  STATUS_W_SCREEN
} from '../../dictionaries/Engagement.dic';
import {
  join
} from '../Array.lib';
import {
  FULLDAY_MILL_SECOND_REFERENCE,
  FULLDAY_SECOND_REFERENCE
} from "../Constants";
import Core from "../Core";
import {
  DATE_FORMAT__DATE__US_LONG,
  isValidDate
} from '../Date.lib';
import Definition, {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_JOB,
  ATS_TYPE__NONE_ID,
  MATCH_TYPE__ADMIN_BACK_MATCH
} from "../Definition";
import EngagementLib from "../Engagement";
import {
  newModel,
  NOT
} from "../GenericTools.lib";
import {
  Obj
} from '../Object.lib';
import {
  COLLECTION__CANDIDATES,
  COLLECTION__JOBS,
  readLoopbackRecord
} from '../services/BE/loopback.api';
import {
  trim
} from '../String.lib';
import {
  reloadLocation
} from '../URL.lib';
import {
  mapAccount
} from "./account";
import {
  mapCandidate
} from "./candidate";
import {
  EmployerCareersPage,
  mapEmployer
} from "./employer";
import getHoldModel from './hold.model';
import {
  mapJob
} from "./job";
import {
  mapStarred
} from "./mapStarred.tool";

const MODEL_NAME = 'Engagement';

const model = {
  status: "",
  stage: "",
  state: "",
  boxKey: "",

  /**
   * Greenhouse Application ID (number)
   *
   * Application is similar to engagement
   *
   * Expecting information to obtain with this ID
   * - stage
   * - status
   * - scheduled interview dates
   * - rejection reason
   * - rejection notes
   *
   * @see CandidateResumeSubmission UI Component
   * - render => onClickSendATS
   */
  greenhouseAtsApplicationId: null,

  /** foreign keys */
  jobId: "",
  candidateId: "",
  actionOwnerId: "",

  /** mixin timestamps */
  createdAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",
  updatedAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",

  lastMatchedDate: null, // date
  introduced: null, // date
  matched: null, // date
  lastAction: null, // date

  confirmed: null, // date
  submitted: null, // date, this should be set when stage transition to review?
  reviewed: null, // date, this should be set when stage transition to screen
  screened: null, // date, this should be set when stage transition to onsite
  onsite: null, // date, this should be set when stage transition to offered?
  offered: null, // date, this should be set when stage transition to hired?
  hired: null, // date
  startDate: null, // date
  guaranteeDate: null, // date
  holdDate: null, // date

  screen1: null, // date
  screen2: null, // date
  screen3: null, // date

  onsite1: null, // date
  onsite2: null, // date

  // {Date} [2023-08-22][story_9536]
  homeworkAssigned: null,

  // {Date} [2023-08-22][story_9536]
  homeworkCompleted: null,

  closed: null, // date
  rejectionReason: null, // string?
  rejectionReasonAdditionalInfo: null, // string?
  shouldTag: null,
  shouldNotTag: null,
  whyNoPrivateNote: null,
  whyNeedToReadCV: null,
  overdueDate: null,
  followedUpCount: null, // number?
  nextAction: null,

  matchStrength: null, // tagId(engagementMatchStrength)
  matchedByWho: null, // account(user)

  hold: null,

  /**
   * {number}
   * "
   * A) Match Type:
   * // new attribute. use integer in DB, but code should be readable name
   * // please include comments in the code
   * // use tag management to make understanding tag faster
   * "
   *
   * @see setMatchFlagForAdditionalActions
   * @see setMatchFlagForBackMatch
   * @see story 2572
   */
  matchType: null,

  /**
   * {number}
   * "
   * B) only applicable if type is 10x10_back_match
   * // allow us to analyze how far should we back match later
   *    backMatchDays : Today's date - Candidate updatedAt (days)
   * "
   *
   * @see setMatchFlagForBackMatch
   * @see story 2572
   */
  backMatchDays: null,

  open: true,
  jobAnswers: [],
  useForMlTraining: null,

  /** @todo create a model for this structure */
  reminderSentDates: {}, // reminderSentDates object

  statusUpdatedDate: null, // date

  statusNote: null, // string

  submissionNote: null, // string

  // [ 2022-11-17 ]
  // {number} - look for schedulingReminderCount in BE
  // this being updated when status change and when schedule reminder is sent
  schedulingReminderCount: 0,

  // {number}
  probScore: null,

  // {object}
  matchDetails: {},

  // {object} : { [PROVIDER_TAG_ID]: { [VARIABLE_NAME]: {{ANY}} } } – story_9481
  atsContext: {},

  /** 
   * @prop {string} empFBText  [multiline][plaintext]
   */
  empFBText: '',

  /** 
   * @prop {string} toRecruiterFBText  [multiline][plaintext]
   */
  toRecruiterFBText: '',

  /** @prop {array} feedback  [{reasons:[number:tagId,...],...}] – Look for MODEL__ENGAGEMENT__FEEDBACK */
  feedback: [],

};

const extended = {
  ___model___: MODEL_NAME,
  modelName: 'engagement',
  id: null,
  _id: null,
  ...model,
  /** nested objects */
  job: {},
  candidate: {},
  recruiter: {},
  employer: {},
  actionOwner: {},
  history: [],
  ___keys___: []
};

const mdash = "—";

function mapHold({ engagement }) {
  if (!!engagement.holdDate) {
    engagement.hold = engagement.hold || getHoldModel();
    if (!engagement.hold.endDate) {
      engagement.hold.endDate = engagement.holdDate;
    }
  }
}

const mapEngagement = item => {
  const engagement = getEngagementModel({ extended: true });
  if (!!item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (engagement[key] = item[key])
    );
    engagement.id = engagement.id || engagement._id;

    if (
      NOT(
        ENGAGEMENT__INFO_STATUSES.includes(engagement.status)
      )
    ) {
      engagement.statusNote = '';
    }


    engagement._createdAt = engagement.createdAt ? moment(engagement.createdAt).format(DATE_FORMAT__DATE__US_LONG) : mdash;
    engagement._updatedAt = engagement.updatedAt ? moment(engagement.updatedAt).format(DATE_FORMAT__DATE__US_LONG) : mdash;

    mapStarred({ model: engagement, starredList: (item.engagementStarreds || []) });
    mapHold({ engagement });

    engagement.candidate = mapCandidate(item.candidate || item.Candidate);
    engagement.recruiter = engagement.candidate.recruiter;
    engagement.job = mapJob(item.job || item.Job);
    engagement.employer = engagement.job.employer || mapEmployer(engagement.Employer);

    engagement.actionOwner = mapAccount(item.actionOwner);
    engagement._actionOwner = engagement.actionOwner.email || mdash;

    engagement.matchedByWho = mapAccount(item.matchedByWho);
    engagement._matchedByWho = engagement.matchedByWho.email || mdash;

    engagement._backMatchDays = engagement.backMatchDays || mdash;
    engagement._followedUpCount = engagement.followedUpCount || mdash;
    engagement._nextAction = engagement.nextAction || mdash;
    engagement._rejectionReason = engagement.rejectionReason || mdash;

    engagement.history = engagement.engagementHistories || [];
    engagement.lastAction = engagement.lastAction || engagement.updatedAt;

    Definition.set(engagement, 'matchType');
    engagement._matchType = engagement._matchType || mdash;

    Definition.set(engagement, 'engagementMatchStrength', 'matchStrength');
    engagement._matchStrength = engagement._matchStrength || mdash;

    engagement._starred = engagement.starred
      ? "Starred: True"
      : "Starred: False";

    engagement._name = `${engagement.candidate._name_rating ||
      ""} - ${engagement.employer._name_rating || ""}`;

    engagement._careersPage = <EmployerCareersPage employer={engagement.job.employer} />;

    if (
      engagement.stage === STAGE_CONFIRMATION &&
      engagement.status === STATUS_W_CANDIDATE_PERMISSION &&
      engagement.state === ENGAGEMENT__STATE_OPEN &&
      engagement.candidate.jobsDeclined.includes(engagement.jobId)
    ) {
      EngagementLib.update(engagement, {
        state: 'Closed',
        status: STATUS_E_CANDIDATE,
        rejectionReason: REJECTION_REASON__CANDIDATE__NOT_INTERESTED
      }, Core.refresh);
    }

    engagement._lastAction = moment(engagement.lastAction).format(DATE_FORMAT__DATE__US_LONG);
    engagement._role = Definition.getLabel("roles", engagement.job.role) || mdash;
    engagement._roles = engagement.job._roles;
    engagement._agencyName = engagement.recruiter.companyName || mdash;
    engagement._recruiterName = `${engagement.recruiter.firstName} ${engagement.recruiter.lastName}`;
    engagement._candidateName = engagement.candidate._name;
    engagement._employerName = engagement.job.employer.name;
    engagement._recruiterTag = engagement.recruiter
      ? `${engagement._agencyName} - ${engagement._recruiterName}`
      : mdash;
    engagement._jobTag = `${engagement.job.jobTitle ||
      ""} - ${engagement._role || ""}`;

    engagement._introduced = moment(engagement.introduced).format(DATE_FORMAT__DATE__US_LONG);
    engagement._matched = moment(engagement.matched).format(DATE_FORMAT__DATE__US_LONG);

    engagement._confirmed = engagement.confirmed
      ? moment(engagement.confirmed).format(DATE_FORMAT__DATE__US_LONG)
      : mdash;
    engagement._submitted = engagement.submitted
      ? moment(engagement.submitted).format(DATE_FORMAT__DATE__US_LONG)
      : mdash;
    engagement._reviewed = engagement.reviewed
      ? moment(engagement.reviewed).format(DATE_FORMAT__DATE__US_LONG)
      : mdash;

    engagement._screened = engagement.screened ? moment(engagement.screened).format(DATE_FORMAT__DATE__US_LONG) : mdash;
    engagement._onsite = engagement.onsite ? moment(engagement.onsite).format(DATE_FORMAT__DATE__US_LONG) : mdash;
    engagement._hired = engagement.hired ? moment(engagement.hired).format(DATE_FORMAT__DATE__US_LONG) : mdash;
    engagement._startDate = engagement.startDate ? moment(engagement.startDate).format(DATE_FORMAT__DATE__US_LONG) : mdash;
    engagement._guaranteeDate = engagement.guaranteeDate ? moment(engagement.guaranteeDate).format(DATE_FORMAT__DATE__US_LONG) : mdash;
    engagement._holdDate = engagement.holdDate ? moment(engagement.holdDate).format(DATE_FORMAT__DATE__US_LONG) : mdash;
    engagement._overdueDate = engagement.overdueDate ? moment(engagement.overdueDate).format(DATE_FORMAT__DATE__US_LONG) : mdash;

    mapEngagementInterviewDates({ engagement });

    engagement._candidatePlatformRating = engagement.candidate._platformRating;

    engagement._col1Cmp = <Column1 engagement={engagement} />;

    engagement.openDetails = async event => {
      let { candidate, job } = engagement;
      candidate = Obj(candidate);
      job = Obj(job);
      candidate = !!candidate.id ? candidate : await readLoopbackRecord({
        collection: COLLECTION__CANDIDATES,
        where: { id: engagement.candidateId },
        fields: ['id', 'firstName', 'lastName', 'accountId'],
        include: [
          {
            relation: 'account',
            scope: { fields: ['id', 'firstName', 'lastName'] }
          }
        ],
        limit: 1,
        mapper: mapCandidate,
      });
      job = !!engagement.job?.id ? engagement.job : await readLoopbackRecord({
        collection: COLLECTION__JOBS,
        where: { id: engagement.jobId },
        fields: ['id', 'jobTitle', 'roles', 'employerId'],
        include: [
          {
            relation: 'employer',
            scope: { fields: ['id', 'jobTitle'] }
          }
        ],
        limit: 1,
        mapper: mapJob,
      });
      console.debug('engagement.openDetails', { candidate, job });
      Core.openDrawer({
        style: {
          width: '80vw',
          maxWidth: "calc(100vw - var(--containerMinMargin))",
        },
        content: (
          <Box column flex1>
            {candidate.getEngagementDetails({ isDrawer: true })}
            <Box className='bg-main' >&nbsp;</Box>
            {job.getEngagementDetails()}
          </Box>
        )
      });
    };

    /* for autocomplete */
    engagement.___keys___ = [
      engagement.employer.name,
      engagement.job.jobTitle,
      engagement.candidate._name,
      ...(engagement.candidate?._contactNames || []),
      engagement.recruiter._name,
      engagement.recruiter.companyName,
      engagement.stage,
      engagement._role,
      engagement._starred,
      engagement.matchStrength,
      engagement.matchedByWho.email,
      engagement.lastMatchedDate
    ]
      /* combine and split values */
      .join("; ")
      .split("; ")
      /* remove empty values */
      .filter(e => !!e && String(e).trim());
  }

  engagement.__refetch = async () => {
    EngagementLib.cleanCache();
    return Object.assign(engagement, await EngagementLib.get(engagement.id));
  }

  return engagement;
};

const mapEngagements = data => {
  return (data || []).map(item => {
    const engagement = mapEngagement(item);
    engagement.filters = mapFilters(engagement);
    return engagement;
  });
};

export function mapEngagementInterviewDates({ engagement }) {

  const isFullDay = (date) => {
    date = new Date(date);
    return (
      (date.getSeconds() === FULLDAY_SECOND_REFERENCE)
      &&
      (date.getMilliseconds() === FULLDAY_MILL_SECOND_REFERENCE)
    );
  };

  engagement._screen1IsFullday = isFullDay(engagement.screen1);
  engagement._screen2IsFullday = isFullDay(engagement.screen2);
  engagement._screen3IsFullday = isFullDay(engagement.screen3);

  engagement._onsite1IsFullday = isFullDay(engagement.onsite1);
  engagement._onsite2IsFullday = isFullDay(engagement.onsite2);

  const formatDate = (date, isFullday = false) => {
    return isFullday ? moment(date).format("MM/DD/YYYY") : moment(date).format("MM/DD/YYYY hh:mm A")
  };

  engagement._screen1 = engagement.screen1
    ? formatDate(engagement.screen1, engagement._screen1IsFullday)
    : mdash;
  engagement._screen2 = engagement.screen2
    ? formatDate(engagement.screen2, engagement._screen2IsFullday)
    : mdash;
  engagement._screen3 = engagement.screen3
    ? formatDate(engagement.screen3, engagement._screen3IsFullday)
    : mdash;
  engagement._onsite1 = engagement.onsite1
    ? formatDate(engagement.onsite1, engagement._onsite1IsFullday)
    : mdash;
  engagement._onsite2 = engagement.onsite2
    ? formatDate(engagement.onsite2, engagement._onsite2IsFullday)
    : mdash;

  engagement._offered = engagement.offered
    ? formatDate(engagement.offered)
    : mdash;

}

function mapFilters(engagement) {
  const filters = {};
  function candidateTodo() {
    filters["Candidate Todo"] = false;
    if (
      new RegExp(
        join(
          [
            STATUS_W_CANDIDATE,
            STATUS_H_CANDIDATE
          ]
          , '|'
        ), 'i'
      ).test(engagement.status) && engagement.statusUpdatedDate
    ) {
      filters["Candidate Todo"] = engagement.statusUpdatedDate;
    }
  }
  function employerTodo() {
    filters["Employer Todo"] = false;
    if (
      new RegExp(
        join(
          [
            STATUS_W_EMPLOYER,
            STATUS_H_EMPLOYER
          ]
          , '|'
        ), 'i'
      ).test(engagement.status) && engagement.statusUpdatedDate
    ) {
      filters["Employer Todo"] = engagement.statusUpdatedDate;
    }
  }
  function tenByTenTodo() {
    filters["10x10 Todo"] = false;
    if (
      new RegExp(
        join(
          [
            STATUS_W_10X10,
            STATUS_H_10X10
          ]
          , '|'
        ), 'i'
      ).test(engagement.status) && engagement.statusUpdatedDate
    ) {
      filters["10x10 Todo"] = engagement.statusUpdatedDate;
    }
  }
  // W-Screen/Onsite date <= now and we're an admin or coordinator, set status = W-Employer-Feedback
  if (Core.isAdminOrCoordinator()) {
    setEventStatus(engagement);
  }
  candidateTodo();
  employerTodo();
  tenByTenTodo();
  filters.Candidate = engagement.candidate.lastName;
  filters.Recruiter = engagement.recruiter.lastName;
  filters.Company = engagement.employer.name;
  filters.Stage = engagement.stage;
  filters.Starred = engagement.starred === true ? "Starred" : "Non Starred";
  filters.Closed = engagement.open === false || engagement.stage === "End";
  engagement._overdueDays =
    engagement.overdueDate && moment(engagement.overdueDate).fromNow();
  engagement._isOverdue = moment(engagement.overdueDate) <= moment();
  return filters;
}

export function mapAtsContextNone(engagement) {
  engagement = Obj(engagement);
  engagement.atsContext = Obj(engagement.atsContext);
  engagement.atsContext[ATS_TYPE__NONE_ID] = Obj(
    engagement.atsContext[ATS_TYPE__NONE_ID]
  );
  return engagement;
}

export function getEmpAtsUrl(engagement) {
  engagement = mapAtsContextNone(engagement);
  return trim(engagement.atsContext[ATS_TYPE__NONE_ID].empAtsUrl);
}

function setEventStatus(engagement) {
  // Get event date.
  const date = (
    ((engagement.status === STATUS_W_SCREEN) && (engagement.screen3 || engagement.screen2 || engagement.screen1)) ||
    ((engagement.status === STATUS_W_ONSITE) && (engagement.onsite2 || engagement.onsite1))
  );
  if (
    [STATUS_W_SCREEN, STATUS_W_ONSITE].includes(engagement.status) && date && (moment() >= moment(date))
  ) {
    engagement.status = STATUS_W_EMPLOYER_FEEDBACK;
    EngagementLib.update(
      engagement,
      { status: engagement.status },
      response => {
        Core.showMessage(
          <div className="d-flex flex-column">
            <span>
              {`${engagement._name}, ${engagement.stage} status changed to ${engagement.status}`}
              &nbsp;&nbsp;&nbsp;
            </span>
            <Button
              title='Reload entire page'
              variant='outlined'
              className='ml-auto'
              onClick={ev => reloadLocation()}
            >
              Reload
            </Button>
          </div>
        );
      }
    );
  }
}

function getOverdue(stringDate) {
  if (!stringDate) {
    return "";
  }
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s, overdue",
      past: "%s overdue"
    }
  });
  return moment(stringDate).fromNow();
}

function hoverDisplay(engagement, htmlElement = false) {
  const TEMPLATE = `{{STATUS}} | {{STATUS_UPDATED_DATE}}{{MATCH_TYPE}}{{STATUS_NOTE}}{{REJECTION_REASON}}{{REJECTION_REASON_ADDITIONAL_INFO}}`;
  let result = compile(TEMPLATE)({
    STATUS: engagement.status,
    STATUS_UPDATED_DATE: (isValidDate(engagement.statusUpdatedDate)
      ? moment(engagement.statusUpdatedDate).format(DATE_FORMAT__DATE__US_LONG)
      : 'No status updated date'
    ),
    MATCH_TYPE: ((engagement.matchType === MATCH_TYPE__ADMIN_BACK_MATCH) && engagement.backMatchDays
      ? ` | ${engagement.backMatchDays} days`
      : ''
    ),
    STATUS_NOTE: ((engagement.statusNote && Core.isAdmin())
      ? `\nStatus note: ${engagement.statusNote}`
      : ''
    ),
    REJECTION_REASON: (
      (engagement.state === ENGAGEMENT__STATE_CLOSED)
        ? `\n\nRejection Reason: ${engagement.rejectionReason || 'Unknown rejection reason'}`
        : ''
    ),
    REJECTION_REASON_ADDITIONAL_INFO: (
      (
        (
          engagement.rejectionReasonAdditionalInfo &&
          (engagement.rejectionReason !== engagement.rejectionReasonAdditionalInfo)
        ) &&
        Core.isAdmin()
      )
        ? `\n${engagement.rejectionReasonAdditionalInfo}`
        : '')
  });
  if (htmlElement) {
    result = (
      <pre className='m-0' dangerouslySetInnerHTML={{ __html: result.replace(/\n/g, '<br/>') }} />
    );
  }
  return result;
}
const listTabs = [
  "Stage",
  "Candidate Todo",
  "10x10 Todo",
  "Employer Todo",
  "Closed",
  "Recruiter",
  "Company",
  "Candidate",
  "Starred"
];
const listTab = "Stage";

const Engagement = {
  extendedModel: extended,
  model,
  extended,
  mapEngagement,
  mapEngagements,
  mapFilters,
  getOverdue,
  listTabs,
  listTab,
  hoverDisplay
};

class Column1 extends Component {
  render() {
    const { engagement } = this.props;
    return (
      <div
        onClick={this.props.onClick}
        className="first-item blocks cursor-default"
        style={{ minWidth: 320, maxWidth: 320 }}
        colSpan={3}
      >
        {!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }) ? (
          <NavLink
            title="Candidate - Employer (Employer Stage) | Go to edit Candidate"
            to={"/candidate/edit/" + engagement.candidateId}
          >
            <b>{engagement._name}</b>
          </NavLink>
        ) : (
          <b>{engagement._name}</b>
        )}
        {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
          <NavLink
            title="Job Title - Role | Go to edit Job"
            to={"/job/edit/" + engagement.jobId}
          >
            {engagement._jobTag}
          </NavLink>
        ) : (
          <span title="Job Title - Role">{engagement._jobTag}</span>
        )}
        <span title="Recruiter Name - Agency Name">
          {engagement._recruiterName} - {engagement._agencyName}
        </span>
        <span
          title="Stage, Status, State, Rejection Reason"
          className="bold"
          style={{ marginTop: "10px" }}
        >
          {engagement.stage}, {engagement.status}
          {`${Core.isAdminOrCoordinator() ? `, ${engagement.state}` : ""} `}
          {engagement.rejectionReason && /closed/i.test(engagement.state)
            ? ", " + engagement.rejectionReason
            : ""}
        </span>
        <span
          title={`Overdue Date: ${engagement.overdueDate
            }, Followed Up, Next Action`}
          className={engagement._isOverdue ? "cred" : ""}
        >
          {[
            !!engagement._overdueDays && "Overdue: " + engagement._overdueDays,
            !!engagement.followedUpCount &&
            "Followed Up: " + engagement.followedUpCount,
            !!engagement.nextAction && "Next: " + engagement.nextAction
          ]
            .filter(i => !!i)
            .join(", ")}
        </span>
        <span
          className="align-left"
          title={`Last Action: ${engagement.lastAction}, actionOwner`}
        >
          {[
            !!engagement.lastAction &&
            "Last: " + moment(engagement.lastAction).format(DATE_FORMAT__DATE__US_LONG),
            !!engagement.actionOwnerId &&
            "Owner: " + engagement.actionOwner._name
          ]
            .filter(i => !!i)
            .join(", ")}
          <a
            href={`https://mail.google.com/mail/u/1/#box/${engagement.boxKey}`}
            target="_blank"
            rel="noreferrer"
            className="pointer"
            title="Go to Streak Box"
            style={{ float: "right" }}
          >
            Streak & nbsp;❯& nbsp;& nbsp;& nbsp;
          </a >
        </span >
      </div >
    );
  }
}

function getEngagementModel({
  extended: isExtendedRequired,
} = {}) {
  return newModel(
    isExtendedRequired
      ? extended
      : model
  );
}

export {
  Engagement as default, extended,
  getEngagementModel, getOverdue, hoverDisplay, listTab, listTabs, mapEngagement, mapFilters as mapEngagementFilters, mapEngagements, model
};

