/*global chrome*/

/**
 * @note
 * "global chrome" was a functional comment for linkedin parser.
 */

import moment from 'moment';
import dig from 'object-dig';
import {
  Component
} from 'react';
import Company from '../../../lib/Company';
import {
  OPTION_YES
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition, {
  DEFINITION_CATEGORY__DIVERSITY_CATEGORIES,
  DIVERSITY_CATEGORIES__UNKNOWN
} from '../../../lib/Definition';
import {
  getHTMLLinkString
} from '../../../lib/HTML.lib';
import Job from '../../../lib/Job';
import School from '../../../lib/School';
import {
  Str,
  sanitizeString
} from '../../../lib/String.lib';
import {
  matchSkillWithText
} from '../../../lib/tools/cvMatch.deprecated';
import formatURL from '../../../lib/tools/formatURL';
import {
  validateGithubURL,
  validateLinkedinURL
} from '../../../lib/tools/processURL.tool';
import StepHeader from '../../Forms/StepHeader';
import Box from '../../Layout/Wrappers/Box';
import Chip from '../../Layout/Wrappers/Chip';
import Col from '../../Layout/Wrappers/Col';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconLinkLauncher from '../../Layout/Wrappers/IconLinkLauncher';
import List from '../../Layout/Wrappers/List';
import Menu from '../../Layout/Wrappers/Menu';
import {
  LoadingMessage,
  WarningChipMessage
} from '../../Layout/Wrappers/Message';
import OnlyAdmin from '../../Layout/Wrappers/OnlyAdmin';
import Row from '../../Layout/Wrappers/Row';
import {
  PLACEMENT__TOP_START
} from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';
import YearsDropdown from '../../Shared/YearsDropdown';
import {
  EMPLOYMENT_HISTORIES__PRESENT
} from '../CandidateHistories.lib';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import {
  CandidateEditResumeSection
} from '../Forms/CandidateEditResumeSection';
import {
  CandidateNameFieldsets
} from '../Forms/CandidateNameFieldsets';
import YearsOfExperienceRow from '../Forms/YearsOfExperienceRow';
import HISTORIES_DERIVATOR from '../Histories/Histories.derivator';
import HistorySection from '../Histories/HistorySection';

/* CONSTANTS ================================== */

const MONTHS = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const matchFromDef = (key, rawText, defaultLabel) => {
  const _default = Definition.get(key).find((obj) => obj.label === defaultLabel);
  let result = Definition.get(key).find((obj) => {
    let labels = [...(obj.alternativeNames || []), ...obj.label.split(' ')];
    return !!labels.find((label) => {
      let regex1 = RegExp(label, 'i');
      return regex1.test(rawText);
    });
  });
  return !!result ? result : _default;
};

/* MAIN CLASS ================================= */

class Basics extends Component {
  constructor(args) {
    super(args);
    const t = this.props.parent.state._tags || [];
    const myTags = this.props.parent.state._myTags || [];
    this.state = {
      tags: t,
      myTags: myTags,
      allTags: t,
      removedElements: [],
      newElements: [],
      linkedInData: null,
      chipsAvailableRoles: [],
      availableStages: Definition.get('stage'),
    };
    this._hasjobsPermittedArrived = false;
    this._allTechnicalSkills = Definition.get('technicalSkills');

  }

  componentDidUpdate() {
    if (!this._hasjobsPermittedArrived && !!this.props.parent.state.jobsPermitted.length) {
      this._hasjobsPermittedArrived = true;
      Job.getWhere({ id: { inq: this.props.parent.state.jobsPermitted } }, (res) => {
        !!this.props.updateJobs && this.props.updateJobs(res);
      });
    }
  }

  defaultRenderTag = (propsCustom) => (props) => {
    let { tag, key } = props;
    let mine =
      propsCustom.myTags.includes(tag) || !propsCustom.allTags.includes(tag) || propsCustom.allTags.length === 0;
    return (
      <Chip
        key={key}
        className='chip'
        backgroundColor={colors.purple.common}
        style={{
          margin: 4,
          display: 'inlineBlock',
        }}
        labelColor={colors.white.common}
        onRequestDelete={
          mine
            ? (e) => {
              let value = this.state.tags.concat([]);
              let index = key;
              if (index > -1 && index < value.length) {
                let changed = value.splice(index, 1);
                this.tagOnChange(value, changed, [index], {
                  action: 'delete',
                });
              }
            }
            : null
        }
      >
        {tag}
      </Chip>
    );
  };

  tagOnChange = (allTags, changed, index, opts = { action: 'add' }) => {
    const CandidateEditController = this.props.parent;
    this.setState({ tags: allTags });
    let myTag = this.state.myTags;

    if (opts.action === 'add') {
      this.state.newElements.push(allTags.slice(-1));
      myTag = [...myTag, ...this.state.newElements];
    } else {
      this.setState({
        removedElements: [...this.state.removedElements, ...changed],
      });
      myTag = myTag.filter((x) => !this.state.removedElements.includes(x));
    }

    let latestSet = new Set(myTag);
    let def = this.props.parent.state.tags;

    def[Core.getUserRole()] = {};
    def[Core.getUserRole()][Core.getUserId()] = {};

    def[Core.getUserRole()][Core.getUserId()] = { tags: Array.from(latestSet) };
    CandidateEditController.setStateStore({ tags: def, key: 'tags' });
  };

  /*
   * The following routine is defined but never used
   *
  parseLinkedInUpdate = ()=>{
    var extensionId = "gkefmiflkdpdelifedadlpkpcnneogha";
  };
  */

  parseLinkedIn = () => {
    // var extensionId = Core.isLocalHost() ? "gkefmiflkdpdelifedadlpkpcnneogha" : "dfjfkiebdocfeanjkdabfpfjiiffbjam";
    var extensionId = (Core.getSession().user || {}).extensionId;
    if (!extensionId) {
      alert('extension id missing, please add in account page first.');
      return;
    }
    const lurl = this.props.parent.state.linkedInURL;
    const that = this;
    const CandidateEditController = this.props.parent;
    const firstElementFromArray = function (array, n) {
      if (array == null) return void 0;
      if (n == null) return array[0];
      if (n < 0) return [];
      return array.slice(0, n);
    };

    /*
     * the following function is commented out below, therefore commenting it out here
     *
    const linkedInToDefinition = (keyValue)=>()=>{
        //degree,major,school
        let that = this;
        let keys = Object.keys(keyValue);
        let state = {};

        keys.forEach(key=>{
            let def = Definition.getRawDef(key);
            let values = def.values;
            let labels = values.map(el=>el.label);

            if(!!keyValue[key] && !labels.includes(keyValue[key])){
                let newLabel = {id:labels.length+1,label:keyValue[key]};
                state[key]=newLabel.id;
                values.push(newLabel);
                Definition.update(def.id,{values})
            }else{
                let [current] = values.filter(el=>el.label===keyValue[key]);
                state[key] = Object(current).id;
            }
        });

        return state;
    };
    */

    const formatLinkedinJobs = (linkedinJobs) => {
      console.log({ linkedinJobs });
      let description = '';
      return linkedinJobs.map((el, index) => {
        let [employerFrom, employerTo] = String(el.date).split('–');
        el.id = index + 1;
        el.employerFrom = employerFrom;
        el.employerTo = employerTo;
        description = !!el.description ? el.description : '';
        el.chips = this._allTechnicalSkills
          .filter((skill) => !!matchSkillWithText(skill.label, description.replace(/(<([^>]+)>)/gi, '')))
          .map((skill) => skill.id);
        return el;
      });
    };

    const formatLinkedinSchools = (linkedinSchools) => {
      return linkedinSchools.map((el, index) => {
        let degreeFromDef = matchFromDef('undergraduateDegree', el.degree, 'None');

        // if(/none/i.test(Object(degreeFromDef).label)) {
        //     el.linkedInToDefClosure = linkedInToDefinition({undergraduateDegree:el.degree});
        // }

        let dateSplitted = el.date.split('–');
        el.initialDegree = el.degree;
        el.degree = degreeFromDef.id;
        el.graduationYearTo = dateSplitted.pop();
        el.graduationYearFrom = dateSplitted.pop();
        el.id = index + 1;
        return el;
      });
    };

    const formatLinkedinSchoolsTo10by10 = (linkedinSchools) => {
      return linkedinSchools.map((el, index) => {
        let dateSplitted = (el.date || '').split('–');
        let endDate = dateSplitted.pop() || null;
        let startDate = dateSplitted.pop() || null;

        let defaultDegree = {
          degreeName: el.degree,
          endDate: !!endDate && moment.utc(endDate).isValid() ? moment.utc(endDate) : endDate,
          startDate: !!startDate && moment.utc(startDate).isValid() ? moment.utc(startDate) : startDate,
          startDateMonth: null,
          startDateYear: !!startDate ? +startDate : null,
          endDateMonth: null,
          endDateYear: !!endDate ? +endDate : null,
        };

        return {
          timestampTemporary: Math.random().toString(36),
          id: index,
          schoolName: el.school,
          schoolType: '',
          degrees: [defaultDegree],
        };
      });
    };

    const fromLinkedinTo10By10 = (linkedinJobs) => {
      const { parent } = this.props;

      return linkedinJobs.map((el, index) => {
        let [employerFrom, employerTo] = (el.date || '').split('–');
        let defaultPositionHistory = {
          title: el.title,
          description: el.description || '',
          startDate: moment.utc(employerFrom).isValid() ? moment.utc(employerFrom) : null,
          endDate: moment.utc(employerTo).isValid() ? moment.utc(employerTo) : null,
          isSelfEmployed: false,
        };

        let isCurrentEmployer = false;

        const MONTHS = [
          '',
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];

        let positionHistories = [defaultPositionHistory, ...(el.children || [])].map((position) => {
          let [from, to] = (el.date || '').split('–');
          from = from.trim();
          to = to.trim();

          let currentlyEmployed = 2;

          if (String(to).trim() === EMPLOYMENT_HISTORIES__PRESENT) {
            to = EMPLOYMENT_HISTORIES__PRESENT;
            currentlyEmployed = OPTION_YES;
            parent.setState({ currentlyEmployed });
            isCurrentEmployer = true;
          }

          let monthStringStartDate = from.split(' ').length === 1 ? '' : from.split(' ')[0];
          let monthStringEndDate = to.split(' ').length === 1 ? '' : to.split(' ')[0];
          const regexMonthStringStartDate = new RegExp(monthStringStartDate, 'g');
          const regexMonthStringEndDate = new RegExp(monthStringEndDate, 'g');

          return {
            title: position.title,
            description: position.description || '',
            startDate: moment.utc(from).isValid() ? moment.utc(from) : null,
            endDate: to === 'current' ? null : moment.utc(to).isValid() ? moment.utc(to) : null,
            isSelfEmployed: false,
            startDateMonth:
              MONTHS.indexOf(MONTHS.find((m) => regexMonthStringStartDate.test(m))) > 0
                ? MONTHS.indexOf(MONTHS.find((m) => regexMonthStringStartDate.test(m)))
                : null,
            currentlyEmployed,
            startDateYear: from.split(' ').length === 1 ? +from.split(' ')[0] : +from.split(' ')[1],
            endDateMonth:
              (to === 'current'
                ? null
                : MONTHS.indexOf(MONTHS.find((m) => regexMonthStringEndDate.test(m)))
                  ? MONTHS.indexOf(MONTHS.find((m) => regexMonthStringEndDate.test(m)))
                  : null) || null,
            endDateYear:
              (to === 'current' ? null : to.split(' ').length === 1 ? to.split(' ')[0] : to.split(' ')[1]) || null,
          };
        });

        return {
          id: index,
          timestampTemporary: Math.random().toString(36),
          employerOrgName: el.employer,
          isCurrentEmployer,
          positionHistories,
        };
      });
    };

    let passed = false;

    const getCurrentEmployer = (employmentHistories) => {
      const latest = employmentHistories[0];
      let ret = {
        currentEmployer: '',
        currentTitle: '',
        currentEmployerFrom: '',
        currentEmployerTo: '',
        currentlyEmployed: 2,
      };

      if (!latest) {
        return ret;
      }

      const latestPositionHistory = Object(latest.positionHistories[0]);

      let currentEmployerFrom = `${MONTHS[latestPositionHistory.startDateMonth || 0]} ${latestPositionHistory.startDateYear || ''
        }`.trim();
      let currentEmployerTo = `${MONTHS[latestPositionHistory.endDateMonth || 0]} ${latestPositionHistory.endDateYear || ''
        }`.trim();

      return {
        currentEmployer: latest.employerOrgName,
        currentTitle: latestPositionHistory.title,
        currentEmployerFrom,
        currentEmployerTo,
        currentlyEmployed: latestPositionHistory.currentlyEmployed,
      };
    };

    const getCurrentSchool = (educationHistories) => {
      const latest = educationHistories[0];
      let ret = {
        undergraduateDegree: '',
        undergraduateMajor: '',
        undergraduateSchool: '',
        graduationYear: '',
      };

      if (!latest) {
        return ret;
      }

      const latestDegree = Object(latest.degrees[0]);
      // let reta = {
      //   undergraduateDegree: latestDegree.degreeName,
      //   undergraduateMajor: '',
      //   undergraduateSchool: latest.schoolName,
      //   graduationYear: latestDegree.endDate
      // };

      let degreeFromDef = matchFromDef('undergraduateDegree', latestDegree.degreeName, 'None');

      let graduationYear = `${MONTHS[latestDegree.endDateMonth || 0]} ${latestDegree.endDateYear || ''}`.trim();

      return {
        undergraduateDegree: Object(degreeFromDef).id,
        undergraduateMajor: '',
        undergraduateSchool: latest.schoolName,
        graduationYear,
      };
    };

    const extensionCb = (type) => {
      chrome.runtime.sendMessage(extensionId, { linkedInUrl: lurl, type }, (response) => {
        let [firstKey] = Object.keys(response.data);
        const regexFirstKey = new RegExp(lurl, 'i');

        if (regexFirstKey.test(firstKey)) {
          console.log({ response });
          that.setState({ linkedInData: response.data[firstKey] });

          let userDetails = firstElementFromArray(response.data[firstKey].userDetails) || '';
          let username = sanitizeString(userDetails.name) || '';
          let parent = this.props.parent;
          let educationHistories = formatLinkedinSchoolsTo10by10(response.data[firstKey].education);
          let schoolsLinkedIn = formatLinkedinSchools(response.data[firstKey].education);
          const employmentHistories = fromLinkedinTo10By10(response.data[firstKey].userExperience);

          console.log({
            history: fromLinkedinTo10By10(response.data[firstKey].userExperience),
          });
          CandidateEditController.setStateStore({
            firstName: parent.state.firstName || username.split(' ')[0],
            lastName: parent.state.lastName || username.split(' ')[1],
            jobsLinkedIn: formatLinkedinJobs(response.data[firstKey].userExperience),
            employmentHistories,
            educationHistories,
            schoolsLinkedIn,
            ...getCurrentEmployer(employmentHistories),
            ...getCurrentSchool(educationHistories),
          });

          passed = true;
        } else {
          if (!dig(response, 'data')) {
            alert('you need to install extension');
            that.clearInterval();
          }
        }

        if (!passed) {
          window.setTimeout(function () {
            extensionCb('update');
          }, 2000);
        }
      });
    };
    extensionCb('start');
  };



  getCompanyInfo = (value, cb) => {
    let employerOrgName = sanitizeString(value);
    Company.get(
      {
        where: {
          or: [
            { name: { like: employerOrgName, options: "i" } },
            { alternativeNames: employerOrgName },
          ],
        },
        fields: ["alternativeNames", "id", "name", "signalTags"],
        limit: 5,
      },
      (response) => {
        this.setState(
          { companies: response, shouldShowCompanyBgColor: true },
          () => {
            !!cb && cb(response);
          }
        );
      }
    );
  };

  getSchoolInfo = (value, callback) => {
    let schoolName = sanitizeString(value);
    School.get(
      {
        include: {
          relation: "schoolRating",
          scope: {
            fields: [
              "degree",
              "rating",
              "positiveSignalsTags",
              "negativeSignalsTags",
              "signals",
            ],
            scope: {
              where: {
                degree: Definition.getId("positiveSignals", "Computer degree"),
              },
            },
          },
        },
        where: {
          or: [
            { universityName: { like: schoolName, options: "i" } },
            { alternativeNames: schoolName },
          ],
        },
        fields: ["alternativeNames", "id", "schoolRating", "universityName"],
        limit: 5,
      },
      (response) => {
        this.setState(
          { ranking: response, shouldShowCompanyBgColor: true },
          () => {
            !!callback && callback(response);
          }
        );
      },
    );
  };

  render() {

    const { parent: CandidateEditController = {} } = this.props;
    const candidate = CandidateEditController.state;

    let diversityCategoriesTags = Definition.get(DEFINITION_CATEGORY__DIVERSITY_CATEGORIES);

    const _normalizeLinkedInUrl = () => {
      const key = 'linkedInURL';
      const errorKey = 'errorLinkedIn';
      const normalizedUrl = validateLinkedinURL(
        formatURL(CandidateEditController.state[key])
      );
      if (!!Str(normalizedUrl).trim()) {
        CandidateEditController.setStateStore({
          key,
          [key]: normalizedUrl,
          [errorKey]: '',
        }, () => {
          CandidateEditController.setState({ duplicateTriggeredBy: key }, () => {
            CandidateEditController.checkPotentialDuplicatedCandidates(() => null);
          });
        });
      }
      else {
        CandidateEditController.setStateStore({
          [errorKey]: 'Invalid URL',
        })
      }
    }

    const _normalizeGitHubUrl = () => {
      const key = 'gitHubURL';
      const errorKey = 'errorGitHub';
      const normalizedUrl = validateGithubURL(
        formatURL(CandidateEditController.state[key])
      );
      if (!!Str(normalizedUrl).trim()) {
        CandidateEditController.setStateStore({
          key,
          [key]: normalizedUrl,
          [errorKey]: '',
        }, () => {
          CandidateEditController.setState({ duplicateTriggeredBy: key }, () => {
            CandidateEditController.checkPotentialDuplicatedCandidates(() => null);
          });
        });
      }
      else {
        CandidateEditController.setStateStore({
          [errorKey]: 'Invalid URL',
        })
      }
    }

    // See story 7772 for layout key_codes
    return (
      <List>

        <StepHeader {...this.props} />

        {/** 1A */}
        <CandidateEditResumeSection CandidateEditController={CandidateEditController} />

        <CandidateNameFieldsets CandidateEditController={CandidateEditController} />

        {/** PREFERRED NAME | UNDERREPRESENTED GROUP */}
        <Row>
          <Fieldset
            title={CANDIDATE_UI.nickName.title}
            subtitle={CANDIDATE_UI.nickName.subtitle}
          >
            <TextField
              name='nickName'
              value={CandidateEditController.state.nickName}
              onChange={(ev, nickName) => CandidateEditController.setStateStore({ nickName, key: 'nickName' })}
            />
          </Fieldset>
          <Fieldset
            title={CANDIDATE_UI.diversityCategories.title}
            nextToTitle={
              <Box
                title={CANDIDATE_UI.diversityCategories.tooltip}
                titleStyle={{ maxWidth: 380 }}
              >
                <i className='material-icons c-purple f-lg icon16 help'>help_outline</i>
              </Box>
            }
            subtitle={CANDIDATE_UI.diversityCategories.subtitle}
          >
            <Menu multiple
              name='diversityCategories'
              placeholder={CANDIDATE_UI.diversityCategories.placeholder}
              options={diversityCategoriesTags}
              value={(
                !!diversityCategoriesTags.length
                  ? candidate.diversityCategories
                  : []
              )}
              onChange={(diversityCategories) => {
                let candidateHasExcludingOption = candidate.diversityCategories.includes(
                  DIVERSITY_CATEGORIES__UNKNOWN
                );
                let currentSetsExcludingOption = (
                  diversityCategories.includes(DIVERSITY_CATEGORIES__UNKNOWN) &&
                  diversityCategories.length >= 2
                );
                if (candidateHasExcludingOption && currentSetsExcludingOption) {
                  diversityCategories = diversityCategories.filter(v =>
                    v !== DIVERSITY_CATEGORIES__UNKNOWN
                  );
                }
                else if (!candidateHasExcludingOption && currentSetsExcludingOption) {
                  diversityCategories = [DIVERSITY_CATEGORIES__UNKNOWN];
                }
                delete CandidateEditController.__mappedSignals;
                CandidateEditController.setStateStore({
                  diversityCategories,
                  key: 'diversityCategories',
                  __forceSignals: false
                });
              }}
            />
          </Fieldset>
        </Row>

        {/** EMAIL | PHONE */}
        <Row className='mt-3'>
          <Col>
            <label>
              Email <b className='cred'>*</b>
            </label>
            <TextField disabledBlurExport
              name='email'
              type='email'
              value={CandidateEditController.state.email}
              onChange={(ev, email) => CandidateEditController.setStateStore({ email, errorEmail: '', key: 'email' })}
              onBlur={(ev) => {
                !!CandidateEditController.state.email &&
                  CandidateEditController.setState({ duplicateTriggeredBy: 'email' }, () => {
                    CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                  });
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  CandidateEditController.setState({ duplicateTriggeredBy: 'email' }, () => { });
                }
              }}
              fullWidth
              required
              errorText={CandidateEditController.state.errorEmail}
            />
          </Col>

          <Col style={{ display: 'inline-grid' }}>
            <label>
              Phone <b style={{ color: 'orange' }}>*</b>
            </label>
            {/** @todo Re-implement "mui-phone-number" or similar */}
            <TextField
              name='phone'
              value={CandidateEditController.state.phone}
              onChange={(event, phone) => {
                CandidateEditController.setStateStore({
                  phone: phone,
                  errorPhone: '',
                  key: 'phone',
                });
              }}
            />
          </Col>
        </Row>
        {/** LinkedIn | GitHub */}
        <Row className='mt-3'>
          <Fieldset
            title={
              <>
                LinkedIn&nbsp;<b style={{ color: 'orange' }}>*</b>
              </>
            }
            nextToTitle={
              <Box row noWrap>
                <IconLinkLauncher
                  url={CandidateEditController.state.linkedInURL}
                  className='mr-1'
                />
                <OnlyAdmin
                  acl={!!CandidateEditController.state.linkedInURL}
                  render={() => (
                    <i className='material-icons pointer' style={{ fontSize: 18 }} onClick={this.parseLinkedIn}>
                      swap_vert
                    </i>
                  )}
                  className='dotted rounded-sm'
                />
              </Box>
            }
          >
            <TextField disabledBlurExport
              name='linkedInURL'
              value={CandidateEditController.state.linkedInURL}
              onChange={(ev, linkedInURL) =>
                CandidateEditController.setStateStore({
                  linkedInURL: linkedInURL,
                  key: 'linkedInURL',
                  errorLinkedIn: '',
                })
              }
              onBlur={_normalizeLinkedInUrl}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  _normalizeLinkedInUrl();
                }
              }}
              fullWidth
              errorText={CandidateEditController.state.errorLinkedIn}
            />
          </Fieldset>
          <Fieldset
            title='GitHub'
            nextToTitle={
              <IconLinkLauncher
                url={CandidateEditController.state.gitHubURL}
              />
            }
          >
            <TextField disabledBlurExport
              name='gitHubURL'
              value={CandidateEditController.state.gitHubURL}
              onChange={(ev, gitHubURL) =>
                CandidateEditController.setStateStore({
                  gitHubURL: gitHubURL,
                  key: 'gitHubURL',
                  errorGitHub: '',
                })
              }
              onBlur={_normalizeGitHubUrl}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  _normalizeGitHubUrl();
                }
              }}
              fullWidth
              errorText={CandidateEditController.state.errorGitHub}
            />
          </Fieldset>
        </Row>
        {/** StackOverflow | Additional links */}
        <Row>
          <Fieldset
            title={
              <>
                Stack Overflow
              </>
            }
            nextToTitle={
              <IconLinkLauncher
                url={CandidateEditController.state.stackoverflowUrl}
              />
            }
          >
            <TextField disabledBlurExport
              name='stackoverflowUrl'
              value={CandidateEditController.state.stackoverflowUrl}
              onChange={(ev, stackoverflowUrl) =>
                CandidateEditController.setStateStore({
                  stackoverflowUrl: formatURL(stackoverflowUrl),
                  key: 'stackoverflowUrl',
                  errorStackoverflowUrl: '',
                })
              }
              onBlur={(ev) => {
                !!CandidateEditController.state.stackoverflowUrl &&
                  CandidateEditController.setState({ duplicateTriggeredBy: 'stackoverflowUrl' }, () => {
                    CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                  });
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  CandidateEditController.setState({ duplicateTriggeredBy: 'stackoverflowUrl' }, () => { });
                }
              }}
              fullWidth
              errorText={CandidateEditController.state.errorStackoverflowUrl}
            />
          </Fieldset>
          <Fieldset
            title={
              <>Additional links</>
            }
            info={
              CandidateEditController.state.otherLinks
                ? <div dangerouslySetInnerHTML={{
                  __html: CandidateEditController.state.otherLinks.split('\n').filter(v => !!v).map(
                    (url) => `
                        ${getHTMLLinkString({
                      url,
                      postContent: `&nbsp;<i class='material-icons icon24 c-white'>link</i>`,
                      full: true,
                      className: 'c-white f-md d-flex flex-align-left-center'
                    })}
                      `
                  ).join(``)
                }} />
                : ''
            }
          >
            <TextField disabledBlurExport
              name="otherLinks"
              rows={1}
              rowsMax={7}
              value={CandidateEditController.state.otherLinks}
              onChange={(event) => {
                CandidateEditController.setStateStore({
                  otherLinks: event.target.value,
                  key: "otherLinks"
                });
              }}
              onBlur={
                (event) => {
                  CandidateEditController.setStateStore({
                    otherLinks: event.target.value.split("\n")
                      .map((url) => formatURL(url))
                      .join("\n"),
                    key: "otherLinks"
                  });
                }
              }
              fullWidth
            />
          </Fieldset>
        </Row>

        {/** COMPANY recruiter's view */}
        <Box
          className={
            Core.isAdmin() ? 'dashed-orange rounded m-1 mt-3 pb-1' : ''
          }
          style={{ position: 'relative' }}
        >
          {Core.isAdmin() && (
            <WarningChipMessage
              className='bg-white'
              style={{ position: 'absolute', top: -12, left: 16 }}
            >
              <OnlyAdmin
                render={() => (
                  <>
                    Recruiter's view
                  </>
                )}
                title='This section is visible for recruiters, without this orange border'
                placement={PLACEMENT__TOP_START}
              />
            </WarningChipMessage>
          )}
          <Row className='mt-3'>
            <Col>
              <label>Most recent company</label>
              <TextField
                value={CandidateEditController.state.currentEmployer || ''}
                onChange={(ev, currentEmployer) =>
                  CandidateEditController.setStateStore({
                    currentEmployer,
                    key: "currentEmployer",
                  })
                }
              />
            </Col>

            <Col>
              <label>Most recent job title</label>
              <TextField
                name="currentTitle"
                rows={1}
                rowsMax={7}
                value={CandidateEditController.state.currentTitle}
                onChange={(ev, currentTitle) =>
                  CandidateEditController.setStateStore({ currentTitle, key: "currentTitle" })
                }
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <label>Currently working here?</label>
              <Menu dropdown
                name="currentlyEmployed"
                value={CandidateEditController.state.currentlyEmployed}
                onChange={(currentlyEmployed) => {
                  CandidateEditController.setStateStore({
                    currentlyEmployed,
                    key: "currentlyEmployed",
                  });
                  if (parseInt(currentlyEmployed) === 1) {
                    CandidateEditController.setStateStore({
                      currentEmployerTo: "current",
                      key: "currentEmployerTo",
                    });
                  }
                }}
                options={Definition.get("diversity")}
              />
            </Col>
            <Col>
              <label>Start date</label>
              <YearsDropdown
                key={"currentEmployerFrom"}
                month={
                  (CandidateEditController.state.currentEmployerFrom || "").trim().split(" ")
                    .length > 1
                    ? (CandidateEditController.state.currentEmployerFrom || "")
                      .trim()
                      .split(" ")[0]
                    : 0
                }
                year={
                  (CandidateEditController.state.currentEmployerFrom || "").trim().split(" ")
                    .length > 1
                    ? (CandidateEditController.state.currentEmployerFrom || "")
                      .trim()
                      .split(" ")[1]
                    : (CandidateEditController.state.currentEmployerFrom || "")
                      .trim()
                      .split(" ")[0]
                }
                onChangeHandler={(month, year, monthString) => {
                  CandidateEditController.setStateStore({
                    currentEmployerFrom: `${monthString || ""} ${year || ""}`,
                    key: "currentEmployerFrom",
                  });
                }}
              />
            </Col>
            <Col>
              <label>End date</label>
              {parseInt(CandidateEditController.state.currentlyEmployed) === 1 ? (
                <TextField
                  name={"currentlyEmployed"}
                  value={"current"}
                  rowsMax={5}
                  disabled
                />
              ) : (
                <YearsDropdown
                  month={
                    (CandidateEditController.state.currentEmployerTo || "").trim().split(" ")
                      .length > 1
                      ? (CandidateEditController.state.currentEmployerTo || "")
                        .trim()
                        .split(" ")[0]
                      : 0
                  }
                  key={"currentEmployerTo"}
                  year={
                    (CandidateEditController.state.currentEmployerTo || "").trim().split(" ")
                      .length > 1
                      ? (CandidateEditController.state.currentEmployerTo || "")
                        .trim()
                        .split(" ")[1]
                      : (CandidateEditController.state.currentEmployerTo || "")
                        .trim()
                        .split(" ")[0]
                  }
                  onChangeHandler={(month, year, monthString) => {
                    CandidateEditController.setStateStore({
                      currentEmployerTo: `${monthString || ""} ${year || ""}`,
                      key: "currentEmployerTo",
                    });
                  }}
                />
              )}
            </Col>
          </Row>
          {!Core.isAdmin() && (
            <YearsOfExperienceRow candidate={candidate} className='mt-3' />
          )}
        </Box>

        {/** 1B + 1C */}
        <LoadingMessage
          show={Core.isAdmin() && !!candidate.employmentHistories.length && (!candidate.__dicOrganizations || !candidate.__dicSignals)}
          title={`Loading organizations and signals`}
        >
          Loading additional information
        </LoadingMessage>

        <HistorySection
          className='mt-3'
          candidate={candidate}
          onChange={CandidateEditController.setStateStore}
          context={{
            ...HISTORIES_DERIVATOR.experience,
            CandidateEditController
          }}
        />

        {/** SCHOOL recruiter's view */}
        <Box
          className={
            Core.isAdmin() ? 'dashed-orange rounded m-1 mt-3 pb-1' : ''
          }
          style={{ position: 'relative' }}
        >
          {Core.isAdmin() && (
            <WarningChipMessage
              className='bg-white'
              style={{ position: 'absolute', top: -12, left: 16 }}
            >
              Recruiter's view
            </WarningChipMessage>
          )}
          <Row className='mt-3'>
            <Col>
              <label>School</label>
              <TextField
                value={CandidateEditController.state.undergraduateSchool || ''}
                onChange={(event, undergraduateSchool) =>
                  CandidateEditController.setStateStore({
                    undergraduateSchool,
                    key: "undergraduateSchool",
                  })
                }
              />
            </Col>
            <Col>
              <label>End date (or expected)</label>
              <YearsDropdown
                key={"currentEmployerFrom"}
                month={
                  String(CandidateEditController.state.graduationYear || "").split(" ").length > 1
                    ? String(CandidateEditController.state.graduationYear || "").split(" ")[0]
                    : 0
                }
                year={
                  String(CandidateEditController.state.graduationYear || "").split(" ").length > 1
                    ? String(CandidateEditController.state.graduationYear || "").split(" ")[1]
                    : String(CandidateEditController.state.graduationYear || "").split(" ")[0]
                }
                onChangeHandler={(month, year, monthString) => {
                  CandidateEditController.setStateStore({
                    graduationYear: `${monthString} ${year}`,
                    key: "graduationYear",
                  });
                }}
                className='mt-1'
              />
            </Col>
          </Row>
          <Row>
            <Fieldset
              title={
                <>
                  Degree &nbsp;
                  <span>
                    {dig(CandidateEditController.state, "linkedInRaw", "degree") &&
                      `(From linkdedIn: ${dig(
                        CandidateEditController.state,
                        "linkedInRaw",
                        "degree"
                      )})`}
                  </span>
                </>
              }
            >
              <Menu dropdown
                name="undergraduateDegree"
                value={CandidateEditController.state.undergraduateDegree}
                onChange={(undergraduateDegree) => {
                  candidate.undergraduateDegree = undergraduateDegree;
                  Definition.set(candidate, 'undergraduateDegree');
                  CandidateEditController.setStateStore({
                    undergraduateDegree,
                    key: "undergraduateDegree",
                  })
                }}
                options={Definition.get("undergraduateDegree")}
              />
            </Fieldset>
            <Fieldset
              title={
                <>Field of study</>
              }
            >
              <TextField
                name="undergraduateMajor"
                value={CandidateEditController.state.undergraduateMajor}
                onChange={(event, undergraduateMajor) =>
                  CandidateEditController.setStateStore({
                    undergraduateMajor,
                    key: "undergraduateMajor",
                  })
                }
              />
            </Fieldset>
          </Row>
        </Box>

        <HistorySection
          className='mt-3'
          candidate={candidate}
          onChange={CandidateEditController.setStateStore}
          context={{
            ...HISTORIES_DERIVATOR.education,
            CandidateEditController
          }}
        />

      </List>
    );
  }
}

/* EXPORTS ==================================== */

export default Basics;

/* ============================================ */
