
export const QUERY__MATCH_DECISIONS = {
  where: {
    and: [
      { engagementId: '{{ENGAGEMENT_ID}}' },
      { annotator: { neq: 'ML' } }
    ],
  },
  fields: [
    'id',
    'annotator',
    'engagementId',
    'candidateId',
    'jobId',
    'createdAt',
    'updatedAt',
    'matchDecision',
    'whyNoDetails',
    'whyNoPrivateNote',
    'whyNoCategories',
    'whyYesDetails',
    'whyYesPrivateNote',
    'whyYesCategories',
    'whyNeedToReadCV',
    'shouldTag',
    'shouldNotTag',
    'reviewRequested',
    'sameDecision',
    'diffDecision',
  ]
};
