import moment from "moment";
import {
  Component,
  Fragment
} from "react";
import {
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import {
  join
} from '../../../lib/Array.lib';
import Core from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  getJoinedFeedbackOrRejectionReason
} from '../../../lib/models/EngagementFeedback.model';
import {
  Str
} from '../../../lib/String.lib';
import cleanHtml from "../../../lib/tools/cleanHtml";
import Card from '../../Layout/Wrappers/Card';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import { Html } from '../../Layout/Wrappers/Html';
import Typography from '../../Layout/Wrappers/Typography';

class SubmissionHistory extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      candidateEngagements: []
    }
  }

  fetchEngagements = () => {
    Engagement.getWhere({ candidateId: this.props.candidate.id }, (res) => {
      this.setState({ candidateEngagements: res });
    }, {}, { source: `candidates__match_new__candidate_pipe__submission_history` })
  }

  componentDidMount() {
    this.fetchEngagements();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!prevProps.candidate.id && prevProps.candidate.id !== this.props.candidate.id) {
      this.fetchEngagements();
    }
  }

  profiles = () => {
    let ret = {}
    let engagements = this.state.candidateEngagements;

    if (!Array.isArray(engagements)) {
      return;
    }

    ret.no = () => engagements.filter(
      (engagement) => (
        (engagement.stage === STAGE_REVIEW) &&
        /e -/i.test(engagement.status)
      )
    );

    ret.yes = () => {
      let filtered = engagements.filter(
        (engagement) => NOT(
          [
            STAGE_CONFIRMATION,
            STAGE_SUBMISSION,
            STAGE_REVIEW
          ].includes(engagement.stage)
        )
      );

      let hash = {
        [STAGE_SCREEN]: 1,
        [STAGE_ONSITE]: 2,
        [STAGE_OFFER]: 3,
        [STAGE_GUARANTEE]: 4,
        [STAGE_HIRE]: 5,
        [STAGE_END]: 6
      };

      filtered.sort((a, b) => {
        return ((new Date(b.submitted)).getTime() * hash[b.stage]) - ((new Date(a.submitted)).getTime() * hash[a.stage]);
      });

      return filtered;
    }

    ret.active = () => {
      return engagements.filter(el => el.state === "Open");
    };

    ret.inactive = () => {
      return engagements.filter(el => el.state === "Closed");
    };

    ret.summary = () => {
      let onsite = engagements.filter(el => /onsite|offer|guarantee|hire|end/i.test(el.stage));
      let screens = engagements.filter(el => /screen/i.test(el.stage));
      let reviewed = [...ret.yes(), ...ret.no()];
      let submitted = [...reviewed, ...ret.mayBe()];
      let screenPercent, onSitesPercent = "0%";

      if (reviewed.length > 0) {
        screenPercent = `${parseInt(((screens.length + onsite.length) * 100) / reviewed.length)}%`;
        onSitesPercent = `${parseInt((onsite.length * 100) / reviewed.length)}%`;
      }

      return <Fragment>
        <div>{submitted.length} submitted
          | {reviewed.length} reviewed
          | {screens.length} screens & after ({screenPercent})
          | {onsite.length} onSites & after ({onSitesPercent})
        </div>
      </Fragment>
    }

    const printSingle = (eng) => {
      let employer = eng.employer;
      let job = eng.job;
      let isActive = eng.state !== 'Closed';
      let isHired = !isActive && eng.stage === 'Guarantee';
      let finalLabel = "";
      let rejectionReasonDetails = "";
      if (!isActive) {
        if (isHired) {
          finalLabel = "<strong><u>HIRED</u></strong>";
          rejectionReasonDetails = "N/A"
        } else {
          finalLabel = "<strong>REJECTED</strong>";
          rejectionReasonDetails = join([
            getJoinedFeedbackOrRejectionReason(eng),
            eng.rejectionReasonAdditionalInfo
          ], ' - ');
        }
      }
      return cleanHtml(
        '<div style="font-size:12px;"><strong>' + String(eng.stage) + '</strong> | ' + String(eng.status) + ' | ' +
        [
          moment(eng.lastAction).format("MM/DD/YY"),
          `<a href="${Core.getLink(`/employer/edit/${employer.id}`)}" target="_blank">${employer._name}</a>`,
          Str(job._roles).split(", ")[0]
        ]
          .filter(e => !!e && !!String(e).trim().length)
          .join(" | ") + ' | ' + finalLabel + '</div>' +
        '<div style="font-size:10px;">&emsp;' + rejectionReasonDetails + '</div>'
      );
    };

    ret.print = (array) => {
      return array.map(el => printSingle(el)).join('')
    }

    return ret;
  }

  render() {

    return (
      <Card className="mb-05">

        <Typography strong large alignCenter blackDark
          className='m-1'
        >
          SUBMISSION HISTORY
        </Typography>
        <Divider />

        <Fieldset
          title={
            <>
              Active ({this.profiles().active().length})
            </>
          }
        >
          <Html
            value={this.profiles().print(this.profiles().active())}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </Fieldset>

        <Fieldset
          title={
            <>
              Inactive ({this.profiles().inactive().length})
            </>
          }
        >
          <Html
            value={this.profiles().print(this.profiles().inactive())}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </Fieldset>

      </Card>
    );
  }
}

export default SubmissionHistory;