import {
  MATERIAL_ICON_NAME__QUESTION_MARK
} from '../../../lib/constants/MaterialIconName.dic';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  trim
} from '../../../lib/String.lib';
import {
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import Box from './Box';
import Divider from './Divider';
import Icon from './Icon';
import {
  PLACEMENT__RIGHT_END
} from './StyledTooltip';
import Typography, {
  Label
} from './Typography';

/**
 * 
 * @param {object} props 
 * @param {string} props.title [JSX.Element]
 * @param {string} props.dot [red|orange]
 * @param {string} props.nextToTitle
 * @param {string} props.info
 * @param {object} props.infoProps,
 * @param {string} props.infoIconName [MATERIAL_ICON_NAME__QUESTION_MARK|...]
 * @param {JSX.Element} props.infoIcon
 * @param {JSX.Element} props.actionBar
 * @param {string} props.subtitle [JSX.Element]
 * @param {string} props.children
 * @param {JSX.Element} props.wrapper [wrapper:Col]
 * @param {object} props.wrapperProps [{fullWidth:true}]
 * @param {string} props.classNameWrapper
 * @param {object} props.styleWrapper
 * @param {string} props.className
 * @param {boolean} props.startDivider
 * @param {boolean} props.fullWidth
 * @param {boolean} props.endDivider
 * @param {boolean} props.startDivider
 * 
 * @returns {JSX.Element}
 */
export default function Fieldset({
  title = '',
  dot = '', // red | orange
  nextToTitle,
  info,
  infoProps,
  infoIconName = MATERIAL_ICON_NAME__QUESTION_MARK,
  infoIcon,
  actionBar,
  subtitle,
  children,
  wrapperProps = { fullWidth: true },
  classNameWrapper = '',
  style = {},
  className = '',
  acl = true,
  startDivider = false,
  endDivider = false,
  row = false,
  noWrap = false,
  column = false,
  fullWidth = undefined,
  contentWrapper = {},
  ...props
}) {

  if (!acl) { return null; }

  wrapperProps = { ...Obj(wrapperProps), ...props };

  return (
    <Box column flex1
      role='Fieldset'
      {...wrapperProps}
    >

      <Divider
        acl={!!startDivider}
        role='Fieldset__startDivider'
      />

      <Box column w100
        role='Fieldset__Wrapper'
        className={
          joinClassName([
            'flex-1 px-1',
            classNameWrapper,
            className
          ])
        }
        style={style}
      >

        <Box column w100
          acl={title}
          role='Fieldset__Header'
          style={{ minHeight: 36 }}
        >

          <Box row w100 flex1>

            <Box row flex1
              className='mr-auto truncate'
            >

              <Label className='w-auto'>
                {title}
              </Label>

              <Typography mr
                acl={dot}
                className={`c-${dot}`}
              >
                *
              </Typography>

              <Typography mr
                acl={!!nextToTitle}
              >
                {nextToTitle}
              </Typography>

              <Box
                acl={trim(info)}
                title={info}
                placement={PLACEMENT__RIGHT_END}
                paperStyle={{ maxWidth: 480 }}
                {...Obj(infoProps)}
              >
                {
                  infoIcon ||
                  <Icon
                    acl={infoIcon}
                    icon={infoIconName}
                    className='c-purple help icon16 mr-1'
                  />
                }
              </Box>

            </Box>

            <Box row wAuto className='w-auto'>
              {actionBar}
            </Box>

          </Box>

          <Typography sub acl={!!subtitle}>
            {subtitle}
          </Typography>

        </Box>

        <Box
          {...mapWrapper({
            role: 'FieldsetContent',
            props: contentWrapper,
            assign: {
              className: joinClassName([
                'w-100 py-05',
                row && joinClassName([
                  'flex-row scroll-y',
                  noWrap ? 'flex-no-wrap' : 'flex-wrap',
                ]),
                column && 'flex-column scroll-y',
                contentWrapper.className,
              ]),
              children
            }
          })}
        />

      </Box>

      <Divider
        acl={!!endDivider}
        role='Fieldset__endDivider'
      />

    </Box>
  );
}
